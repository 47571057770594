import React from "react";
import Button from "../components/ui/Button";
import { useSelector, useDispatch } from "react-redux";
import { setCountry, setImmigrantStatus } from "../store";
import Select from "react-select"; // Import react-select
import { useNavigate } from "react-router-dom";

function LoginPage() {
  const dispatch = useDispatch();
  const { country, immigrantStatus } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const countries = ["Cyprus", "Spain", "Greece", "Italy", "Lithuania", "Sweden", "Turkey"];

  const immigrationStatuses = ["TCN", "Recognised Refugee", "Asylum Seeker", "Person with subsidiary protection"];

  const handleCountryChange = (selectedOption) => {
    if (!selectedOption) selectedOption = { value: "" };
    dispatch(setCountry(selectedOption.value));
    dispatch(setImmigrantStatus("")); // Reset immigrant status when country changes
  };

  const handleStatusChange = (selectedOption) => {
    if (!selectedOption) selectedOption = "";
    dispatch(setImmigrantStatus(selectedOption.value)); // Use selectedOption.value
  };

  const handleMoveToMainPage = () => {
    if (country) {
      navigate("/select");
    }
  };

  const filteredImmigrationStatuses =
    country === "Sweden"
      ? immigrationStatuses.filter((status) => status !== "Person with subsidiary protection")
      : immigrationStatuses;

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-300">
      {/* <h1 className="text-2xl font-bold mb-24">VRin</h1> */}
      <img src="/Vrin.png" alt="Vrin Logo" className="w-fit mb-10 p-5 -mt-32" loading="lazy"></img>
      {/*--------------------- COUNTRY -----------------------*/}
      <div className="w-full max-w-xs">
        <label className="block text-gray-700 max-[400px]:text-sm text-base font-bold mb-2">
          Please select your country:
        </label>
        <Select
          value={{ value: country, label: country }} // Set value and label for country
          isClearable={true}
          isSearchable={false}
          onChange={handleCountryChange}
          options={countries.map((country) => ({
            value: country,
            label: country,
          }))}
        />
      </div>
      {/*--------------------- STATUS -----------------------*/}
      {country && (
        <div className="w-full max-w-xs mt-4">
          <label className="block text-gray-700 max-[400px]:text-sm text-base font-bold mb-2">
            Please select your immigration status:
          </label>
          <Select
            value={{ value: immigrantStatus, label: immigrantStatus }} // Set value and label for status
            onChange={handleStatusChange}
            isSearchable={false}
            isClearable={true}
            options={filteredImmigrationStatuses.map((status) => ({
              value: status,
              label: status,
            }))}
          />
        </div>
      )}
      {/* <span class="relative flex h-3 w-3">
        <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
        <span class="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
      </span> */}

      {country && immigrantStatus && (
        <div className="mt-4">
          <Button onClick={handleMoveToMainPage} className="noTapHighlight" primary rounded rippleEffect>
            ENTER
          </Button>
        </div>
      )}
    </div>
  );
}

export default LoginPage;
