import {
  dark100,
  med100,
  light100,
  sDark100,
  sMed100,
  sLight100,
  dark200,
  med200,
  light200,
  sDark200,
  sMed200,
  sLight200,
  dark300,
  med300,
  light300,
  sDark300,
  sMed300,
  sLight300,
  dark400,
  med400,
  light400,
  sDark400,
  sMed400,
  sLight400,
} from "./colors";

export const codes = {
  // --------------------------         English        ------------------------------- //
  English: [
    // 100
    {
      code: "100",
      color: dark100,
      shadow: sDark100,
      name: "SECTION 1: Communication - Language Skills",
    },
    {
      code: "110",
      color: med100,
      shadow: sMed100,
      name: "Communication with local authorities",
    },
    {
      code: "111",
      color: light100,
      shadow: sLight100,
      name: "Interact with authorities ",
    },
    {
      code: "112",
      color: light100,
      shadow: sLight100,
      name: "Cultural, situational and interactional awareness and expectations",
    },
    {
      code: "113",
      color: light100,
      shadow: sLight100,
      name: "Rules and Regulations",
    },
    { code: "114", color: light100, shadow: sLight100, name: "Dos and Don’ts" },
    {
      code: "115",
      color: light100,
      shadow: sLight100,
      name: "Cultural aspects",
    },
    {
      code: "116",
      color: light100,
      shadow: sLight100,
      name: "Accessing help in case of emergencies",
    },
    {
      code: "120",
      color: med100,
      shadow: sMed100,
      name: "Everyday language needs",
    },
    {
      code: "121",
      color: light100,
      shadow: sLight100,
      name: "Daily communication (basic words in local language and English language)",
    },
    {
      code: "122",
      color: light100,
      shadow: sLight100,
      name: "Information on language learning availabilities",
    },
    {
      code: "123",
      color: light100,
      shadow: sLight100,
      name: "Cultural, situational and interactional awareness and expectations",
    },
    {
      code: "124",
      color: light100,
      shadow: sLight100,
      name: "Visiting supermarket, school, doctors and hospitals, labour service, seeking for employment, housing, transportation",
    },
    // 200
    {
      code: "200",
      color: dark200,
      shadow: sDark200,
      name: "SECTION 2: Employment",
    },
    { code: "210", color: med200, shadow: sMed200, name: "Social Insurance" },
    {
      code: "211",
      color: light200,
      shadow: sLight200,
      name: "Further information about social insurance services",
    },
    {
      code: "212",
      color: light200,
      shadow: sLight200,
      name: "Protection against various risks: Health and safety awareness and exploitation",
    },
    {
      code: "220",
      color: med200,
      shadow: sMed200,
      name: "Labour/Employment access",
    },
    {
      code: "221",
      color: light200,
      shadow: sLight200,
      name: "Further information about the labour market of the country (eg. How they work)",
    },
    {
      code: "222",
      color: light200,
      shadow: sLight200,
      name: "Being aware of exploitative practices (eg sexual harassment)",
    },
    {
      code: "223",
      color: light200,
      shadow: sLight200,
      name: "Overcoming biases, prejudice",
    },
    {
      code: "224",
      color: light200,
      shadow: sLight200,
      name: "Searching for employment",
    },
    {
      code: "225",
      color: light200,
      shadow: sLight200,
      name: "Working culture of receiving country",
    },
    {
      code: "226",
      color: light200,
      shadow: sLight200,
      name: "Salary-ranges (depending on skills, experience, education) & what to expect",
    },
    // 300
    {
      code: "300",
      color: dark300,
      shadow: sDark300,
      name: "SECTION 3: Settling",
    },
    {
      code: "310",
      color: med300,
      shadow: sMed300,
      name: "Housing & Accommodation",
    },
    {
      code: "311",
      color: light300,
      shadow: sLight300,
      name: "Expectations regarding properties",
    },
    {
      code: "312",
      color: light300,
      shadow: sLight300,
      name: "Bills, rent, utilities",
    },
    {
      code: "313",
      color: light300,
      shadow: sLight300,
      name: "Accessing housing/communal areas/social housing",
    },
    {
      code: "314",
      color: light300,
      shadow: sLight300,
      name: "Overcoming biases, prejudice ",
    },
    {
      code: "320",
      color: med300,
      shadow: sMed300,
      name: "Financial procedures",
    },
    {
      code: "321",
      color: light300,
      shadow: sLight300,
      name: "How banks operate & no banking (eg revolut)",
    },
    {
      code: "322",
      color: light300,
      shadow: sLight300,
      name: "Opening bank accounts",
    },
    {
      code: "323",
      color: light300,
      shadow: sLight300,
      name: "Where to seek for support & guidance",
    },
    {
      code: "324",
      color: light300,
      shadow: sLight300,
      name: "Loan eligibility ",
    },
    {
      code: "325",
      color: light300,
      shadow: sLight300,
      name: "Benefits (financial literacy, administration)",
    },
    {
      code: "326",
      color: light300,
      shadow: sLight300,
      name: "Financial registration in the country of residence",
    },
    {
      code: "330",
      color: med300,
      shadow: sMed300,
      name: "Educational access/steps",
    },
    {
      code: "331",
      color: light300,
      shadow: sLight300,
      name: "Validating studies/diplomas/degrees etc",
    },
    {
      code: "332",
      color: light300,
      shadow: sLight300,
      name: "Seeking further information (eg. Enrolment, criteria, children enrolment into education system)",
    },
    {
      code: "333",
      color: light300,
      shadow: sLight300,
      name: "Accessing advice (eg. Counsellors)",
    },
    {
      code: "340",
      color: light300,
      shadow: sLight300,
      name: "Socialisation/community building",
    },
    {
      code: "341",
      color: light300,
      shadow: sLight300,
      name: "Identifying community events/gatherings with people of same/similar cultures",
    },
    // 400
    {
      code: "400",
      color: dark400,
      shadow: sDark400,
      name: "SECTION 4: Health",
    },
    { code: "410", color: med400, shadow: sMed400, name: "Health Services" },
    {
      code: "411",
      color: light400,
      shadow: sLight400,
      name: "Social security number",
    },
    {
      code: "412",
      color: light400,
      shadow: sLight400,
      name: "Covid-19 & vaccines info",
    },
    {
      code: "413",
      color: light400,
      shadow: sLight400,
      name: "Accessing hospitals and doctors",
    },
    {
      code: "414",
      color: light400,
      shadow: sLight400,
      name: "Information regarding the health system",
    },
    {
      code: "420",
      color: med400,
      shadow: sMed400,
      name: "Psychological support",
    },
    {
      code: "421",
      color: light400,
      shadow: sLight400,
      name: "Accessing psychological support & recognising the need for psychological support & counselling",
    },
    {
      code: "422",
      color: light400,
      shadow: sLight400,
      name: "Costs and information",
    },
    {
      code: "423",
      color: light400,
      shadow: sLight400,
      name: "Removing the stigma to the need of visiting/consistent visiting",
    },
    {
      code: "424",
      color: light400,
      shadow: sLight400,
      name: "Accessing help in case of emergencies",
    },
  ],
  // -----------------------------------------      Cyprus    ----------------------------------------- //
  Cyprus: [
    // 100
    {
      code: "100",
      color: dark100,
      shadow: sDark100,
      name: "Ενότητα 1: Επικοινωνιακές και γλωσσικές δεξιότητες",
    },
    {
      code: "110",
      color: med100,
      shadow: sMed100,
      name: "Επικοινωνία με τοπικές αρχές",
    },
    {
      code: "111",
      color: light100,
      shadow: sLight100,
      name: "Αλληλεπίδραση με τις αρχές",
    },
    {
      code: "112",
      color: light100,
      shadow: sLight100,
      name: "Αντιλήψεις και προσδοκίες σχετικά με την κουλτούρα, τις συνθήκες και την επικοινωνία",
    },
    {
      code: "113",
      color: light100,
      shadow: sLight100,
      name: "Kανόνες και κανονισμοί",
    },
    {
      code: "114",
      color: light100,
      shadow: sLight100,
      name: "Τι πρέπει να κάνετε και τι να μην κάνετε",
    },
    {
      code: "115",
      color: light100,
      shadow: sLight100,
      name: "Πολιτιστικές πτυχές",
    },
    {
      code: "116",
      color: light100,
      shadow: sLight100,
      name: "Πρόσβαση σε βοήθεια κατά τη διάρκεια έκτακτης ανάγκης",
    },
    {
      code: "120",
      color: med100,
      shadow: sMed100,
      name: "Καθημερινές γλωσσικές ανάγκες",
    },
    {
      code: "121",
      color: light100,
      shadow: sLight100,
      name: "Καθημερινή επικοινωνία (βασικές λέξεις στην ελληνική και την αγγλική γλώσσα)",
    },
    {
      code: "122",
      color: light100,
      shadow: sLight100,
      name: "Πληροφορίες για διαθέσιμες ευκαιρίες εκμάθησης της γλώσσας",
    },
    {
      code: "123",
      color: light100,
      shadow: sLight100,
      name: "Αντιλήψεις και προσδοκίες σχετικά με την κουλτούρα, τις συνθήκες και την επικοινωνία",
    },
    {
      code: "124",
      color: light100,
      shadow: sLight100,
      name: "Επίσκεψη στην υπεραγορά, το σχολείο, τον γιατρό και το  νοσοκομείο, τις υπηρεσίες απασχόλησης, αναζήτηση εργασίας, στέγαση και μέσα μαζικής μεταφοράς",
    },
    // 200
    {
      code: "200",
      color: dark200,
      shadow: sDark200,
      name: "Ενότητα 2:  Απασχόληση",
    },
    {
      code: "210",
      color: med200,
      shadow: sMed200,
      name: "Υπηρεσίες Κοινωνικών Ασφαλίσεων",
    },
    {
      code: "211",
      color: light200,
      shadow: sLight200,
      name: "Περισσότερες πληροφορίες για τις Υπηρεσίες Κοινωνικών Ασφαλίσεων",
    },
    {
      code: "212",
      color: light200,
      shadow: sLight200,
      name: "Προστασία από διάφορους κινδύνους: Ενημέρωση και προσδοκίες για την υγεία και την ασφάλεια",
    },
    {
      code: "220",
      color: med200,
      shadow: sMed200,
      name: "Πρόσβαση σε εργασία/απασχόληση",
    },
    {
      code: "221",
      color: light200,
      shadow: sLight200,
      name: "Περισσότερες πληροφορίες για την αγορά εργασίας της χώρας (π.χ. συνθήκες εργασίας)",
    },
    {
      code: "222",
      color: light200,
      shadow: sLight200,
      name: "Επίγνωση των πρακτικών εκμετάλλευσης (π.χ. σεξουαλική παρενόχληση)",
    },
    {
      code: "223",
      color: light200,
      shadow: sLight200,
      name: "Aντιμετώπιση των προκαταλήψεων",
    },
    {
      code: "224",
      color: light200,
      shadow: sLight200,
      name: "Αναζήτηση εργασίας",
    },
    {
      code: "225",
      color: light200,
      shadow: sLight200,
      name: "Εργασιακή κουλτούρα της χώρας υποδοχής",
    },
    {
      code: "226",
      color: light200,
      shadow: sLight200,
      name: "Εύρος μισθών (ανάλογα με τις δεξιότητες, εμπειρία, εκπαίδευση) και τι να περιμένετε",
    },
    // 300
    {
      code: "300",
      color: dark300,
      shadow: sDark300,
      name: "Ενότητα 3:  Εγκατάσταση",
    },
    {
      code: "310",
      color: med300,
      shadow: sMed300,
      name: "Στέγαση (και Διαμονή)",
    },
    {
      code: "311",
      color: light300,
      shadow: sLight300,
      name: "Προσδοκίες σχετικά με τη στέγαση",
    },
    {
      code: "312",
      color: light300,
      shadow: sLight300,
      name: "Λογαριασμοί, ενοίκιο, τέλη",
    },
    {
      code: "313",
      color: light300,
      shadow: sLight300,
      name: "Πρόσβαση σε στέγαση/κέντρα υποδοχής/κοινωνική στέγαση",
    },
    {
      code: "314",
      color: light300,
      shadow: sLight300,
      name: "Αντιμετώπιση των προκαταλήψεων",
    },
    {
      code: "320",
      color: med300,
      shadow: sMed300,
      name: "Οικονομικές διαδικασίες",
    },
    {
      code: "321",
      color: light300,
      shadow: sLight300,
      name: "Τρόπος λειτουργίας των τραπεζών και εναλλακτικοί τρόποι τραπεζικών συναλλαγών (π.χ. Revolut)",
    },
    {
      code: "322",
      color: light300,
      shadow: sLight300,
      name: "Άνοιγμα τραπεζικού λογαριασμού",
    },
    {
      code: "323",
      color: light300,
      shadow: sLight300,
      name: "Πού να αναζητήσετε υποστήριξη και καθοδήγηση",
    },
    {
      code: "324",
      color: light300,
      shadow: sLight300,
      name: "Δικαίωμα για δάνειο",
    },
    {
      code: "325",
      color: light300,
      shadow: sLight300,
      name: "Ωφελήματα (οικονομικά και άλλα)",
    },
    {
      code: "326",
      color: light300,
      shadow: sLight300,
      name: "Οικονομική ένταξη στη χώρα διαμονής",
    },
    {
      code: "330",
      color: med300,
      shadow: sMed300,
      name: "Πρόσβαση σε εκπαίδευση/τα βήματα",
    },
    {
      code: "331",
      color: light300,
      shadow: sLight300,
      name: "Επικύρωση σπουδών/διπλωμάτων/πτυχίων, κ.λπ.",
    },
    {
      code: "332",
      color: light300,
      shadow: sLight300,
      name: "Αναζήτηση επιπλέον πληροφοριών (π.χ. εγγραφή, κριτήρια, εγγραφή παιδιών στο εκπαιδευτικό σύστημα)",
    },
    {
      code: "333",
      color: light300,
      shadow: sLight300,
      name: "Πρόσβαση σε συμβουλευτική (π.χ. σύμβουλοι)",
    },
    {
      code: "340",
      color: light300,
      shadow: sLight300,
      name: "Κοινωνικοποίηση/οικοδόμηση κοινότητας",
    },
    {
      code: "341",
      color: light300,
      shadow: sLight300,
      name: "Εξεύρεση κοινοτικών εκδηλώσεων - συναντήσεις με άτομα της ίδιας/παρόμοιας κουλτούρας",
    },
    // 400
    {
      code: "400",
      color: dark400,
      shadow: sDark400,
      name: "Ενότητα 4: Υγεία",
    },
    { code: "410", color: med400, shadow: sMed400, name: "Υπηρεσίες υγείας" },
    {
      code: "411",
      color: light400,
      shadow: sLight400,
      name: "Αριθμός κοινωνικών ασφαλίσεων",
    },
    {
      code: "412",
      color: light400,
      shadow: sLight400,
      name: "Πληροφορίες για Covid-19 και εμβολιασμό",
    },
    {
      code: "413",
      color: light400,
      shadow: sLight400,
      name: "Πρόσβαση σε νοσοκομεία και γιατρούς",
    },
    {
      code: "414",
      color: light400,
      shadow: sLight400,
      name: "Πληροφορίες για το σύστημα υγείας",
    },
    {
      code: "420",
      color: med400,
      shadow: sMed400,
      name: "Ψυχολογική υποστήριξη",
    },
    {
      code: "421",
      color: light400,
      shadow: sLight400,
      name: "Πρόσβαση σε ψυχολογική υποστήριξη και αναγνώριση της ανάγκης για ψυχολογική υποστήριξη και συμβουλευτική",
    },
    {
      code: "422",
      color: light400,
      shadow: sLight400,
      name: "Κόστος και πληροφορίες",
    },
    {
      code: "423",
      color: light400,
      shadow: sLight400,
      name: "Απαλλαγή από το στίγμα της ανάγκης για επίσκεψη/σταθερές επισκέψεις σε ειδικό",
    },
    {
      code: "424",
      color: light400,
      shadow: sLight400,
      name: "Πρόσβαση σε βοήθεια σε περίπτωση έκτακτης ανάγκης",
    },
  ],
  // ------------------------------------------- SPAIN ------------------------------------------- //
  Spain: [
    // 100
    {
      code: "100",
      color: dark100,
      shadow: sDark100,
      name: "Módulos 1:  Habilidades comunicativas y lingüísticas",
    },
    {
      code: "110",
      color: med100,
      shadow: sMed100,
      name: "Comunicación con las autoridades locales",
    },
    {
      code: "111",
      color: light100,
      shadow: sLight100,
      name: "Interacción con las autoridades",
    },
    {
      code: "112",
      color: light100,
      shadow: sLight100,
      name: "Conocimiento y expectativas culturales, situacionales e interaccionales",
    },
    {
      code: "113",
      color: light100,
      shadow: sLight100,
      name: "Normas y reglamentos",
    },
    {
      code: "114",
      color: light100,
      shadow: sLight100,
      name: "Qué hacer y qué no hacer",
    },
    {
      code: "115",
      color: light100,
      shadow: sLight100,
      name: "Aspectos culturales",
    },
    {
      code: "116",
      color: light100,
      shadow: sLight100,
      name: "Acceso a la ayuda en caso de emergencia",
    },
    {
      code: "120",
      color: med100,
      shadow: sMed100,
      name: "Necesidades lingüísticas cotidianas",
    },
    {
      code: "121",
      color: light100,
      shadow: sLight100,
      name: "Comunicación diaria (palabras básicas en lengua local y en inglés)",
    },
    {
      code: "122",
      color: light100,
      shadow: sLight100,
      name: "Información sobre las posibilidades de aprendizaje del idioma",
    },
    {
      code: "123",
      color: light100,
      shadow: sLight100,
      name: "Conocimiento y expectativas culturales, situacionales e interaccionales",
    },
    {
      code: "124",
      color: light100,
      shadow: sLight100,
      name: "Visitar supermercados, escuelas, médicos y hospitales, servicios laborales, buscar empleo, vivienda, transporte",
    },
    // 200
    {
      code: "200",
      color: dark200,
      shadow: sDark200,
      name: "Módulos 2:  Empleo",
    },
    {
      code: "210",
      color: med200,
      shadow: sMed200,
      name: "Servicios de la Seguridad Social",
    },
    {
      code: "211",
      color: light200,
      shadow: sLight200,
      name: "Información adicional sobre los servicios de la seguridad social",
    },
    {
      code: "212",
      color: light200,
      shadow: sLight200,
      name: "Protección frente a diversos riesgos: Concienciación y explotación de la salud y la seguridad",
    },
    {
      code: "220",
      color: med200,
      shadow: sMed200,
      name: "Trabajo/Acceso al empleo",
    },
    {
      code: "221",
      color: light200,
      shadow: sLight200,
      name: "Más información sobre el mercado laboral del país (p. ej. Cómo funcionan)",
    },
    {
      code: "222",
      color: light200,
      shadow: sLight200,
      name: "Ser consciente de las prácticas de explotación (por ejemplo, el acoso sexual)",
    },
    {
      code: "223",
      color: light200,
      shadow: sLight200,
      name: "Superar los prejuicios",
    },
    {
      code: "224",
      color: light200,
      shadow: sLight200,
      name: "Búsqueda de empleo",
    },
    {
      code: "225",
      color: light200,
      shadow: sLight200,
      name: "Cultura laboral del país de acogida",
    },
    {
      code: "226",
      color: light200,
      shadow: sLight200,
      name: "Salarios (en función de las competencias, la experiencia y la formación) y expectativas",
    },
    // 300
    {
      code: "300",
      color: dark300,
      shadow: sDark300,
      name: "Módulos 3:  Vivienta",
    },
    {
      code: "310",
      color: med300,
      shadow: sMed300,
      name: "Vivienda (y alojamiento)",
    },
    {
      code: "311",
      color: light300,
      shadow: sLight300,
      name: "Expectativas respecto de las propiedades",
    },
    {
      code: "312",
      color: light300,
      shadow: sLight300,
      name: "Facturas, alquiler, servicios",
    },
    {
      code: "313",
      color: light300,
      shadow: sLight300,
      name: "Acceso a la vivienda/zonas comunes/vivienda social",
    },
    {
      code: "314",
      color: light300,
      shadow: sLight300,
      name: "Superar los prejuicios",
    },
    {
      code: "320",
      color: med300,
      shadow: sMed300,
      name: "Procedimientos financieros",
    },
    {
      code: "321",
      color: light300,
      shadow: sLight300,
      name: "Cómo operan los bancos & neobancos (p.ej. Revolut)",
    },
    {
      code: "322",
      color: light300,
      shadow: sLight300,
      name: "Abrir cuentas bancarias",
    },
    {
      code: "323",
      color: light300,
      shadow: sLight300,
      name: "Dónde encontrar apoyo & orientación",
    },
    {
      code: "324",
      color: light300,
      shadow: sLight300,
      name: "Elegibilidad para un préstamo",
    },
    {
      code: "325",
      color: light300,
      shadow: sLight300,
      name: "Beneficios (conocimientos financieros, administración)",
    },
    {
      code: "326",
      color: light300,
      shadow: sLight300,
      name: "Registro fiscal en el país de residencia",
    },
    {
      code: "330",
      color: med300,
      shadow: sMed300,
      name: "Acceso a la educación/pasos",
    },
    {
      code: "331",
      color: light300,
      shadow: sLight300,
      name: "Convalidar estudios/diplomas/títulos, etc.",
    },
    {
      code: "332",
      color: light300,
      shadow: sLight300,
      name: "Buscar información adicional (p.ej. matriculación, criterios, matriculación de los niños en el sistema educativo)",
    },
    {
      code: "333",
      color: light300,
      shadow: sLight300,
      name: "Acceder al asesoramiento (p.ej. consejeros)",
    },
    {
      code: "340",
      color: light300,
      shadow: sLight300,
      name: "Socialización/creación de comunidad",
    },
    {
      code: "341",
      color: light300,
      shadow: sLight300,
      name: "Identificar los eventos comunitarios/reuniones con personas de la misma o similar cultura",
    },
    // 400
    {
      code: "400",
      color: dark400,
      shadow: sDark400,
      name: "Módulos 4:  Salud",
    },
    {
      code: "410",
      color: med400,
      shadow: sMed400,
      name: "Servicios sanitarios",
    },
    {
      code: "411",
      color: light400,
      shadow: sLight400,
      name: "Número de seguridad social",
    },
    {
      code: "412",
      color: light400,
      shadow: sLight400,
      name: "Covid-19 & información sobre las vacunas",
    },
    {
      code: "413",
      color: light400,
      shadow: sLight400,
      name: "Acceso a hospitales y médicos",
    },
    {
      code: "414",
      color: light400,
      shadow: sLight400,
      name: "Información con respecto al sistema sanitario",
    },
    { code: "420", color: med400, shadow: sMed400, name: "Apoyo psicológico" },
    {
      code: "421",
      color: light400,
      shadow: sLight400,
      name: "Acceder al apoyo psicológico & asesoramiento",
    },
    {
      code: "422",
      color: light400,
      shadow: sLight400,
      name: "Costos e información",
    },
    {
      code: "423",
      color: light400,
      shadow: sLight400,
      name: "Eliminar el estigma de la necesidad de visitas/visitas constantes",
    },
    {
      code: "424",
      color: light400,
      shadow: sLight400,
      name: "Acceder a la ayuda en caso de emergencias",
    },
  ],

  Greece: [
    // 100
    {
      code: "100",
      color: dark100,
      shadow: sDark100,
      name: "Ενότητα 1: Επικοινωνιακές και γλωσσικές δεξιότητες",
    },
    {
      code: "110",
      color: med100,
      shadow: sMed100,
      name: "Επικοινωνία με τοπικές αρχές",
    },
    {
      code: "111",
      color: light100,
      shadow: sLight100,
      name: "Αλληλεπίδραση με τις αρχές",
    },
    {
      code: "112",
      color: light100,
      shadow: sLight100,
      name: "Αντιλήψεις και προσδοκίες σχετικά με την κουλτούρα, τις συνθήκες και την επικοινωνία",
    },
    {
      code: "113",
      color: light100,
      shadow: sLight100,
      name: "Kανόνες και κανονισμοί",
    },
    {
      code: "114",
      color: light100,
      shadow: sLight100,
      name: "Τι πρέπει να κάνετε και τι να μην κάνετε",
    },
    {
      code: "115",
      color: light100,
      shadow: sLight100,
      name: "Πολιτιστικές πτυχές",
    },
    {
      code: "116",
      color: light100,
      shadow: sLight100,
      name: "Πρόσβαση σε βοήθεια κατά τη διάρκεια έκτακτης ανάγκης",
    },
    {
      code: "120",
      color: med100,
      shadow: sMed100,
      name: "Καθημερινές γλωσσικές ανάγκες",
    },
    {
      code: "121",
      color: light100,
      shadow: sLight100,
      name: "Καθημερινή επικοινωνία (βασικές λέξεις στην ελληνική και την αγγλική γλώσσα)",
    },
    {
      code: "122",
      color: light100,
      shadow: sLight100,
      name: "Πληροφορίες για διαθέσιμες ευκαιρίες εκμάθησης της γλώσσας",
    },
    {
      code: "123",
      color: light100,
      shadow: sLight100,
      name: "Αντιλήψεις και προσδοκίες σχετικά με την κουλτούρα, τις συνθήκες και την επικοινωνία",
    },
    {
      code: "124",
      color: light100,
      shadow: sLight100,
      name: "Επίσκεψη στην υπεραγορά, το σχολείο, τον γιατρό και το  νοσοκομείο, τις υπηρεσίες απασχόλησης, αναζήτηση εργασίας, στέγαση και μέσα μαζικής μεταφοράς",
    },
    // 200
    {
      code: "200",
      color: dark200,
      shadow: sDark200,
      name: "Ενότητα 2: Απασχόληση",
    },
    {
      code: "210",
      color: med200,
      shadow: sMed200,
      name: "Υπηρεσίες Κοινωνικών Ασφαλίσεων",
    },
    {
      code: "211",
      color: light200,
      shadow: sLight200,
      name: "Περισσότερες πληροφορίες για τις Υπηρεσίες Κοινωνικών Ασφαλίσεων",
    },
    {
      code: "212",
      color: light200,
      shadow: sLight200,
      name: "Προστασία από διάφορους κινδύνους: Ενημέρωση και προσδοκίες για την υγεία και την ασφάλεια",
    },
    {
      code: "220",
      color: med200,
      shadow: sMed200,
      name: "Πρόσβαση σε εργασία/απασχόληση",
    },
    {
      code: "221",
      color: light200,
      shadow: sLight200,
      name: "Περισσότερες πληροφορίες για την αγορά εργασίας της χώρας (π.χ. συνθήκες εργασίας)",
    },
    {
      code: "222",
      color: light200,
      shadow: sLight200,
      name: "Επίγνωση των πρακτικών εκμετάλλευσης (π.χ. σεξουαλική παρενόχληση)",
    },
    {
      code: "223",
      color: light200,
      shadow: sLight200,
      name: "Aντιμετώπιση των προκαταλήψεων",
    },
    {
      code: "224",
      color: light200,
      shadow: sLight200,
      name: "Αναζήτηση εργασίας",
    },
    {
      code: "225",
      color: light200,
      shadow: sLight200,
      name: "Εργασιακή κουλτούρα της χώρας υποδοχής",
    },
    {
      code: "226",
      color: light200,
      shadow: sLight200,
      name: "Εύρος μισθών (ανάλογα με τις δεξιότητες, εμπειρία, εκπαίδευση) και τι να περιμένετε",
    },
    // 300
    {
      code: "300",
      color: dark300,
      shadow: sDark300,
      name: "Ενότητα 3:  Εγκατάσταση",
    },
    {
      code: "310",
      color: med300,
      shadow: sMed300,
      name: "Στέγαση (και Διαμονή)",
    },
    {
      code: "311",
      color: light300,
      shadow: sLight300,
      name: "Προσδοκίες σχετικά με τη στέγαση",
    },
    {
      code: "312",
      color: light300,
      shadow: sLight300,
      name: "Λογαριασμοί, ενοίκιο, τέλη",
    },
    {
      code: "313",
      color: light300,
      shadow: sLight300,
      name: "Πρόσβαση σε στέγαση/κέντρα υποδοχής/κοινωνική στέγαση",
    },
    {
      code: "314",
      color: light300,
      shadow: sLight300,
      name: "Αντιμετώπιση των προκαταλήψεων",
    },
    {
      code: "320",
      color: med300,
      shadow: sMed300,
      name: "Οικονομικές διαδικασίες",
    },
    {
      code: "321",
      color: light300,
      shadow: sLight300,
      name: "Τρόπος λειτουργίας των τραπεζών και εναλλακτικοί τρόποι τραπεζικών συναλλαγών (π.χ. Revolut)",
    },
    {
      code: "322",
      color: light300,
      shadow: sLight300,
      name: "Άνοιγμα τραπεζικού λογαριασμού",
    },
    {
      code: "323",
      color: light300,
      shadow: sLight300,
      name: "Πού να αναζητήσετε υποστήριξη και καθοδήγηση",
    },
    {
      code: "324",
      color: light300,
      shadow: sLight300,
      name: "Δικαίωμα για δάνειο",
    },
    {
      code: "325",
      color: light300,
      shadow: sLight300,
      name: "Ωφελήματα (οικονομικά και άλλα)",
    },
    {
      code: "326",
      color: light300,
      shadow: sLight300,
      name: "Οικονομική ένταξη στη χώρα διαμονής",
    },
    {
      code: "330",
      color: med300,
      shadow: sMed300,
      name: "Πρόσβαση σε εκπαίδευση/τα βήματα",
    },
    {
      code: "331",
      color: light300,
      shadow: sLight300,
      name: "Επικύρωση σπουδών/διπλωμάτων/πτυχίων, κ.λπ.",
    },
    {
      code: "332",
      color: light300,
      shadow: sLight300,
      name: "Αναζήτηση επιπλέον πληροφοριών (π.χ. εγγραφή, κριτήρια, εγγραφή παιδιών στο εκπαιδευτικό σύστημα)",
    },
    {
      code: "333",
      color: light300,
      shadow: sLight300,
      name: "Πρόσβαση σε συμβουλευτική (π.χ. σύμβουλοι)",
    },
    {
      code: "340",
      color: light300,
      shadow: sLight300,
      name: "Κοινωνικοποίηση/οικοδόμηση κοινότητας",
    },
    {
      code: "341",
      color: light300,
      shadow: sLight300,
      name: "Εξεύρεση κοινοτικών εκδηλώσεων - συναντήσεις με άτομα της ίδιας/παρόμοιας κουλτούρας",
    },
    // 400
    {
      code: "400",
      color: dark400,
      shadow: sDark400,
      name: "Ενότητα 4: Υγεία",
    },
    { code: "410", color: med400, shadow: sMed400, name: "Υπηρεσίες υγείας" },
    {
      code: "411",
      color: light400,
      shadow: sLight400,
      name: "Αριθμός κοινωνικών ασφαλίσεων",
    },
    {
      code: "412",
      color: light400,
      shadow: sLight400,
      name: "Πληροφορίες για Covid-19 και εμβολιασμό",
    },
    {
      code: "413",
      color: light400,
      shadow: sLight400,
      name: "Πρόσβαση σε νοσοκομεία και γιατρούς",
    },
    {
      code: "414",
      color: light400,
      shadow: sLight400,
      name: "Πληροφορίες για το σύστημα υγείας",
    },
    {
      code: "420",
      color: med400,
      shadow: sMed400,
      name: "Ψυχολογική υποστήριξη",
    },
    {
      code: "421",
      color: light400,
      shadow: sLight400,
      name: "Πρόσβαση σε ψυχολογική υποστήριξη και αναγνώριση της ανάγκης για ψυχολογική υποστήριξη και συμβουλευτική",
    },
    {
      code: "422",
      color: light400,
      shadow: sLight400,
      name: "Κόστος και πληροφορίες",
    },
    {
      code: "423",
      color: light400,
      shadow: sLight400,
      name: "Απαλλαγή από το στίγμα της ανάγκης για επίσκεψη/σταθερές επισκέψεις σε ειδικό",
    },
    {
      code: "424",
      color: light400,
      shadow: sLight400,
      name: "Πρόσβαση σε βοήθεια σε περίπτωση έκτακτης ανάγκης",
    },
  ],

  Italy: [
    // 100
    {
      code: "100",
      color: dark100,
      shadow: sDark100,
      name: "MODULO 1:  Comunicazione - Competenze Linguistiche",
    },
    {
      code: "110",
      color: med100,
      shadow: sMed100,
      name: "Comunicare con le autorità locali",
    },
    {
      code: "111",
      color: light100,
      shadow: sLight100,
      name: "Interagire con le autorità",
    },
    {
      code: "112",
      color: light100,
      shadow: sLight100,
      name: "Consapevolezza culturale, situazionale, relazionale e aspettative",
    },
    {
      code: "113",
      color: light100,
      shadow: sLight100,
      name: "Norme e regolamenti",
    },
    {
      code: "114",
      color: light100,
      shadow: sLight100,
      name: "Cosa fare e cosa non fare",
    },
    {
      code: "115",
      color: light100,
      shadow: sLight100,
      name: "Aspetti culturali",
    },
    {
      code: "116",
      color: light100,
      shadow: sLight100,
      name: "Chiedere aiuto in caso di emergenza",
    },
    {
      code: "120",
      color: med100,
      shadow: sMed100,
      name: "Bisogni linguistici di base",
    },
    {
      code: "121",
      color: light100,
      shadow: sLight100,
      name: "Comunicazione quotidiana (Lessico base in italiano e inglese)",
    },
    {
      code: "122",
      color: light100,
      shadow: sLight100,
      name: "Informazioni su come imparare la lingua",
    },
    {
      code: "123",
      color: light100,
      shadow: sLight100,
      name: "Consapevolezza culturale, situazionale, relazionale e aspettative",
    },
    {
      code: "124",
      color: light100,
      shadow: sLight100,
      name: "Andare al supermercato, a scuola, dal dottore e all'ospedale, cercare lavoro, casa e spostarsi",
    },
    // 200
    {
      code: "200",
      color: dark200,
      shadow: sDark200,
      name: "MODULO 2:  Lavorare",
    },
    {
      code: "210",
      color: med200,
      shadow: sMed200,
      name: "Servizi di Assistenza Sociale",
    },
    {
      code: "211",
      color: light200,
      shadow: sLight200,
      name: "Informazioni sui servizi di assistenza",
    },
    {
      code: "212",
      color: light200,
      shadow: sLight200,
      name: "Protezione contro i rischi: consapevolezza della salute e sicurezza e sftruttamento",
    },
    {
      code: "220",
      color: med200,
      shadow: sMed200,
      name: "Lavoro/Accesso all'impego",
    },
    {
      code: "221",
      color: light200,
      shadow: sLight200,
      name: "Informazioni sul mercato del lavoro nel Paese (es. come funziona)",
    },
    {
      code: "222",
      color: light200,
      shadow: sLight200,
      name: "Conoscere le pratiche di sfruttamento (es. molestie e mobbing)",
    },
    {
      code: "223",
      color: light200,
      shadow: sLight200,
      name: "Superare gli stereotipi e pregiudizi",
    },
    { code: "224", color: light200, shadow: sLight200, name: "Cercare lavoro" },
    {
      code: "225",
      color: light200,
      shadow: sLight200,
      name: "Approccio al lavoro del Paese di destinazione",
    },
    {
      code: "226",
      color: light200,
      shadow: sLight200,
      name: "Livelli salariali (incidenza di competenze, esperienze, educazione) e cosa aspettarsi",
    },
    // 300
    {
      code: "300",
      color: dark300,
      shadow: sDark300,
      name: "MODULO 3:  Abitare",
    },
    {
      code: "310",
      color: med300,
      shadow: sMed300,
      name: "Alloggi e accoglienza",
    },
    {
      code: "311",
      color: light300,
      shadow: sLight300,
      name: "Aspettative riguardanti gli immobili",
    },
    {
      code: "312",
      color: light300,
      shadow: sLight300,
      name: "Bollette, affitto, spese generali",
    },
    {
      code: "313",
      color: light300,
      shadow: sLight300,
      name: "Accesso agli alloggi/edilizia popolare",
    },
    {
      code: "314",
      color: light300,
      shadow: sLight300,
      name: "Superare Stereotipi e Pregiudizi",
    },
    {
      code: "320",
      color: med300,
      shadow: sMed300,
      name: "Gestione finanziaria",
    },
    {
      code: "321",
      color: light300,
      shadow: sLight300,
      name: "Come funziona il sistema bancario",
    },
    {
      code: "322",
      color: light300,
      shadow: sLight300,
      name: "Aprire un conto in banca",
    },
    {
      code: "323",
      color: light300,
      shadow: sLight300,
      name: "Servizi di supporto",
    },
    {
      code: "324",
      color: light300,
      shadow: sLight300,
      name: "Ottenere un prestito/finanziamento",
    },
    { code: "325", color: light300, shadow: sLight300, name: "Sgravi fiscali" },
    {
      code: "326",
      color: light300,
      shadow: sLight300,
      name: "Registrazione fiscale",
    },
    {
      code: "330",
      color: med300,
      shadow: sMed300,
      name: "Accesso all’istruzione",
    },
    {
      code: "331",
      color: light300,
      shadow: sLight300,
      name: "Riconoscere titoli e competenze",
    },
    {
      code: "332",
      color: light300,
      shadow: sLight300,
      name: "Ulteriori informazioni (es. Iscrizioni, inserimento dei bambini nel sistema scolastico)",
    },
    {
      code: "333",
      color: light300,
      shadow: sLight300,
      name: "Supporto e consulenza ",
    },
    {
      code: "340",
      color: light300,
      shadow: sLight300,
      name: "Socializzare/vivere in comunità",
    },
    {
      code: "341",
      color: light300,
      shadow: sLight300,
      name: " Eventi sociali/contatti con persone della propria comunità d’origine",
    },
    // 400
    {
      code: "400",
      color: dark400,
      shadow: sDark400,
      name: "MODULO 4:  Salute",
    },
    { code: "410", color: med400, shadow: sMed400, name: "Servizi sanitari" },
    {
      code: "411",
      color: light400,
      shadow: sLight400,
      name: "Il Codice Fiscale",
    },
    {
      code: "412",
      color: light400,
      shadow: sLight400,
      name: "Informazioni su COVID-19 e vaccini",
    },
    {
      code: "413",
      color: light400,
      shadow: sLight400,
      name: "Andare in ospedale e dal medico",
    },
    {
      code: "414",
      color: light400,
      shadow: sLight400,
      name: "Informazioni sul Sistema Sanitario",
    },
    {
      code: "420",
      color: med400,
      shadow: sMed400,
      name: "Supporto Psicologico",
    },
    {
      code: "421",
      color: light400,
      shadow: sLight400,
      name: "Accedere ai servizi di supporto psicologico e riconoscere la necessità di aiuto",
    },
    {
      code: "422",
      color: light400,
      shadow: sLight400,
      name: "Costi e informazioni",
    },
    {
      code: "423",
      color: light400,
      shadow: sLight400,
      name: "Superare i pregiudizi e seguire il percorso terapeutico",
    },
    {
      code: "424",
      color: light400,
      shadow: sLight400,
      name: "Accesso ai soccorsi in caso di emergenza",
    },
  ],

  Lithuania: [
    // 100
    {
      code: "100",
      color: dark100,
      shadow: sDark100,
      name: "SKYRIUS 1:  Komunikacijos ir kalbos įgūdžiai",
    },
    {
      code: "110",
      color: med100,
      shadow: sMed100,
      name: "Bendravimas su vietinėmis institucijomis",
    },
    {
      code: "111",
      color: light100,
      shadow: sLight100,
      name: "Bendravimas su valstybinėmis įstaigomis",
    },
    {
      code: "112",
      color: light100,
      shadow: sLight100,
      name: "Kultūriniai ir vietiniai elgesio aspektai, situacijos bei lūkesčiai",
    },
    {
      code: "113",
      color: light100,
      shadow: sLight100,
      name: "Taisyklės ir tvarkos",
    },
    {
      code: "114",
      color: light100,
      shadow: sLight100,
      name: "Kaip elgtis ir ko nedaryti",
    },
    {
      code: "115",
      color: light100,
      shadow: sLight100,
      name: "Kultūriniai aspektai",
    },
    {
      code: "116",
      color: light100,
      shadow: sLight100,
      name: "Pagalba nelaimės atveju",
    },
    {
      code: "120",
      color: med100,
      shadow: sMed100,
      name: "Kasdieniai kalbos poreikiai",
    },
    {
      code: "121",
      color: light100,
      shadow: sLight100,
      name: "Kasdienis bendravimas (pagrindiniai žodžiai vietine kalba ir anglų kalba)",
    },
    {
      code: "122",
      color: light100,
      shadow: sLight100,
      name: "Informacija apie kalbos mokymąsi",
    },
    {
      code: "123",
      color: light100,
      shadow: sLight100,
      name: "Kultūriniai ir vietiniai elgesio aspektai, situacijos bei lūkesčiai",
    },
    {
      code: "124",
      color: light100,
      shadow: sLight100,
      name: "Lankymasis prekybos centruose, mokyklose, pas gydytojus ir ligoninėse, Užimtumo tarnyboje, darbo paieška, būstas, transportas.",
    },
    // 200
    {
      code: "200",
      color: dark200,
      shadow: sDark200,
      name: "SKYRIUS 2:  Įdarbinimas",
    },
    {
      code: "210",
      color: med200,
      shadow: sMed200,
      name: "Socialinis draudimas",
    },
    {
      code: "211",
      color: light200,
      shadow: sLight200,
      name: "Informacija apie socialinį draudimą",
    },
    {
      code: "212",
      color: light200,
      shadow: sLight200,
      name: "Kaip apsisaugoti nuo galimų rizikų: sveikata, darbo sauga ir išnaudojimas",
    },
    { code: "220", color: med200, shadow: sMed200, name: "Darbas" },
    {
      code: "221",
      color: light200,
      shadow: sLight200,
      name: "Papildoma informacija apie šalies darbo rinkos ypatumus",
    },
    {
      code: "222",
      color: light200,
      shadow: sLight200,
      name: "Ką daryti išnaudojimo atvejais",
    },
    {
      code: "223",
      color: light200,
      shadow: sLight200,
      name: "Šališkumo ir išankstinių nuostatų įveikimas",
    },
    { code: "224", color: light200, shadow: sLight200, name: "Ieškant darbo" },
    {
      code: "225",
      color: light200,
      shadow: sLight200,
      name: "Darbo kultūra priimančioje šalyje",
    },
    {
      code: "226",
      color: light200,
      shadow: sLight200,
      name: "Atlyginimų dydžiai (priklausomai nuo įgūdžių, patirties ir išsilavinimo)",
    },
    // 300
    {
      code: "300",
      color: dark300,
      shadow: sDark300,
      name: "SKYRIUS 3:  Įsikūrimas",
    },
    {
      code: "310",
      color: med300,
      shadow: sMed300,
      name: "Būstas (ir apgyvendinimas)",
    },
    {
      code: "311",
      color: light300,
      shadow: sLight300,
      name: "Lūkesčiai, susiję su nuosavybe/nekilnojamu turtu",
    },
    {
      code: "312",
      color: light300,
      shadow: sLight300,
      name: "Sąskaitos, nuoma, komunaliniai mokesčiai",
    },
    {
      code: "313",
      color: light300,
      shadow: sLight300,
      name: "Galimybė gauti būstą/bendro naudojimo patalpas/socialinį būstą",
    },
    {
      code: "314",
      color: light300,
      shadow: sLight300,
      name: "Šališkumo, išankstinių nuostatų adresavimas",
    },
    {
      code: "320",
      color: med300,
      shadow: sMed300,
      name: "Finansinės procedūros",
    },
    {
      code: "321",
      color: light300,
      shadow: sLight300,
      name: "Kaip veikia bankai; kokios yra alternatyvos (pvz., revolut)",
    },
    {
      code: "322",
      color: light300,
      shadow: sLight300,
      name: "Banko sąskaitų atidarymas",
    },
    {
      code: "323",
      color: light300,
      shadow: sLight300,
      name: "Kur kreiptis pagalbos; konsultacijos",
    },
    {
      code: "324",
      color: light300,
      shadow: sLight300,
      name: "Reikalavimai paskolai gauti",
    },
    {
      code: "325",
      color: light300,
      shadow: sLight300,
      name: "Išmokos (finansinis raštingumas, administravimas)",
    },
    {
      code: "326",
      color: light300,
      shadow: sLight300,
      name: "Mokesčiai ir rezidavimas šalyje",
    },
    {
      code: "330",
      color: med300,
      shadow: sMed300,
      name: "Švietimo prieinamumas ir (arba) pakopos",
    },
    {
      code: "331",
      color: light300,
      shadow: sLight300,
      name: "Studijų/diplomų/laipsnių pripažinimas",
    },
    {
      code: "332",
      color: light300,
      shadow: sLight300,
      name: "Kur gauti daugiau informacijos (pvz., registracija mokymo įstaigose, kriterijai, vaikų priėmimas į švietimo sistemą)",
    },
    {
      code: "333",
      color: light300,
      shadow: sLight300,
      name: "Galimybė gauti konsultacijas (pvz., konsultantų)",
    },
    {
      code: "340",
      color: light300,
      shadow: sLight300,
      name: "Socializacija ir bendruomenės kūrimas",
    },
    {
      code: "341",
      color: light300,
      shadow: sLight300,
      name: "Vietos bendruomenės, renginiai ir susibūrimai",
    },
    // 400
    {
      code: "400",
      color: dark400,
      shadow: sDark400,
      name: "SKYRIUS 4:  Sveikata",
    },
    {
      code: "410",
      color: med400,
      shadow: sMed400,
      name: "Sveikatos priežiūros paslaugos",
    },
    {
      code: "411",
      color: light400,
      shadow: sLight400,
      name: "Socialinio draudimo numeris",
    },
    {
      code: "412",
      color: light400,
      shadow: sLight400,
      name: "Covid-19 ir vakcinos informacija",
    },
    {
      code: "413",
      color: light400,
      shadow: sLight400,
      name: "Galimybė kreiptis į ligonines ir gydytojus",
    },
    {
      code: "414",
      color: light400,
      shadow: sLight400,
      name: "Informacija apie sveikatos sistemą",
    },
    {
      code: "420",
      color: med400,
      shadow: sMed400,
      name: "Psichologinė pagalba",
    },
    {
      code: "421",
      color: light400,
      shadow: sLight400,
      name: "Psichologinės pagalbos gavimas ir psichologinės pagalbos bei konsultacijų poreikio pripažinimas",
    },
    {
      code: "422",
      color: light400,
      shadow: sLight400,
      name: "Kainos ir kita informacija (pvz., nemokamai paslaugas teikiančių specialistų sąrašas)",
    },
    {
      code: "423",
      color: light400,
      shadow: sLight400,
      name: "Stigmos, susijusios su psichologinės pagalbos poreikiu (stebėsena, progresas, vertinimas).",
    },
    {
      code: "424",
      color: light400,
      shadow: sLight400,
      name: "Pagalbos gavimas kritiniais atvejais",
    },
  ],

  Sweden: [
    // 100
    {
      code: "100",
      color: dark100,
      shadow: sDark100,
      name: "MODULER 1:  Kommunikation och språkfärdigheter",
    },
    {
      code: "110",
      color: med100,
      shadow: sMed100,
      name: "Kommunikation med lokala myndigheter",
    },
    {
      code: "111",
      color: light100,
      shadow: sLight100,
      name: "Interagera med myndigheter",
    },
    {
      code: "112",
      color: light100,
      shadow: sLight100,
      name: "Kulturell, situations- och interaktionsrelaterad medvetenhet och förväntningar",
    },
    {
      code: "113",
      color: light100,
      shadow: sLight100,
      name: "Regler och bestämmelser",
    },
    { code: "114", color: light100, shadow: sLight100, name: "Dos och Don'ts" },
    {
      code: "115",
      color: light100,
      shadow: sLight100,
      name: "Kulturella aspekter",
    },
    {
      code: "116",
      color: light100,
      shadow: sLight100,
      name: "Tillgång till hjälp i nödsituationer",
    },
    {
      code: "120",
      color: med100,
      shadow: sMed100,
      name: "Språkliga behov i vardagen",
    },
    {
      code: "121",
      color: light100,
      shadow: sLight100,
      name: "Daglig kommunikation (grundläggande ord på det lokala språket och engelska)",
    },
    {
      code: "122",
      color: light100,
      shadow: sLight100,
      name: "Information om möjligheter till språkinlärning",
    },
    {
      code: "123",
      color: light100,
      shadow: sLight100,
      name: "Kulturell, situations- och interaktionsrelaterad medvetenhet och förväntningar",
    },
    {
      code: "124",
      color: light100,
      shadow: sLight100,
      name: "Besöka snabbköp, skola, läkare och sjukhus, arbetsförmedling, söka arbete, bostad, transport ",
    },
    // 200
    {
      code: "200",
      color: dark200,
      shadow: sDark200,
      name: "MODULER 2:  Sysselsättning",
    },
    { code: "210", color: med200, shadow: sMed200, name: "Socialförsäkring" },
    {
      code: "211",
      color: light200,
      shadow: sLight200,
      name: "Ytterligare information om socialförsäkringstjänster",
    },
    {
      code: "212",
      color: light200,
      shadow: sLight200,
      name: "Skydd mot olika risker: Medvetenhet om och utnyttjande av hälsa och säkerhet",
    },
    {
      code: "220",
      color: med200,
      shadow: sMed200,
      name: "Arbete/sysselsättning",
    },
    {
      code: "221",
      color: light200,
      shadow: sLight200,
      name: "Ytterligare information om arbetsmarknaden i landet (t.ex. hur de arbetar)",
    },
    {
      code: "222",
      color: light200,
      shadow: sLight200,
      name: "Att vara medveten om exploaterande metoder (t.ex. sexuella trakasserier)",
    },
    {
      code: "223",
      color: light200,
      shadow: sLight200,
      name: "Övervinna fördomar och förutfattade meningar",
    },
    {
      code: "224",
      color: light200,
      shadow: sLight200,
      name: "Att söka arbete",
    },
    {
      code: "225",
      color: light200,
      shadow: sLight200,
      name: "Arbetskultur i mottagarlandet",
    },
    {
      code: "226",
      color: light200,
      shadow: sLight200,
      name: "Löneintervall (beroende på kompetens, erfarenhet, utbildning) & vad du kan förvänta dig",
    },
    // 300
    {
      code: "300",
      color: dark300,
      shadow: sDark300,
      name: "MODULER 3:  Avveckling",
    },
    {
      code: "310",
      color: med300,
      shadow: sMed300,
      name: "Bostäder och boende",
    },
    {
      code: "311",
      color: light300,
      shadow: sLight300,
      name: "Förväntningar avseende egenskaper",
    },
    {
      code: "312",
      color: light300,
      shadow: sLight300,
      name: "Räkningar, hyra, hushållsel",
    },
    {
      code: "313",
      color: light300,
      shadow: sLight300,
      name: "Tillgång till bostäder/kommunala områden/sociala bostäder",
    },
    {
      code: "314",
      color: light300,
      shadow: sLight300,
      name: "Övervinna fördomar och förutfattade meningar",
    },
    {
      code: "320",
      color: med300,
      shadow: sMed300,
      name: "Finansiella förfaranden",
    },
    {
      code: "321",
      color: light300,
      shadow: sLight300,
      name: "Hur banker fungerar och ingen bankverksamhet (t.ex. Revolut)",
    },
    {
      code: "322",
      color: light300,
      shadow: sLight300,
      name: "Öppnande av bankkonton",
    },
    {
      code: "323",
      color: light300,
      shadow: sLight300,
      name: "Vart man kan vända sig för stöd och vägledning",
    },
    {
      code: "324",
      color: light300,
      shadow: sLight300,
      name: "Berättigande till lån",
    },
    {
      code: "325",
      color: light300,
      shadow: sLight300,
      name: "Fördelar (finansiell kompetens, administration)",
    },
    {
      code: "326",
      color: light300,
      shadow: sLight300,
      name: "Finansiell registrering i bosättningslandet",
    },
    {
      code: "330",
      color: med300,
      shadow: sMed300,
      name: "Enhet 3 Pedagogisk tillgång/steg",
    },
    {
      code: "331",
      color: light300,
      shadow: sLight300,
      name: "Validering av studier/examina/betyg etc.",
    },
    {
      code: "332",
      color: light300,
      shadow: sLight300,
      name: "Söker ytterligare information (t.ex. inskrivning, kriterier, inskrivning av barn i utbildningssystemet)",
    },
    {
      code: "333",
      color: light300,
      shadow: sLight300,
      name: "Tillgång till rådgivning (t.ex. rådgivare)",
    },
    {
      code: "340",
      color: light300,
      shadow: sLight300,
      name: "Enhet 4 Socialisering/samhällsbyggande",
    },
    {
      code: "341",
      color: light300,
      shadow: sLight300,
      name: "Identifiera samhällsevenemang/sammankomster med människor från samma/liknande kulturer",
    },
    // 400
    {
      code: "400",
      color: dark400,
      shadow: sDark400,
      name: "MODULER 4:  Hälsa",
    },
    {
      code: "410",
      color: med400,
      shadow: sMed400,
      name: "Hälso- och sjukvård",
    },
    { code: "411", color: light400, shadow: sLight400, name: "Personnummer" },
    {
      code: "412",
      color: light400,
      shadow: sLight400,
      name: "Information om covid-19 och vacciner",
    },
    {
      code: "413",
      color: light400,
      shadow: sLight400,
      name: "Tillgång till sjukhus och läkare",
    },
    {
      code: "414",
      color: light400,
      shadow: sLight400,
      name: "Information om hälso- och sjukvårdssystemet",
    },
    { code: "420", color: med400, shadow: sMed400, name: "Psykologiskt stöd" },
    {
      code: "421",
      color: light400,
      shadow: sLight400,
      name: "Få tillgång till psykologiskt stöd och inse behovet av psykologiskt stöd och rådgivning",
    },
    {
      code: "422",
      color: light400,
      shadow: sLight400,
      name: "Kostnader och information",
    },
    {
      code: "423",
      color: light400,
      shadow: sLight400,
      name: "Ta bort stigmatiseringen av behovet av besök/konsekventa besök",
    },
    {
      code: "424",
      color: light400,
      shadow: sLight400,
      name: "Tillgång till hjälp i nödsituationer",
    },
  ],

  Turkey: [
    // 100
    {
      code: "100",
      color: dark100,
      shadow: sDark100,
      name: "MODÜL 1:  İletişim ve Dil Becerileri",
    },
    {
      code: "110",
      color: med100,
      shadow: sMed100,
      name: "Yerel makamlarla iletişim",
    },
    {
      code: "111",
      color: light100,
      shadow: sLight100,
      name: "Yetkililerle etkileşim",
    },
    {
      code: "112",
      color: light100,
      shadow: sLight100,
      name: "Kültürel, durumsal ve etkileşimsel farkındalık ve beklentiler",
    },
    {
      code: "113",
      color: light100,
      shadow: sLight100,
      name: "Kurallar ve Yönetmelikler",
    },
    {
      code: "114",
      color: light100,
      shadow: sLight100,
      name: "Yapılması ve Yapılmaması Gerekenler",
    },
    {
      code: "115",
      color: light100,
      shadow: sLight100,
      name: "Kültürel yönler",
    },
    {
      code: "116",
      color: light100,
      shadow: sLight100,
      name: "Acil durumlarda yardıma erişim",
    },
    {
      code: "120",
      color: med100,
      shadow: sMed100,
      name: "Günlük dil ihtiyaçları",
    },
    {
      code: "121",
      color: light100,
      shadow: sLight100,
      name: "Günlük iletişim (yerel dilde ve İngilizce'de temel kelimeler)",
    },
    {
      code: "122",
      color: light100,
      shadow: sLight100,
      name: "Dil öğrenimi olanakları hakkında bilgi",
    },
    {
      code: "123",
      color: light100,
      shadow: sLight100,
      name: "Kültürel, durumsal ve etkileşimsel farkındalık ve beklentiler",
    },
    {
      code: "124",
      color: light100,
      shadow: sLight100,
      name: "Süpermarket, okul, doktor ve hastane ziyaretleri, işçi servisi, iş arama, barınma, ulaşım",
    },
    // 200
    {
      code: "200",
      color: dark200,
      shadow: sDark200,
      name: "MODÜL 2:  İstihdam",
    },
    {
      code: "210",
      color: med200,
      shadow: sMed200,
      name: "Sosyal Sigorta Hizmetleri",
    },
    {
      code: "211",
      color: light200,
      shadow: sLight200,
      name: "Sosyal sigorta hizmetleri hakkında daha fazla bilgi",
    },
    {
      code: "212",
      color: light200,
      shadow: sLight200,
      name: "Çeşitli risklere karşı koruma: Sağlık ve güvenlik bilinci ve istismar",
    },
    {
      code: "220",
      color: med200,
      shadow: sMed200,
      name: "İşgücü/İstihdam erişimi",
    },
    {
      code: "221",
      color: light200,
      shadow: sLight200,
      name: "Ülkenin işgücü piyasası hakkında daha fazla bilgi (örn. Nasıl çalıştıkları)",
    },
    {
      code: "222",
      color: light200,
      shadow: sLight200,
      name: "Sömürücü uygulamaların farkında olmak (örneğin cinsel taciz)",
    },
    {
      code: "223",
      color: light200,
      shadow: sLight200,
      name: "Önyargıların ve önyargıların üstesinden gelmek",
    },
    { code: "224", color: light200, shadow: sLight200, name: "İş aramak" },
    {
      code: "225",
      color: light200,
      shadow: sLight200,
      name: "Ev sahibi ülkenin çalışma kültürü",
    },
    {
      code: "226",
      color: light200,
      shadow: sLight200,
      name: "Maaş aralıkları (becerilere, deneyime, eğitime bağlı olarak) & ne beklemeli",
    },
    // 300
    {
      code: "300",
      color: dark300,
      shadow: sDark300,
      name: "MODÜL 3:  Yerleşme",
    },
    {
      code: "310",
      color: med300,
      shadow: sMed300,
      name: "Barınma ve Konaklama",
    },
    {
      code: "311",
      color: light300,
      shadow: sLight300,
      name: "Mülklere ilişkin beklentiler",
    },
    {
      code: "312",
      color: light300,
      shadow: sLight300,
      name: "Faturalar, kira, kamu hizmetleri",
    },
    {
      code: "313",
      color: light300,
      shadow: sLight300,
      name: "Konutlara/toplumsal alanlara/sosyal konutlara erişim",
    },
    {
      code: "314",
      color: light300,
      shadow: sLight300,
      name: "Önyargıların ve önyargıların üstesinden gelmek",
    },
    { code: "320", color: med300, shadow: sMed300, name: "Mali prosedürler" },
    {
      code: "321",
      color: light300,
      shadow: sLight300,
      name: "Bankalar nasıl çalışır ve online bankacılık (örneğin revolut)",
    },
    {
      code: "322",
      color: light300,
      shadow: sLight300,
      name: "Banka hesabı açma",
    },
    {
      code: "323",
      color: light300,
      shadow: sLight300,
      name: "Destek ve rehberlik için nereye başvurulmalı",
    },
    {
      code: "324",
      color: light300,
      shadow: sLight300,
      name: "Kredi uygunluğu",
    },
    {
      code: "325",
      color: light300,
      shadow: sLight300,
      name: "Faydalar (finansal okuryazarlık, yönetim)",
    },
    {
      code: "326",
      color: light300,
      shadow: sLight300,
      name: "İkamet edilen ülkede mali kayıt",
    },
    {
      code: "330",
      color: med300,
      shadow: sMed300,
      name: "Eğitim erişimi/adımları",
    },
    {
      code: "331",
      color: light300,
      shadow: sLight300,
      name: "Çalışmaların/diplomaların/derecelerin vb. onaylanması",
    },
    {
      code: "332",
      color: light300,
      shadow: sLight300,
      name: "Daha fazla bilgi aramak (örneğin, kayıt, kriterler, çocukların eğitim sistemine kaydı)",
    },
    {
      code: "333",
      color: light300,
      shadow: sLight300,
      name: "Tavsiyeye erişim (örn. Danışmanlar)",
    },
    {
      code: "340",
      color: light300,
      shadow: sLight300,
      name: "Sosyalleşme/topluluk oluşturma",
    },
    {
      code: "341",
      color: light300,
      shadow: sLight300,
      name: "Aynı/benzer kültürlerden insanlarla topluluk etkinliklerini/toplantılarını belirleme",
    },
    // 400
    {
      code: "400",
      color: dark400,
      shadow: sDark400,
      name: "MODÜL 4:  Sağlık",
    },
    { code: "410", color: med400, shadow: sMed400, name: "Sağlık Hizmetleri " },
    {
      code: "411",
      color: light400,
      shadow: sLight400,
      name: "Sosyal güvenlik numarası",
    },
    {
      code: "412",
      color: light400,
      shadow: sLight400,
      name: "Covid-19 ve aşı bilgileri",
    },
    {
      code: "413",
      color: light400,
      shadow: sLight400,
      name: "Hastanelere ve doktorlara erişim",
    },
    {
      code: "414",
      color: light400,
      shadow: sLight400,
      name: "Sağlık sistemine ilişkin bilgiler",
    },
    { code: "420", color: med400, shadow: sMed400, name: "Psikolojik Destek " },
    {
      code: "421",
      color: light400,
      shadow: sLight400,
      name: "Psikolojik desteğe erişim ve psikolojik destek ve danışmanlık ihtiyacının farkına varma",
    },
    {
      code: "422",
      color: light400,
      shadow: sLight400,
      name: "Maliyetler ve bilgiler",
    },
    {
      code: "423",
      color: light400,
      shadow: sLight400,
      name: "Ziyaret ihtiyacı/sürekli ziyaret damgasının kaldırılması",
    },
    {
      code: "424",
      color: light400,
      shadow: sLight400,
      name: "Acil durumlarda yardıma erişim",
    },
  ],
};
