import { GoChevronRight, GoChevronDown } from "react-icons/go";
import { useState } from "react";

function Accordion({ items, isLoading }) {
  const handleClick = (newIndex) => {
    setExpandedIndex((current) => {
      if (current === newIndex) return -1;
      else return newIndex;
    });
  };
  // console.log(items);

  const [expandedIndex, setExpandedIndex] = useState(-1);

  // go through all the items
  const renderedItems = items.map((item, index) => {
    // check if the index is the the expandedIndex
    const isExpanded = index === expandedIndex;

    const icon = (
      <span className="text-3xl">
        {isExpanded ? (
          <GoChevronDown color="white" />
        ) : (
          <GoChevronRight color="white" />
        )}
      </span>
    );

    const contentClassName = isExpanded
      ? "max-h-[3000px] transition-[max-height] overflow-hidden duration-[1000ms] ease-linear"
      : "max-h-0 overflow-hidden duration-[500ms] ease-in-out";

    return (
      <div key={item.id}>
        <div
          className="flex p-3 justify-between bg-gray-900 border-b items-center cursor-pointer"
          onClick={() => handleClick(index)}
        >
          <div className="max-[400px]:text-sm  text-gray-200">{item.label}</div>
          {icon}
        </div>
        <div
          className={` bg-gray-100 shadow-sm shadow-black ${contentClassName}`}
        >
          {item.content}
        </div>
      </div>
    );
  });

  return <div className=" border-t mb-3">{renderedItems}</div>;
}

export default Accordion;
