import React from "react";
import "../../css/wave.css"; // Assuming you have your CSS in this file

const WaveComponent = () => {
  return (
    <div>
      <svg
        className="waves"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 27 150 28" // Adjusted viewbox to accommodate boat
        preserveAspectRatio="none"
        shapeRendering="auto"
      >
        <defs>
          <path
            id="gentle-wave"
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
          />
          {/* Boat Path */}
          <path
            id="simple-boat"
            d="M 0 45 H 9 L 11 44 H 0 C -0.3333 44.3333 -1 44 -1 45 z L 3 44 L 3 40" // Smaller boat shape
          />
          {/* Plane Path */}
          <path
            id="simple-plane"
            d="M 0.033 31.5448 L -0.398 30.3899 C -0.44 30.2683 -0.3669 30.1415 -0.2373 30.1263 L 0.2174 30.0669 L 0.972 30.6823 L 2.6396 30.5828 L 1.538 29.0558 C 1.4569 28.94 1.5076 28.7966 1.6314 28.7423 L 2.1057 28.5721 L 4.6034 30.4594 L 6.0016 30.376 L 7.1378 30.6271 C 7.4962 30.7538 7.5904 31.2273 7.3044 31.4815 C 6.886 31.8393 6.3661 32.0522 5.823 32.1088 L 1.9766 32.4714 L 0.2343 31.7707 L 0.033 31.5448"
          />
          {/* Plane Path */}
          <path
            id="simple-bird"
            d="M 10.9657 49.3625 c 1.2521 -0.4956 3.297 -2.0649 4.666 -0.7578 c 1.4291 -1.5828 2.5241 -0.3799 3.3942 -0.0818 c -2.071 -0.2317 -2.3197 -0.2925 -3.3495 1.2072 c -1.7271 -1.5068 -3.1445 -0.2403 -4.7107 -0.3676 z"
          />
        </defs>
        <g className="parallax">
          <use
            xlinkHref="#gentle-wave"
            x="48"
            y="15"
            fill="rgba(255, 255, 255, 0.5)"
          />
          <use
            xlinkHref="#gentle-wave"
            x="48"
            y="8"
            fill="rgba(255, 255, 255, 0.3)"
          />
          <use
            xlinkHref="#gentle-wave"
            x="48"
            y="9"
            fill="rgba(255, 255, 255, 0.2)"
          />
          <use xlinkHref="#gentle-wave" x="48" y="10" fill="#ffffffaa" />
          {/* Using the boat */}
          <use xlinkHref="#simple-boat" x="65" y="8" fill="#000000" />

          {/* Using the plane */}
          <use xlinkHref="#simple-plane" x="65" y="4" fill="#000000" />
          {/* Birds */}
          <use xlinkHref="#simple-bird" x="48" y="-5" fill="#000000" />
          <use xlinkHref="#simple-bird" x="48" y="-5" fill="#000000" />
          <use xlinkHref="#simple-bird" x="48" y="-6" fill="#000000" />
          <use xlinkHref="#simple-bird" x="48" y="-4" fill="#000000" />
        </g>
      </svg>
    </div>
  );
};

export default WaveComponent;
