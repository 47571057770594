import React, { useState } from "react";
import { GoLink } from "react-icons/go";

const ExpectationsComponent = ({ data, code }) => {
  // console.log(data);
  const [clickedLink, setClickedLink] = useState(null);
  const handleClick = (index) => {
    setClickedLink(index);
  };

  const linkStyle = {
    transition: "background-color 0.3s ease, opacity 0.3s ease", // Add transitions
  };

  return (
    <div>
      {data.map((linkItem, index) => {
        if (linkItem[0] === "span") {
          const spanText = linkItem[1].split("\n"); // Split text by "\n"
          return (
            <p key={`${code}_${index}`} className="mt-4 mb-1">
              {spanText.map((line, lineIndex) => (
                <React.Fragment key={lineIndex}>
                  {line}
                  {lineIndex !== spanText.length - 1 && <br />}{" "}
                  {/* Add line breaks */}
                </React.Fragment>
              ))}
            </p>
          );
        } else if (linkItem[0] === "link") {
          const isClicked = clickedLink === index;
          const opacity = isClicked ? 0.8 : 1; // Reduce opacity when clicked

          const linkItemStyle = {
            ...linkStyle,
            opacity,
          };

          // Check if linkItem[1] starts with "info"
          if (linkItem[1].startsWith("http")) {
            // Render a regular anchor link
            return (
              <div
                className="ml-2 mb-1 text-blue-700 font-medium items-center"
                key={index}
              >
                <a
                  href={linkItem[1]}
                  onClick={() => handleClick(index)}
                  style={linkItemStyle}
                  className="flex flex-row items-center w-fit"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="mr-2">
                    <GoLink size={10} />
                  </div>
                  {linkItem[2]}
                </a>
              </div>
            );
          } else {
            // Render an email element
            return (
              <div
                className="ml-2 mb-1 text-blue-700 font-medium items-center"
                key={index}
              >
                <a
                  href={`mailto:${linkItem[1]}`} // Use "mailto" to create an email link
                  style={linkItemStyle}
                  className="flex flex-row items-center w-fit"
                >
                  <div className="mr-2">
                    <GoLink size={10} />
                  </div>
                  {linkItem[2]}
                </a>
              </div>
            );
          }
        } else if (linkItem[0] === "list") {
          return (
            <ul key={index} className=" list-disc ml-5 mb-1">
              {linkItem[1].map((listItem, i) => (
                <li key={i} className="mx-2 text-justify">
                  {listItem}
                </li>
              ))}
            </ul>
          );
        }
        return null; // Handle other cases if needed
      })}
    </div>
  );
};

export default ExpectationsComponent;
