import { createSlice } from "@reduxjs/toolkit";

const saveToLocalStorage = (state) => {
  localStorage.setItem("categoryState", JSON.stringify(state));
};

export const categoriesSlice = createSlice({
  name: "categories",
  initialState: {
    saved: [],
    discarded: [],
    isShowing: false,
  },
  reducers: {
    setCategories: (state, action) => {
      state.saved = action.payload;
      saveToLocalStorage(state);
    },
    setDiscarded: (state, action) => {
      state.discarded = action.payload;
      saveToLocalStorage(state);
    },
    setIsShowing: (state, action) => {
      state.isShowing = action.payload;
    },
  },
});

export const { setCategories, setDiscarded, setIsShowing } = categoriesSlice.actions;

export const categoriesReducer = categoriesSlice.reducer;
