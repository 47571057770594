import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import ActorLanguageToggle from "./ActorLanguageToggle";

import { hasCountryActorSelector } from "../../store";
import { useSelector } from "react-redux";
import BottomActorNavigation from "./BottomActorNavigation";
// import LanguageToggle from "./LanguageToggle";

function ProtectedActorRoute({ redirectPath = "/actor" }) {
  const hasCountryActor = useSelector(hasCountryActorSelector);

  // if (hasCountryActor) {
  //   console.log("is Authenticated: " + hasCountryActor); // TODO REMOVE THE LOG ---
  // } else {
  //   console.log("Redirecting...."); // TODO REMOVE THE LOG ---
  // }

  return (
    <div className="relative">
      <ActorLanguageToggle />
      <div className="mt-12 mb-10">
        {/* Create space for LanguageToggle */}
        {hasCountryActor ? <Outlet /> : <Navigate to={redirectPath} />}
      </div>
      {hasCountryActor && <BottomActorNavigation />}
    </div>
  );
}

export default ProtectedActorRoute;
