import React, { useState, useEffect } from "react";
import useWindowDimensions from "../hooks/useWindowDimensions";

function VideoPlayer({ source, setPartFinished }) {
  // track when video ended
  const [videoEnded, setVideoEnded] = useState(false);
  const { width, height } = useWindowDimensions();
  //console.log(width);

  //   useEffect(() => {
  //     const videoElement = document.querySelector("#videoPlayer");

  //     if (videoElement) {
  //       window.addEventListener("fullscreenchange", handleOrientationChange);
  //     }

  //     return () => {
  //       if (videoElement) {
  //         window.removeEventListener("fullscreenchange", handleOrientationChange);
  //       }
  //     };
  //   }, []);

  function handleVideoEnd() {
    setVideoEnded(true);
    // setPartFinished(true);
  }

  //   function handleOrientationChange() {
  //     const videoElement = document.querySelector("#videoPlayer");
  //     if (videoElement) {
  //       const orientation = window.orientation;
  //       console.log(orientation);
  //       // videoElement.setAttribute('orientation', orientation);
  //     }
  //   }

  return (
    <>
      {/* {videoEnded && (
        <p className="flex justify-center">The video has ended!</p>
      )} */}
      <video
        onEnded={handleVideoEnd}
        controls
        autoPlay
        className="mx-auto mb-2"
        style={{ width: width * 0.7, height: height * 0.7 }}
      >
        <source src={source} type="video/mp4" />
      </video>
    </>
  );
}

export default VideoPlayer;
