import React, { useEffect, useState } from "react";
import Category from "../components/app/Category";

import { useSelector } from "react-redux";
import {
  englishCat,
  cyprusCat,
  lithuaniaCat,
  italyCat,
  spainCat,
  swedishCat,
  turkishCat,
} from "../components/context/categoryLanguages";

import { motion, AnimatePresence } from "framer-motion";
import Modal from "../components/ui/Modal";

const langMapping = (language) => {
  switch (language) {
    case "English":
      return englishCat;
    case "Cyprus":
      return cyprusCat;
    case "Spain":
      return spainCat;
    case "Greece":
      return cyprusCat;
    case "Italy":
      return italyCat;
    case "Lithuania":
      return lithuaniaCat;
    case "Sweden":
      return swedishCat;
    case "Turkey":
      return turkishCat;
    default:
      break;
  }
};

const MainPage = () => {
  const [categories, setCategories] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [text, setText] = useState("");
  //const [loading, setLoading] = useState(false); // New state to manage loading
  const { country, immigrantStatus, language } = useSelector((state) => state.user);
  const { saved, discarded, isShowing } = useSelector((state) => state.categories);
  // const [dataSet, setDataSet] = useState(langMapping(language));

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    // Clean up by removing the class when the component unmounts
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [isModalOpen]);

  useEffect(() => {
    // Extract just the codes from the saved array
    const savedCodes = saved.map((item) => item.code);

    // Filter the modules, units, and subunits
    const filteredModules = langMapping(language)
      .map((module) => {
        const filteredUnits = module.units
          .map((unit) => {
            const filteredSubunits = unit.subunits.filter((subunit) => savedCodes.includes(subunit.code));
            return { ...unit, subunits: filteredSubunits };
          })
          .filter((unit) => unit.subunits.length > 0); // Keep units that have filtered subunits
        return { ...module, units: filteredUnits };
      })
      .filter((module) => module.units.length > 0); // Keep modules that have filtered units

    setCategories(filteredModules);
  }, [saved, language]);

  // if (loading) {
  //   return <FetchingDataAnimation />; // Show FetchingData component while loading
  // }

  return (
    <>
      <div className="flex flex-col items-center overflow-hidden bg-gray-900 pt-4 min-h-[90vh]">
        {/* <LanguageToggle /> */}
        <h1 className="text-3xl font-bold mb-4 text-gray-200">VRin</h1>
        <div
        // style={{
        //   backgroundImage: `url(${process.env.PUBLIC_URL + "/iconLogo.png"} `,
        // }}
        // className="bg-white rounded-full p-3 flex flex-col justify-center m-2 shadow-md shadow-black"
        >
          {/* <img
            src="/logoSolo.png"
            alt="Vrin Logo"
            className="max-w-[150px]"
          ></img> */}
        </div>

        <div className="w-full max-w-4xl ">
          {categories.map((category, index) => (
            <div key={`${category.code}_${index}`}>
              <Category
                title={category.name}
                animation={category.animation}
                bgColor={category.bgColor}
                units={category.units}
                description={category.description}
                openModal={openModal}
                closeModal={closeModal}
                isModalOpen={isModalOpen}
                setText={setText}
              />
            </div>
          ))}
        </div>
        <AnimatePresence
          // Disable any initial animations on children that
          // are present when the component is first rendered
          initial={false}
          // Only render one component at a time.
          // The exiting component will finish its exit
          // animation before entering component is rendered
          mode="wait"
          // Fires when all exiting nodes have completed animating out
          onExitComplete={() => null}
        >
          {isModalOpen && <Modal modalOpen={openModal} handleClose={closeModal} text={text} />}
        </AnimatePresence>
      </div>
    </>
  );
};

export default MainPage;
