import React from "react";
import Button from "../ui/Button";

const MQComponent = ({
  question,
  handleAnswer,
  selectedAnswer,
  isAnimatingProgress,
}) => {
  if (!question || question.type !== "MQ") return null;

  return (
    <>
      {JSON.parse(question.options).map((option, index) => (
        <Button
          key={index}
          outline
          primary={selectedAnswer !== option}
          danger={selectedAnswer === option && option !== question.correct}
          success={selectedAnswer === option && option === question.correct}
          rounded
          animated
          disabled={isAnimatingProgress}
          onClick={() => handleAnswer(option)}
          className="noTapHighlight items-center"
        >
          <div className="mr-2 flex text-center flex-1 justify-center">
            {option}
          </div>
        </Button>
      ))}
    </>
  );
};

export default MQComponent;
