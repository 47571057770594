import React from "react";
import Button from "../components/ui/Button";
import { useSelector, useDispatch } from "react-redux";
import { setActorCountry } from "../store";
import Select from "react-select"; // Import react-select
import { useNavigate } from "react-router-dom";

function ActorLoginPage() {
  const dispatch = useDispatch();
  const { country } = useSelector((state) => state.actor);
  console.log(country);
  const navigate = useNavigate();
  const countries = ["Cyprus", "Spain", "Greece", "Italy", "Lithuania", "Sweden", "Turkey"];

  const handleCountryChange = (selectedOption) => {
    console.log("selected option: ", selectedOption.value);
    if (!selectedOption) selectedOption = { value: "" };
    dispatch(setActorCountry(selectedOption.value));
  };

  const handleMoveToMainPage = () => {
    if (country) {
      navigate("/actor/main");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-300">
      <img src="/Vrin.png" alt="Vrin Logo" className="w-fit mb-10 p-5 -mt-32" loading="lazy"></img>

      <div className="w-full max-w-xs">
        <label className="block text-gray-700 max-[400px]:text-sm text-base font-bold mb-2">
          Please select country:
        </label>
        <Select
          value={{ value: country, label: country }} // Set value and label for country
          isClearable={true}
          isSearchable={false}
          onChange={handleCountryChange}
          options={countries.map((country) => ({
            value: country,
            label: country,
          }))}
        />
      </div>
      {country && (
        <div className="mt-4">
          <Button onClick={handleMoveToMainPage} className="noTapHighlight" primary rounded rippleEffect>
            ENTER
          </Button>
        </div>
      )}
    </div>
  );
}

export default ActorLoginPage;
