// DroppableSavedColumn.js
import React from "react";
import { Droppable } from "react-beautiful-dnd";
import DraggableItem from "../ui/DraggableItem";
import { useDispatch } from "react-redux";

const DroppableSavedColumn = ({ title, categories, isDrawerOpen, id, showingFunction }) => {
  const dispatch = useDispatch();

  const handleSwitching = () => {
    if (isDrawerOpen) {
      dispatch(showingFunction(false));
    }
  };

  return (
    <Droppable droppableId={id}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className={` p-3 border duration-500 ease-in-out min-h-screen
        ${snapshot.isDraggingOver && isDrawerOpen ? "bg-gray-500" : "bg-white"}  
        ${isDrawerOpen ? "w-2/12" : "w-10/12"}
        `}
          onClick={() => handleSwitching()}
        >
          {!isDrawerOpen && <h2 className="font-bold mb-4 text-center">{title}</h2>}
          {categories.map((category, index) => (
            <DraggableItem
              key={category.code}
              category={category}
              index={index}
              isDrawerOpen={!isDrawerOpen}
              isDragDisabled={isDrawerOpen}
            />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default DroppableSavedColumn;
