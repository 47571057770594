import React from "react";
import { motion } from "framer-motion";
import Backdrop from "./Backdrop";
import "../../css/modal.css";
import Button from "./Button";
import { getCloseLabelByLanguage } from "../context/labels";
import { useSelector } from "react-redux";

const modalVariants = {
  hidden: {
    y: "-100vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.2,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: "100vh",
    opacity: 0,
  },
};

const Modal = ({ handleClose, text }) => {
  const { language } = useSelector((state) => state.user);
  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className="modal orange-gradient z-50"
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className="max-[400px]:text-xs p-0.5 mb-2 max-[500px]:text-sm text-justify">
          {text}
        </div>
        <Button onClick={handleClose} danger rounded>
          {getCloseLabelByLanguage(language)}
        </Button>
      </motion.div>
    </Backdrop>
  );
};

export default Modal;
