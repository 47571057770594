import React from "react";
import ShortDescription from "../ui/ShortDescription";
import DescriptionComponent from "../ui/DescriptionComponent";
import LinksComponent from "../ui/LinksComponent";
import ExpectationsComponent from "../ui/ExpectationsComponent";

const AccordionSection = ({ component, data, labelFunction, language, bg }) => {
  const ComponentToRender = componentsMap[component];
  return (
    <div>
      <div className="mt-1 p-1 m-auto">
        <div
          className={`text-center font-medium border-b border-gray-950 rounded-md w-7/12 m-auto`}
        >
          {labelFunction(language)}
        </div>
      </div>
      <div className="max-[400px]:text-xs max-[500px]:text-sm p-4">
        <ComponentToRender data={data} />
      </div>
    </div>
  );
};
export default AccordionSection;

// Map your component strings to actual components
const componentsMap = {
  ShortDescription: ShortDescription,
  LinksComponent: LinksComponent,
  DescriptionComponent: DescriptionComponent,
  ExpectationsComponent: ExpectationsComponent,
};
