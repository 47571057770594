import React from "react";

function DescriptionComponent({ descriptions }) {
  // console.log(descriptions[0][0]);
  return (
    <div>
      {descriptions[0] === "null" ? (
        descriptions[1].map((item, i) => {
          // Split the item into lines based on '\n'
          const lines = item.split("\n");
          return (
            <p
              key={i}
              className="mt-2 p-1 max-[400px]:text-xs max-[500px]:text-sm text-justify"
            >
              {lines.map((line, lineIndex) => (
                <React.Fragment key={lineIndex}>
                  {line}
                  {lineIndex !== lines.length - 1 && <br />}
                </React.Fragment>
              ))}
            </p>
          );
        })
      ) : (
        <>
          {descriptions[1].slice(0, descriptions[0]).map((item, i) => {
            const lines = item.split("\n");
            return (
              <p
                key={i}
                className="mt-2 p-1 max-[400px]:text-xs max-[500px]:text-sm text-justify"
              >
                {lines.map((line, lineIndex) => (
                  <React.Fragment key={lineIndex}>
                    {line}
                    {lineIndex !== lines.length - 1 && <br />}
                  </React.Fragment>
                ))}
              </p>
            );
          })}
          <ul className=" list-disc ml-6">
            {descriptions[1].slice(descriptions[0]).map((item, i) => (
              <li key={i} className=" mb-2 mr-1 text-justify">
                {item}
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
}

export default DescriptionComponent;
