// import "./App.css";
import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
// import { AuthProvider } from "./components/context/AuthContext";
import ProtectedRoute from "./components/app/ProtectedRoute";
import ProtectedActorRoute from "./components/app/ProtectedActorRoute";
//Pages for TCN:
import LoginPage from "./views/LoginPage";
import MainPage from "./views/MainPage";
import SelectionPage from "./views/SelectionPage";
import QuizPage from "./views/QuizPage";
import NotFoundPage from "./views/NotFoundPage";

//Pages for Actors:
import ActorLoginPage from "./views/ActorLoginPage";
import ActorMainPage from "./views/ActorMainPage";
//import ActorSelectionPage from "./views/ActorSelectionPage";
import ActorDetails from "./views/ActorDetails";
//import CircleRadioButtons from "./components/ui/CircleRadioButtons";
//import ParentComponent from "./components/app/ParentComponent";
// import BottomNavigation from "../src/components/app/BottomNavigation";
import Subunit from "./views/Subunit";
import QuestionsPage from "./views/QuestionsPage";
import { LayoutProvider } from "./components/context/LayoutContext";
import ReactGA from "react-ga4";

function useAnalytics() {
  const location = useLocation();

  useEffect(() => {
    // Initialize GA4
    ReactGA.initialize([{ trackingId: "G-W1XLWM1PY9" }]);
  }, []);

  useEffect(() => {
    const currentPage = location.pathname + location.search;
    //console.log("Page view", currentPage);
    ReactGA.send({ hitType: "pageview", page: currentPage });
    //ReactGA.send("pageview");
  }, [location]);
}

function App() {
  return (
    <LayoutProvider>
      <BrowserRouter basename={"/"}>
        <Analytics />
        <Routes>
          <Route element={<ProtectedRoute />}>
            <Route path="/select" element={<SelectionPage />}></Route>
            <Route path="/main" element={<MainPage />}></Route>
            <Route path="/quiz" element={<QuizPage />}></Route>
            <Route path="/questions" element={<QuestionsPage />}></Route>
            <Route path="/details/:id" element={<Subunit />}></Route>
          </Route>
          <Route element={<ProtectedActorRoute />}>
            {/* <Route path="/actor/select" element={<ActorSelectionPage />}></Route> */}
            <Route path="/actor/main" element={<ActorMainPage />}></Route>
            <Route path="/actor/details/:code" element={<ActorDetails />}></Route>
          </Route>
          <Route path="/actor" element={<ActorLoginPage />} />
          <Route path="/" element={<LoginPage />}></Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </LayoutProvider>
  );
}

function Analytics() {
  useAnalytics();
  return null;
}

export default App;
