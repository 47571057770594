import React from "react";

const FetchingDataAnimation2 = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen/2  overflow-hidden">
      <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-blue-500"></div>
      <p className="mt-4 text-xl font-semibold">Fetching Data...</p>
    </div>
  );
};

export default FetchingDataAnimation2;
