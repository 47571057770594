import React from "react";

// ---------------------------- SHORT DESCRIPTION ------------------------//

export const getShortDLabelByLanguage = (language) => {
  switch (language) {
    case "English":
      return "Useful Information";
    case "Cyprus":
      return "Χρήσιμες πληροφορίες";
    case "Spain":
      return "Información de utilidad";
    case "Greece":
      return "Χρήσιμες πληροφορίες";
    case "Italy":
      return "Informazioni utili";
    case "Lithuania":
      return "Naudinga informacija";
    case "Sweden":
      return "Användbar information";
    case "Turkey":
      return "Faydalı Bilgiler";
    default:
      return "Unknown Language";
  }
};

// ----------------------------   PROCEDURES   ------------------------//
export function getProceduresLabelByLanguage(language) {
  switch (language) {
    case "English":
      return "Procedures";
    case "Cyprus":
      return "Διαδικασίες";
    case "Spain":
      return "Procesos";
    case "Greece":
      return "Διαδικασίες";
    case "Italy":
      return "Procedure";
    case "Lithuania":
      return "Procesas";
    case "Sweden":
      return "Förfaranden";
    case "Turkey":
      return "Prosedürler";
    default:
      return "Unknown Language";
  }
}
// ----------------------------  DETAILS   ---------------------------//
export function getInfoLabelByLanguage(language) {
  switch (language) {
    case "English":
      return "Info";
    case "Cyprus":
      return "Πληροφορίες";
    case "Spain":
      return "Info";
    case "Greece":
      return "Πληροφορίες";
    case "Italy":
      return "Info";
    case "Lithuania":
      return "Informacija";
    case "Sweden":
      return "info";
    case "Turkey":
      return "Bilgi";
    default:
      return "Unknown Language";
  }
}
// ----------------------------    LINKS   ------------------------//
export function getLinksLabelByLanguage(language) {
  switch (language) {
    case "English":
      return "Related Links";
    case "Cyprus":
      return "Σχετικοί σύνδεσμοι";
    case "Spain":
      return "Enlaces relacionados";
    case "Greece":
      return "Σχετικοί σύνδεσμοι";
    case "Italy":
      return "Link Correlati";
    case "Lithuania":
      return "Susijusios nuorodos";
    case "Sweden":
      return "Relaterade länkar";
    case "Turkey":
      return "İlgili Bağlantılar";
    default:
      return "Unknown Language";
  }
}
// ---------------------------- WOMEN ------------------------//
export function getWomenLabelByLanguage(language) {
  switch (language) {
    case "English":
      return "Specificities to women";
    case "Cyprus":
      return "Ιδιαιτερότητες για τις γυναίκες";
    case "Spain":
      return "Particularidades para las mujeres";
    case "Greece":
      return "Ιδιαιτερότητες για τις γυναίκες ";
    case "Italy":
      return "Specificità per le donne";
    case "Lithuania":
      return "Informacija specifiškai moterims";
    case "Sweden":
      return "Särskildheter för kvinnor";
    case "Turkey":
      return "Specificities to women";
    default:
      return "Unknown Language";
  }
}
// ---------------------------- Expectations ------------------------//
export function getExpectationsLabelByLanguage(language) {
  switch (language) {
    case "English":
      return "What to except / reality";
    case "Cyprus":
      return "Τι να περιμένετε/η πραγματικότητα";
    case "Spain":
      return "Qué esperar / realidad";
    case "Greece":
      return "Τι να περιμένετε/η πραγματικότητα";
    case "Italy":
      return "Cosa aspettarsi (realtà)";
    case "Lithuania":
      return "Ko tikėtis/ realybė";
    case "Sweden":
      return "Vad man ska undanta/verklighet";
    case "Turkey":
      return "Ne beklenebilir?";
    default:
      return "Unknown Language";
  }
}

// ----------------------------    DESCRIPTION    ------------------------//
export function getDescriptionLabelByLanguage(language) {
  switch (language) {
    case "English":
      return "Description";
    case "Cyprus":
      return "Περιγραφή";
    case "Spain":
      return "Descripción";
    case "Greece":
      return "Περιγραφή";
    case "Italy":
      return "Descrizione";
    case "Lithuania":
      return "Aprašymas";
    case "Sweden":
      return "Beskrivning";
    case "Turkey":
      return "Açıklama";
    default:
      return "Unknown Language";
  }
}

//----------------------------       Links     ------------------------//
export function getMenuLabelByLanguage(language) {
  switch (language) {
    case "English":
      return "Menu";
    case "Cyprus":
      return "Μενού";
    case "Spain":
      return "Menú";
    case "Greece":
      return "Μενού";
    case "Italy":
      return "Menù";
    case "Lithuania":
      return "Meniu";
    case "Sweden":
      return "Meny";
    case "Turkey":
      return "Menü";
    default:
      return "Unknown Language";
  }
}

export function getCustomisePageLabelByLanguage(language) {
  switch (language) {
    case "English":
      return "Customise";
    case "Cyprus":
      return "Εξατομίκευση";
    case "Spain":
      return "Personalizar";
    case "Greece":
      return "Εξατομίκευση";
    case "Italy":
      return "Personalizza";
    case "Lithuania":
      return "Personalizuoti";
    case "Sweden":
      return "Anpassa";
    case "Turkey":
      return "Kişiselleştirmek";
    default:
      return "Unknown Language";
  }
}

export function getInfoHubPageLabelByLanguage(language) {
  switch (language) {
    case "English":
      return "Information Hub";
    case "Cyprus":
      return "Kέντρο πληροφοριών";
    case "Spain":
      return "Lista de contenidos";
    case "Greece":
      return "Kέντρο πληροφοριών ";
    case "Italy":
      return "Archivio contenuti";
    case "Lithuania":
      return "Informacijos centras";
    case "Sweden":
      return "Informationshub";
    case "Turkey":
      return "Bilgi Merkezi";
    default:
      return "Unknown Language";
  }
}

export function getQuizPageLabelByLanguage(language) {
  switch (language) {
    case "English":
      return "Quiz";
    case "Cyprus":
      return "Κουίζ";
    case "Spain":
      return "Cuestionario";
    case "Greece":
      return "Κουίζ";
    case "Italy":
      return "Quiz";
    case "Lithuania":
      return "Žaidimas";
    case "Sweden":
      return "Frågesport";
    case "Turkey":
      return "Sınav";
    default:
      return "Unknown Language";
  }
}

export function getLoginPageLabelByLanguage(language) {
  switch (language) {
    case "English":
      return "Country l Status";
    case "Cyprus":
      return "Χώρα l Καθεστώς";
    case "Spain":
      return "País l Categoría";
    case "Greece":
      return "Χώρα l Καθεστώς";
    case "Italy":
      return "Paese l Status";
    case "Lithuania":
      return "Valstybė l Statusas";
    case "Sweden":
      return "Land l Status";
    case "Turkey":
      return "Ülke l Durumu";
    default:
      return "Unknown Language";
  }
}

export function getCloseLabelByLanguage(language) {
  switch (language) {
    case "English":
      return "Close";
    case "Cyprus":
      return "Κλείσε";
    case "Spain":
      return "Cerrar";
    case "Greece":
      return "Κλείσε";
    case "Italy":
      return "Chiudi";
    case "Lithuania":
      return "Uždaryti";
    case "Sweden":
      return "avbryta";
    case "Turkey":
      return "Kapat";
    default:
      return "Unknown Language";
  }
}

export function getToggleLabelByLanguage(language) {
  switch (language) {
    case "English":
      return "Drawer";
    case "Cyprus":
      return "Αποθήκη";
    case "Spain":
      return "Cajón";
    case "Greece":
      return "Αποθήκη";
    case "Italy":
      return "Cassetto";
    case "Lithuania":
      return "Saugykla";
    case "Sweden":
      return "Lådan";
    case "Turkey":
      return "Çekmece";
    default:
      return "Unknown Language";
  }
}

//-------------------------------   Customize Screen ---------------------------------//
export function getVisibleLabelByLanguage(language) {
  switch (language) {
    case "English":
      return "Visible";
    case "Cyprus":
      return "Ορατό";
    case "Spain":
      return "Visibles";
    case "Greece":
      return "Ορατό";
    case "Italy":
      return "Visibili";
    case "Lithuania":
      return "Naudojamos";
    case "Sweden":
      return "Aktivsida";
    case "Turkey":
      return "Görünür";
    default:
      return "Unknown Language";
  }
}

export function getDiscardedLabelByLanguage(language) {
  switch (language) {
    case "English":
      return "Discarded";
    case "Cyprus":
      return "Aχρείαστο";
    case "Spain":
      return "Descartados";
    case "Greece":
      return "Aχρείαστο";
    case "Italy":
      return "Scartate";
    case "Lithuania":
      return "Nenaudojamos";
    case "Sweden":
      return "Tabort";
    case "Turkey":
      return "Gizlenmiş";
    default:
      return "Unknown Language";
  }
}

export function getWelcomeMsgCustomizeByLanguage(language) {
  switch (language) {
    case "English":
      return "Welcome, here you can pick and choose which information interests you and hide any that doesn't.";
    case "Cyprus":
      return "Καλώς ήρθες, εδώ μπορείς να επιλέξεις ποιες πληροφορίες σε ενδιαφέρουν και να αποκλείσεις όσες δεν σε ενδιαφέρουν.";
    case "Spain":
      return "Bienvenido/a, aquí puedes elegir la información que te interesa y ocultar la que no.";
    case "Greece":
      return "Καλώς ήρθες, εδώ μπορείς να επιλέξεις ποιες πληροφορίες σε ενδιαφέρουν και να αποκλείσεις όσες δεν σε ενδιαφέρουν.";
    case "Italy":
      return "Benvenuto/a, qui puoi selezionare e scegliere quali informazioni ti interessano e nascondere quelle che non ti interessano.";
    case "Lithuania":
      return "Sveiki atvykę, čia galite pasirinkti, kuri informacija jus domina ir paslėpti tą, kuri nedomina.";
    case "Sweden":
      return "Välkommen, här kan du välja vilken information som intresserar dig och dölja den som inte gör det.";
    case "Turkey":
      return "Hoş geldiniz, burada ilginizi çeken bilgileri seçebilir ve ilginizi çekmeyenleri gizleyebilirsiniz.";
    default:
      return "Unknown Language";
  }
}

export function getShowTutorialBTNByLanguage(language) {
  switch (language) {
    case "English":
      return "Show me how it works";
    case "Cyprus":
      return "Δείξε μου πώς λειτουργεί";
    case "Spain":
      return "Muéstrame cómo funciona";
    case "Greece":
      return "Δείξε μου πώς λειτουργεί";
    case "Italy":
      return "Mostrami come funziona";
    case "Lithuania":
      return "Parodyk man, kaip tai veikia";
    case "Sweden":
      return "Visa mig hur det fungerar";
    case "Turkey":
      return "Nasıl çalıştığını bana göster";
    default:
      return "Unknown Language";
  }
}

export function getLinkCopiedTextByLanguage(language) {
  switch (language) {
    case "English":
      return (
        <>
          link
          <br />
          Copied!
        </>
      );
    case "Cyprus":
      return (
        <>
          σύνδεσμος
          <br />
          Αντιγράφηκε!
        </>
      );
    case "Spain":
      return (
        <>
          enlace
          <br />
          ¡Copiado!
        </>
      );
    case "Greece":
      return (
        <>
          σύνδεσμος
          <br />
          Αντιγράφηκε!
        </>
      );
    case "Italy":
      return (
        <>
          collegamento
          <br />
          Copiato!
        </>
      );
    case "Lithuania":
      return (
        <>
          link
          <br />
          Copied!
        </>
      );
    case "Sweden":
      return (
        <>
          länk
          <br />
          Kopierade!
        </>
      );
    case "Turkey":
      return (
        <>
          bağlantı
          <br />
          Kopyalandı!
        </>
      );
    default:
      return "Unknown Language";
  }
}

// -------------------------  Expandable  -------------------------//
export function getExpandableLabelByLanguage(language) {
  switch (language) {
    case "English":
      return "Tap on any of the items to expand";
    case "Cyprus":
      return "Πάτησε σε οποιοδήποτε σημείο για να τα επεκτείνεις";
    case "Spain":
      return "Toque cualquiera de los elementos para expandir";
    case "Greece":
      return "Πάτησε σε οποιοδήποτε σημείο για να τα επεκτείνεις";
    case "Italy":
      return "Tocca uno qualsiasi degli elementi per espanderlo.";
    case "Lithuania":
      return "Norėdami išplėsti, bakstelėkite bet kurį elementą.";
    case "Sweden":
      return "Tryck på något av objekten för att expandera.";
    case "Turkey":
      return "Genişletmek için öğelerden herhangi birine dokunun.";
    default:
      return "Unknown Language";
  }
}

// ----------------------------------------------- QUIZ PAGE ---------------------------------------------//
export function getGreetingsLabelByLanguage(language) {
  switch (language) {
    case "English":
      return "Greetings traveler!";
    case "Cyprus":
      return "Γεια σου ταξιδιώτη!";
    case "Spain":
      return "Saludos viajero!";
    case "Greece":
      return "Γεια σου ταξιδιώτη!";
    case "Italy":
      return "Benvenuti/e navigatori e navigatrici!";
    case "Lithuania":
      return "Labas, keliautojau (-a!)";
    case "Sweden":
      return "Hälsningar resenärer!";
    case "Turkey":
      return "Selamlar gezginler!";
    default:
      return "Unknown Language";
  }
}

export function getQuizInstructionsByLanguage(language) {
  switch (language) {
    case "English":
      return "These quizzes are designed to engage you with navigating the platform and locating information.  Use the platform to find the required information to answer the quizzes and by the time you are done you will be able to find anything.";
    case "Cyprus":
      return "Αυτά τα κουίζ έχουν σχεδιαστεί για να σε βοηθήσουν να εξερευνήσεις την πλατφόρμα και να εντοπίσεις πληροφορίες. Χρησιμοποιήσε την πλατφόρμα για να βρείς τις απαιτούμενες πληροφορίες για να απαντήσεις στα κουίζ και μέχρι να ολοκληρώσεις την εξερέυνηση, θα είσαι σε θέση να βρείς τα πάντα.";
    case "Spain":
      return "Estos cuestionarios están diseñados para que usted pueda navegar por la plataforma y localizar información. Utilice la plataforma para encontrar la información necesaria para responder los cuestionarios y cuando haya terminado podrá encontrar cualquier cosa.";
    case "Greece":
      return "Αυτά τα κουίζ έχουν σχεδιαστεί για να σε βοηθήσουν να εξερευνήσεις την πλατφόρμα και να εντοπίσεις πληροφορίες. Χρησιμοποιήσε την πλατφόρμα για να βρείς τις απαιτούμενες πληροφορίες για να απαντήσεις στα κουίζ και μέχρι να ολοκληρώσεις την εξερέυνηση, θα είσαι σε θέση να βρείς τα πάντα.";
    case "Italy":
      return "Queste domande serviranno per mostrarvi come utilizzare la piattaforma e trovare tutte le informazioni che cercate. Navigate nella piattaforma per cercare le risposte. Quando avrete completato il quiz sarete in grado di trovare qualsiasi informazione.";
    case "Lithuania":
      return "Ši interaktyvi instrukcija skirta geriau susipažinti su platforma ir išmokti pasirinkti specifiškai tau skirtą informaciją";
    case "Sweden":
      return "Dessa frågesporter är utformade för att hjälpa dig att navigera på plattformen och hitta information. Använd plattformen för att hitta den information som krävs för att svara på frågesporterna och när du är klar kommer du att kunna hitta vad som helst.";
    case "Turkey":
      return "Bu testler sizi platformda gezinmeye ve bilgi bulmaya yönlendirmek için tasarlanmıştır. Sınavları cevaplamak için gerekli bilgileri bulmak için platformu kullanın bu sayede işiniz bittiğinde her şeyi bulabileceksiniz.";
    default:
      return "Unknown Language";
  }
}

export function getQuizCompletedByLanguage(language) {
  switch (language) {
    case "English":
      return "Quiz completed";
    case "Cyprus":
      return "Το κουίζ ολοκληρώθηκε";
    case "Spain":
      return "Cuestionario completado";
    case "Greece":
      return "Το κουίζ ολοκληρώθηκε";
    case "Italy":
      return "Quiz completato";
    case "Lithuania":
      return "Žaidimas baigtas";
    case "Sweden":
      return "Frågesport slutfört";
    case "Turkey":
      return "Sınav tamamlandı";
    default:
      return "Unknown Language";
  }
}

export const getActorLoginPageLabelByLanguage = (language) => {
  switch (language) {
    case "English":
      return "Country";
    case "Cyprus":
      return "Χώρα";
    case "Spain":
      return "País";
    case "Greece":
      return "Χώρα";
    case "Italy":
      return "Paese";
    case "Lithuania":
      return "Valstybė";
    case "Sweden":
      return "Land";
    case "Turkey":
      return "Ülke";
    default:
      return "Unknown Language";
  }
};
