import { createSlice } from "@reduxjs/toolkit";

const saveToLocalStorage = (state) => {
  localStorage.setItem("actorState", JSON.stringify(state));
};

const actorSlice = createSlice({
  name: "actor",
  initialState: {
    country: "",
    language: "English",
  },
  reducers: {
    setActorCountry: (state, action) => {
      state.country = action.payload;
      state.language = "English";
      saveToLocalStorage(state);
    },
    toggleActorLanguage: (state) => {
      state.language = state.language === "English" ? state.country : "English";
      saveToLocalStorage(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase("someOtherAction", (state, action) => {
      // Do something with state
      saveToLocalStorage(state);
    });
  },
});

export const { setActorCountry, toggleActorLanguage } = actorSlice.actions;
export const actorReducer = actorSlice.reducer;
