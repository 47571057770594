import { configureStore } from "@reduxjs/toolkit";
import { userReducer } from "./slices/userSlice";
import { categoriesReducer } from "./slices/categoriesSlice";
// import { actorCategoriesReducer } from "./slices/actorCategoriesSlice";
import { actorReducer } from "./slices/actorSlice";
// import { localStorageMiddleware } from "./middleware/localStorageMiddleware";
import { setCountry, setImmigrantStatus, toggleLanguage } from "./slices/userSlice";

import { setActorCountry, toggleActorLanguage } from "./slices/actorSlice";

import { setCategories, setDiscarded, setIsShowing } from "./slices/categoriesSlice";
// import { setActorCategories, setActorDiscarded, setActorIsShowing } from "./slices/actorCategoriesSlice";

import { hasCountryAndStatusSelector } from "./selectors/userSelectors";
import { hasCountryActorSelector } from "./selectors/actorSelectors";

const preloadedState = {
  user: JSON.parse(localStorage.getItem("userState")) || {},
  actor: JSON.parse(localStorage.getItem("actorState")) || {},
  categories: JSON.parse(localStorage.getItem("categoryState")) || {
    saved: [],
    discarded: [],
    loading: false,
  },
  // actorCategories: JSON.parse(localStorage.getItem("actorCategoryState")) || {
  //   saved: [],
  //   discarded: [],
  //   loading: false,
  // },
};

const store = configureStore({
  reducer: {
    user: userReducer,
    categories: categoriesReducer,
    actor: actorReducer,
    // actorCategories: actorCategoriesReducer,
  },
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware().concat(localStorageMiddleware),
  preloadedState,
});

export {
  store,
  setCountry,
  setImmigrantStatus,
  toggleLanguage,
  hasCountryAndStatusSelector,
  setCategories,
  setDiscarded,
  setIsShowing,
  setActorCountry,
  toggleActorLanguage,
  hasCountryActorSelector,
  // setActorCategories,
  // setActorDiscarded,
  // setActorIsShowing,
}; // Export the store and actions
