import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import SubunitHeader from "../components/ui/SubunitHeader";
import {
  englishDetails,
  greeceDetails,
  italyDetails,
  turkeyDetails,
  lithuaniaDetails,
  spainDetails,
  swedenDetails,
} from "../components/context/actorSubunitData";
import { useParams } from "react-router-dom";
import ActorDescriptionComponent from "../components/ui/ActorDescriptionComponents";

// import SubunitInstructions from "../components/ui/SubunitInstructions";
import Button from "../components/ui/Button";
import { dark100, dark200, dark300 } from "../components/context/colors";
import {
  // getShortDLabelByLanguage,
  // getLinksLabelByLanguage,
  // getExpectationsLabelByLanguage,
  // getProceduresLabelByLanguage,
  // getInfoLabelByLanguage,
  // getDescriptionLabelByLanguage,
  // getWomenLabelByLanguage,
  getLinkCopiedTextByLanguage,
} from "../components/context/labels";

import { FaRegCopy } from "react-icons/fa";

const backgroundColorByCode = (code) => {
  const firstDigit = code[0];
  // console.log(firstDigit);
  const colorMap = {
    1: dark100,
    2: dark200,
    3: dark300,
  };

  const selectedColor = colorMap[firstDigit];
  if (selectedColor) return `bg-${selectedColor}`;
};

const langMapping = {
  English: englishDetails,
  Cyprus: greeceDetails,
  Spain: spainDetails,
  Greece: greeceDetails,
  Italy: italyDetails,
  Lithuania: lithuaniaDetails,
  Sweden: swedenDetails,
  Turkey: turkeyDetails,
};

function ActorDetails() {
  const { language } = useSelector((state) => state.actor);
  const { code } = useParams();
  const [expanded, setExpanded] = useState(false);
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);

  // Get the background color
  const headerBackgroundColor = backgroundColorByCode(code);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [language]);

  const expandTitle = () => {
    setExpanded(!expanded);
  };

  const handleCopyUrlClick = () => {
    // Copy the URL to the clipboard when the button is clicked
    navigator.clipboard.writeText(window.location.href);

    // Show the "Copied!" message
    setShowCopiedMessage(true);

    // Hide the message after a delay
    setTimeout(() => {
      setShowCopiedMessage(false);
    }, 500); // You can adjust the duration as needed
  };
  const { category, parentName, name, description } = langMapping[language][code];

  return (
    <div className="flex flex-col items-center bg-gray-900">
      <div className="pb-2 bg-gray-100 overflow-hidden min-h-[90vh] w-full max-w-4xl">
        <SubunitHeader
          id={code}
          title={category}
          parentName={parentName}
          name={name}
          expanded={expanded}
          onClick={() => expandTitle()}
          backgroundColor={headerBackgroundColor}
          isLoading={false}
        />

        {/* ------------------ BODY ----------------- */}
        <ActorDescriptionComponent descriptions={description} />
        {/* ------------------ AJusted Footer ----------------- */}
        {/* <SubunitInstructions isLoading={isLoading} language={language} /> */}

        {/* ------------------ Copy Button ----------------- */}
        <div className="fixed lg:right-[28%] right-[5%] bottom-9 justify-center z-20 ring-transparent floatingBtn">
          <Button secondary circle onClick={handleCopyUrlClick} animated shadow>
            <div className="py-1 ">
              <FaRegCopy size={25} />
            </div>
          </Button>
        </div>
        {/* ------------------ Copied Message ----------------- */}
        <div
          className="fixed bottom-[5.5rem] lg:right-[28%] right-[5%] p-0.5 text-black bg-gray-100 rounded-xl italic"
          style={{
            opacity: showCopiedMessage ? 1 : 0,
            transform: showCopiedMessage ? "translateY(0)" : "translateY(-10px)", // Adjust the translateY value for desired movement
            transition: "opacity 0.5s ease-in-out, transform 0.5s ease-in-out",
          }}
        >
          <p className="text-center">{getLinkCopiedTextByLanguage(language)}</p>
        </div>
      </div>
    </div>
  );
}

export default ActorDetails;
