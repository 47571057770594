import React from "react";

function ActorDescriptionComponent({ descriptions }) {
  // console.log(descriptions[0][0]);
  return (
    <div className="mb-10">
      <div className={`text-center font-medium border-b border-gray-950 rounded-md w-7/12 m-auto pt-5`}>
        {/* {getDescriptionLabelByLanguage(language)} */}
      </div>
      {descriptions.map((item, index) => {
        const { key, value } = item;
        if (key === "paragraph") {
          return (
            <React.Fragment key={index}>
              <p className="pt-4 pb-1 px-6 text-justify">{value}</p>
            </React.Fragment>
          );
        } else if (key === "list") {
          return (
            <ul key={index} className=" list-disc pl-5 py-2">
              {value.map((listItem, i) => (
                <li key={`${i}_list`} className="mx-5 text-left pb-1">
                  {listItem}
                </li>
              ))}
            </ul>
          );
        }
        return null; // Handle other cases if needed
      })}
    </div>
  );
}

export default ActorDescriptionComponent;
