import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toggleActorLanguage } from "../../store";
import "../../css/langToggle.css";
import { useLocation } from "react-router-dom"; // import useLocation
// import { setActorIsShowing } from "../../store";
import { getToggleLabelByLanguage } from "../context/labels";
import { useLayout } from "../context/LayoutContext";

const ActorLanguageToggle = () => {
  const dispatch = useDispatch();
  // const { isShowing } = useSelector((state) => state.actorCategories);
  const { language, country } = useSelector((state) => state.actor);

  const location = useLocation(); // Hook to get the current location

  const handleToggle = () => {
    // Dispatch the toggleLanguage action to update the language in Redux state
    dispatch(toggleActorLanguage());
  };

  // const handleExit = () => {
  //   localStorage.removeItem("userState"); // Remove the user item from localStorage
  //   navigate("/login"); // Navigate to the login page
  // };

  const ref = useRef(null);
  const { setLanguageToggleHeight } = useLayout();

  useEffect(() => {
    if (ref.current) {
      setLanguageToggleHeight(ref.current.clientHeight);
    }
  }, [setLanguageToggleHeight]);

  const languageTrs = (country) => {
    switch (country) {
      case "Cyprus":
        return "\u{1F1E8}\u{1F1FE}"; // Cyprus flag
      case "Spain":
        return "\u{1F1EA}\u{1F1F8}"; // Spain flag
      case "Greece":
        return "\u{1F1EC}\u{1F1F7}"; // Greece flag
      case "Italy":
        return "\u{1F1EE}\u{1F1F9}"; // Italy flag
      case "Lithuania":
        return "\u{1F1F1}\u{1F1F9}"; // Lithuania flag
      case "Sweden":
        return "\u{1F1F8}\u{1F1EA}"; // Sweden flag
      case "Turkey":
        return "\u{1F1F9}\u{1F1F7}"; // Turkey flag
      default:
        return ""; // Return an empty string by default
    }
  };

  const isSpecificPage = location.pathname === "/actor/select"; // replace with the path of the specific page

  return (
    <div className="fixed top-0 right-0 w-full flex justify-between items-center p-2 bg-gray-900 text-gray-200 z-50 min-h-[3rem] border-b border-white">
      {/* <div className=" items-center flex ml-1 ">
        <Button
          primary
          rounded
          // style={{ backgroundColor: "#cccccc", color: "black" }}
          onClick={handleExit}
          className=" bg-gray-200 border-gray-900 text-gray-900 shadow-sm shadow-gray-200 border-1 max-[600px]:text-xs"
        >
          Logout
        </Button>
      </div> */}

      <div className="items-center flex  mr-1 sm:text-sm">
        {/* justify-end to move it to the right */}
        <label className=" text-gray-200 mr-2 text-base max-[600px]:text-sm">{"\u{1F1EC}\u{1F1E7}"}</label>
        <label className="switch ">
          <input type="checkbox" onChange={handleToggle} checked={language !== "English"} />
          <span className="slider "></span>
        </label>
        <label className=" text-gray-200 ml-2 text-base max-[600px]:text-sm">{languageTrs(country)}</label>
      </div>

      {/* <div className=" items-center flex ml-1 ">
           <Button
            secondary
             rounded
             onClick={() => dispatch(setIsShowing(!isShowing))}
             className=" justify-end shadow-sm shadow-gray-200 max-[600px]:text-xs text-gray-200"
           >
            {isShowing ? "Hide Drawer" : "Show Drawer"}
           </Button>
         </div> */}
      {/* {isSpecificPage && (
        <div className="items-center flex  mr-1 sm:text-sm">
          <label className=" text-gray-200 mr-2 text-base max-[600px]:text-sm">
            {getToggleLabelByLanguage(language)}
          </label>
          <label className="switch">
            <input type="checkbox" onChange={() => dispatch(setActorIsShowing(!isShowing))} checked={isShowing} />
            <span className="slider "></span>
          </label>
        </div>
      )} */}
    </div>
  );
};
export default ActorLanguageToggle;
