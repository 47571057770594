import { useState, useEffect } from "react";

const useQuizProgress = () => {
  const initialProgress = {
    // initial progress structure
    Italy: { Italy_1: false, Italy_2: false, Italy_3: false, Italy_4: false },
    Greece: {
      Greece_1: false,
      Greece_2: false,
      Greece_3: false,
      Greece_4: false,
    },
    Lithuania: {
      Lithuania_1: false,
      Lithuania_2: false,
      Lithuania_3: false,
      Lithuania_4: false,
    },
    Spain: { Spain_1: false, Spain_2: false, Spain_3: false, Spain_4: false },
    Cyprus: {
      Cyprus_1: false,
      Cyprus_2: false,
      Cyprus_3: false,
      Cyprus_4: false,
    },
    Turkey: {
      Turkey_1: false,
      Turkey_2: false,
      Turkey_3: false,
      Turkey_4: false,
    },
    Sweden: {
      Sweden_1: false,
      Sweden_2: false,
      Sweden_3: false,
      Sweden_4: false,
    },
  };

  const [progress, setProgress] = useState(() => {
    // Try to load progress from local storage or initialize with default
    const savedProgress = localStorage.getItem("userProgress");
    return savedProgress ? JSON.parse(savedProgress) : initialProgress;
  });

  // Update local storage when progress changes
  useEffect(() => {
    localStorage.setItem("userProgress", JSON.stringify(progress));
  }, [progress]);

  // Function to update progress for a specific quiz
  const updateProgress = (quizId, completed = true) => {
    const [country, quizNumber] = quizId.split("_");
    setProgress((prevProgress) => ({
      ...prevProgress,
      [country]: {
        ...prevProgress[country],
        [quizId]: completed,
      },
    }));
  };

  // Function to check if a specific quiz is completed
  const isQuizCompleted = (quizId) => {
    const [country] = quizId.split("_");
    return progress[country] && progress[country][quizId];
  };

  return { progress, updateProgress, isQuizCompleted };
};

export default useQuizProgress;
