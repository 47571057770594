import { createSlice } from "@reduxjs/toolkit";

const saveToLocalStorage = (state) => {
  localStorage.setItem("userState", JSON.stringify(state));
};

const userSlice = createSlice({
  name: "user",
  initialState: {
    country: "",
    immigrantStatus: "",
    language: "English",
  },
  reducers: {
    setCountry: (state, action) => {
      state.country = action.payload;
      state.language = "English";
      saveToLocalStorage(state);
    },
    setImmigrantStatus: (state, action) => {
      state.immigrantStatus = action.payload;
      saveToLocalStorage(state);
    },
    toggleLanguage: (state) => {
      state.language = state.language === "English" ? state.country : "English";
      saveToLocalStorage(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase("someOtherAction", (state, action) => {
      // Do something with state
      saveToLocalStorage(state);
    });
  },
});

export const { setCountry, setImmigrantStatus, toggleLanguage } = userSlice.actions;
export const userReducer = userSlice.reducer;
