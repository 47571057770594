// import Button from "../components/ui/Button";
import "../css/notFoundPage.css";
// import { useNavigate } from "react-router-dom";
// import useCookie from "../hooks/useCookie";

function NotFoundPage() {
  // const navigate = useNavigate();
  // const [token] = useCookie("token");
  // function handleClick() {
  //   navigate("/select");
  // }
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-300">
      <h1 className="err_title">404</h1>
      <p className="err_text">Page not Found.</p>
      {/* <Button danger onClick={handleClick} rounded className="m-4 !p-4">
        {"Go Back"}
      </Button> */}
    </div>
  );
}

export default NotFoundPage;
