import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  getInfoHubPageLabelByLanguage,
  getActorLoginPageLabelByLanguage,
  getMenuLabelByLanguage,
} from "../context/labels";
import { useSelector } from "react-redux";
import Ripples from "react-ripples";
import { useLayout } from "../context/LayoutContext";

const BottomActorNavigation = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { language } = useSelector((state) => state.actor);
  const ref = useRef(null);
  const { setBottomNavHeight } = useLayout();

  useEffect(() => {
    if (ref.current) {
      setBottomNavHeight(ref.current.clientHeight);
    }
  }, [setBottomNavHeight]);

  return (
    <div ref={ref} className="fixed bottom-0 left-0 right-0 bg-gray-900 z-10">
      {/* Mobile View */}

      <div className=" flex justify-center items-center ">
        <Ripples color="#888" during={500} className="flex-1 rounded-md p-4">
          <button
            className="text-xl text-gray-100 flex-1 noTapHighlight flex-row flex justify-center items-center"
            onClick={() => setShowMenu(!showMenu)}
          >
            ☰<div className="ml-1  max-[450px]:text-base">{getMenuLabelByLanguage(language)}</div>
            {/* Burger Icon */}
          </button>
        </Ripples>

        <div
          className={`${
            showMenu ? "opacity-100 translate-y-0 z-50" : "opacity-0 -translate-y-full -z-50"
          } transition-opacity-transform duration-[800ms] ease-in-out absolute bottom-11 left-1/2 transform -translate-x-1/2 w-fit bg-gray-900 p-2 rounded-xl shadow-lg border border-gray-500`}
        >
          {showMenu && (
            <>
              <a
                href="https://vrin-platform.eu/vr/"
                className="block p-2 text-blue-300 hover:text-blue-700"
                onClick={() => setShowMenu(!showMenu)}
                target="_blank" // Opens the link in a new tab
                rel="noopener noreferrer" // Security measure for opening links in a new tab
              >
                VR
              </a>
              <Link
                to="/actor/main"
                className="block p-2 text-blue-300 hover:text-blue-700"
                onClick={() => setShowMenu(!showMenu)}
              >
                {getInfoHubPageLabelByLanguage(language)}
              </Link>
              <Link
                to="/actor"
                className="block p-2 text-blue-300 hover:text-blue-700"
                onClick={() => setShowMenu(!showMenu)}
              >
                {getActorLoginPageLabelByLanguage(language)}
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BottomActorNavigation;
