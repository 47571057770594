import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCategories, setDiscarded, setIsShowing } from "../store";
import { DragDropContext } from "react-beautiful-dnd";
//import FetchingDataAnimation from "../components/app/FetchingDataAnimation";
import { codes } from "../components/context/codesData";
import { motion, AnimatePresence } from "framer-motion";

import DroppableDeleteColumn from "../components/app/DroppableDeleteColumn";
import DroppableSavedColumn from "../components/app/DroppableSavedColumn";
import "../css/drawer.css";
// import Button from "../components/ui/Button";
import ModalMedia from "../components/ui/ModalMedia";
import { MdOutlineOpenInNew } from "react-icons/md";

import {
  getVisibleLabelByLanguage,
  getDiscardedLabelByLanguage,
  getWelcomeMsgCustomizeByLanguage,
  getShowTutorialBTNByLanguage,
} from "../components/context/labels";

const videoMapping = {
  English: "/video/VRIN_Onboarding_EN.mp4",
  Cyprus: "/video/VRIN_Onboarding_GR.mp4",
  Spain: "/video/VRIN_Onboarding_EN.mp4",
  Greece: "/video/VRIN_Onboarding_GR.mp4",
  Italy: "/video/VRIN_Onboarding_EN.mp4",
  Lithuania: "/video/VRIN_Onboarding_LT.mp4",
  Sweden: "/video/VRIN_Onboarding_SW.mp4",
  Turkey: "/video/VRIN_Onboarding_EN.mp4",
};

const SelectionPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { saved, discarded, isShowing } = useSelector((state) => state.categories);
  const { language } = useSelector((state) => state.user);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    console.log(language);
    const fetchedCategories = codes[language]; //fetched categories
    //console.log(fetchedCategories);
    const currentDiscarded = discarded;

    // Arrays for saved and discarded categories
    let newSavedCategories = [];
    let newDiscardedCategories = [];

    // Filter out the discarded categories from the fetched categories
    fetchedCategories.forEach((category) => {
      if (currentDiscarded.some((discardedItem) => discardedItem.code === category.code)) {
        newDiscardedCategories.push(category);
      } else {
        newSavedCategories.push(category);
      }
    });

    // console.log("fetchedCategories:", fetchedCategories);
    // console.log("newSavedCategories:", newSavedCategories);
    // console.log("newDiscardedCategories:", newDiscardedCategories);
    // console.log(discarded);

    //  set the categories with the filtered list
    dispatch(setCategories(newSavedCategories));
    dispatch(setDiscarded(newDiscardedCategories));
  }, [language]);

  // Function to determine parent code, adjusted to accept current level
  const getParentCode = (childCode, level) => {
    if (level === 3) {
      return childCode.substring(0, 2) + "0"; // For level 3 to level 2
    } else if (level === 2) {
      return childCode.substring(0, 1) + "00"; // For level 2 to level 1
    }
    // Level 1 items do not have a parent, return null to stop the loop
    return null;
  };

  const sortByCode = (a, b) => {
    return a.code.localeCompare(b.code);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    //console.log("Drag ended with result:", result);

    // Dropped outside the list or back into the same position
    if (!destination) return;
    if (source.droppableId === destination.droppableId) return;

    const sourceList = source.droppableId === "Saved" ? saved : discarded;

    const destinationList = destination.droppableId === "Saved" ? saved : discarded;

    const draggedItem = sourceList[source.index];
    let currentCode = draggedItem.code;
    //console.log("Dragged item:", draggedItem);

    const getCodeLevel = (code) => {
      if (/^\d00$/.test(code)) return 1;
      if (/^\d\d0$/.test(code)) return 2;
      return 3;
    };

    const findChildren = (items, parentCode, level) => {
      let regex;
      if (level === 1) {
        regex = new RegExp(`^${parentCode[0]}\\d{2}$`);
      } else if (level === 2) {
        regex = new RegExp(`^${parentCode.substring(0, 2)}\\d$`);
      } else {
        return [];
      }
      return items.filter((item) => regex.test(item.code) && item.code !== parentCode);
    };

    const codeLevel = getCodeLevel(draggedItem.code);
    let itemsToMove = [draggedItem];

    // If we're moving to the saved list, ensure all ancestors are also moved
    if (destination.droppableId === "Saved") {
      let codeLevel = getCodeLevel(draggedItem.code);

      while (codeLevel > 1) {
        currentCode = getParentCode(currentCode, codeLevel); // Pass current level
        const parentItem = sourceList.find((item) => item.code === currentCode);

        // If the parent item is not already in saved, prepend it to the items to move
        if (parentItem && !saved.some((item) => item.code === currentCode)) {
          itemsToMove.unshift(parentItem);
        }

        codeLevel--; // Decrement the level as you go up the hierarchy
      }
    }

    if (codeLevel < 3) {
      const children = findChildren(sourceList, draggedItem.code, codeLevel);
      //console.log("Children to move:", children);
      itemsToMove = itemsToMove.concat(children);
    }

    const newSourceList = sourceList.filter((item) => !itemsToMove.includes(item));
    const newDestinationList = [...destinationList];

    if (source.droppableId !== destination.droppableId) {
      newDestinationList.splice(destination.index, 0, ...itemsToMove);
    } else {
      const adjustedIndex =
        destination.index > source.index ? destination.index - itemsToMove.length : destination.index;
      newDestinationList.splice(adjustedIndex, 0, ...itemsToMove);
    }

    newSourceList.sort(sortByCode);
    newDestinationList.sort(sortByCode);

    if (source.droppableId === "Saved") {
      dispatch(setCategories(newSourceList));
      dispatch(setDiscarded(newDestinationList));
    } else {
      dispatch(setDiscarded(newSourceList));
      dispatch(setCategories(newDestinationList));
    }

    //console.log("New source list:", newSourceList);
    //console.log("New destination list:", newDestinationList);
  };

  return (
    <div className="max-[400px]:text-xs max-[500px]:text-sm">
      <div className="pt-2 shadow-md border-gray-900 text-gray-200 bg-gray-900">
        <p className="p-2 text-center ">{getWelcomeMsgCustomizeByLanguage(language)}</p>
        <div className="flex items-center justify-center">
          <div className=" flex  flex-row">
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.8 }}
              className="flex-1 flex flex-row w-2/4 p-2 mx-auto my-2 border-gray-200 border-2 bg-gray-900 text-gray-200 font-bold rounded-md justify-center shadow-sm shadow-gray-200 items-center"
              onClick={() => (isModalOpen ? closeModal() : openModal())}
            >
              <span className="mr-2">{getShowTutorialBTNByLanguage(language)}</span>
              <MdOutlineOpenInNew size={20} />
            </motion.button>
          </div>
        </div>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <div className="flex overflow-hidden">
          <DroppableSavedColumn
            title={getVisibleLabelByLanguage(language)}
            id="Saved"
            categories={saved}
            setCategories={(newList) => dispatch(setCategories(newList))}
            isDrawerOpen={isShowing}
            showingFunction={setIsShowing}
          />
          {/* The drawer */}
          <DroppableDeleteColumn
            title={getDiscardedLabelByLanguage(language)}
            id="Discarded"
            categories={discarded}
            setCategories={(newList) => dispatch(setDiscarded(newList))}
            isDrawerOpen={isShowing}
            showingFunction={setIsShowing}
          />
        </div>
      </DragDropContext>
      <AnimatePresence
        // Disable any initial animations on children that
        // are present when the component is first rendered
        initial={false}
        // Only render one component at a time.
        // The exiting component will finish its exit
        // animation before entering component is rendered
        mode="wait"
        // Fires when all exiting nodes have completed animating out
        onExitComplete={() => null}
      >
        {isModalOpen && <ModalMedia modalOpen={openModal} handleClose={closeModal} url={videoMapping[language]} />}
      </AnimatePresence>
    </div>
  );
};

export default SelectionPage;
