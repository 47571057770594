const dark100 = "red-700";
const med100 = "red-400";
const light100 = "red-200";

const sDark100 = "shadow-red-700";
const sMed100 = "shadow-red-400";
const sLight100 = "shadow-red-200";

const dark200 = "blue-700";
const med200 = "blue-400";
const light200 = "blue-200";

const sDark200 = "shadow-blue-700";
const sMed200 = "shadow-blue-400";
const sLight200 = "shadow-blue-200";

const dark300 = "yellow-600";
const med300 = "yellow-400";
const light300 = "yellow-200";

const sDark300 = "shadow-yellow-700";
const sMed300 = "shadow-yellow-400";
const sLight300 = "shadow-yellow-200";

const dark400 = "green-700";
const med400 = "green-400";
const light400 = "green-200";

const sDark400 = "shadow-green-700";
const sMed400 = "shadow-green-400";
const sLight400 = "shadow-green-200";

export {
  dark100,
  med100,
  light100,
  sDark100,
  sMed100,
  sLight100,
  dark200,
  med200,
  light200,
  sDark200,
  sMed200,
  sLight200,
  dark300,
  med300,
  light300,
  sDark300,
  sMed300,
  sLight300,
  dark400,
  med400,
  light400,
  sDark400,
  sMed400,
  sLight400,
};
