import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import Modal2 from "../components/ui/Modal2";
import { useSelector } from "react-redux";
import { Card, Progress } from "flowbite-react";

import WaveComponent from "../components/ui/WaveComponent";
import MQComponent from "../components/app/MQComponent";
import TFComponent from "../components/app/TFComponent";
import { useNavigate } from "react-router-dom";
import { getQuizCompletedByLanguage } from "../components/context/labels";

import useQuizProgress from "../components/hooks/useQuizProgress";
import { useLayout } from "../components/context/LayoutContext";
// import FetchingDataAnimation2 from "../components/app/FetchingDataAnimation2";
// import FetchingDataAnimation from "../components/app/FetchingDataAnimation";

function QuestionsPage() {
  const [isAnimatingProgress, setIsAnimatingProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const location = useLocation();
  const [questions, setQuestions] = useState([{}, {}, {}, {}]);
  const [quiz, setQuiz] = useState({});
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [progressBar, setProgressBar] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const { updateProgress } = useQuizProgress();
  const quizId = location.state?.quizId || localStorage.getItem("quizId");
  console.log(currentQuestion);

  // console.log(questions.length);
  const { language } = useSelector((state) => state.user);
  const { totalHeight } = useLayout();
  const message = getQuizCompletedByLanguage(language);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    navigate("/quiz");
  };

  const handleAnswer = (answer) => {
    window.scrollTo(0, 0);
    setSelectedAnswer(answer);
    if (answer === questions[currentQuestion].correct) {
      setIsAnimatingProgress(true); // Start the progress animation

      setTimeout(() => {
        setIsAnimatingProgress(false); // End the progress animation
        const nextQuestion = currentQuestion + 1;
        if (nextQuestion < quiz.questions_length) {
          setCurrentQuestion(nextQuestion);
          setProgressBar(progressBar + 1);
        } else {
          //complete
          setProgressBar(progressBar + 1);
          setIsModalOpen(true);
          updateProgress(quizId);
        }
      }, 1500); // Wait for 1.5 seconds
    } else {
      //console.log("wrong");
      // Handle wrong answer logic
    }
  };

  useEffect(() => {
    setSelectedAnswer(null);
  }, [currentQuestion]);

  useEffect(() => {
    if (!quizId) return;
    setIsLoading(true);
    Promise.all([
      axios.get(`https://dev.silversky3d.com/api_vrin/quiz/specific/${quizId}`),
      axios.get(`https://dev.silversky3d.com/api_vrin/quiz/questions/${quizId}/${language}`),
    ])
      .then(([quizResponse, questionsResponse]) => {
        setQuiz(quizResponse.data);
        setQuestions(questionsResponse.data);
        //console.log(quizResponse.data, questionsResponse.data);
      })
      .catch((error) => {
        console.error("Error in fetching data", error);
      })
      .finally(setIsLoading(false));
  }, [quizId, language]);

  if ((questions.length < 1 || questions[0].options == null) && isLoading) {
    return (
      <div
        className=" text-xl font-bold text-center text-red-50 pt-10 pt-50 min-h-screen"
        style={{ backgroundColor: "#2e2e2e" }}
      >
        Data missing from the Database
      </div>
    );
  }

  return (
    <div
      className=""
      style={{
        background: "linear-gradient(60deg, rgba(84,58,183,1) 0%, rgba(0,172,193,1) 100%)",
        minHeight: `calc(100vh - ${totalHeight * 2}px)`,
      }}
    >
      <div className="w-full py-5 bg-gray-900 ">
        <div className="w-3/4 mx-auto max-w-4xl">
          <Progress
            progress={(progressBar / questions.length) * 100}
            progressLabelPosition="inside"
            // textLabel={
            //   "Question " + (currentQuestion + 1) + " / " + questions.length
            // }
            textLabelPosition="outside"
            // labelText
            color="yellow"
          />
        </div>
      </div>
      <Card style={{ backgroundColor: "#ffffff33" }} className="w-5/6 m-auto mt-3 max-w-4xl ">
        <p className="font-semibold text-gray-900 max-[400px]:text-base text-lg text-justify">
          {questions[currentQuestion].text}
        </p>
        {questions[currentQuestion].type === "MQ" && (
          <MQComponent
            question={questions[currentQuestion]}
            handleAnswer={handleAnswer}
            selectedAnswer={selectedAnswer}
            isAnimatingProgress={isAnimatingProgress}
          />
        )}
        {questions[currentQuestion].type === "TF" && (
          <TFComponent
            question={questions[currentQuestion]}
            handleAnswer={handleAnswer}
            selectedAnswer={selectedAnswer}
            isAnimatingProgress={isAnimatingProgress}
          />
        )}
        {isAnimatingProgress && <div className="loading-bar" style={{ animation: "fillBar 1.5s ease" }} />}
      </Card>

      <div className="absolute bottom-0 left-0 right-0 mb-2 m-auto">
        <WaveComponent />
      </div>

      <AnimatePresence
        // Disable any initial animations on children that
        // are present when the component is first rendered
        initial={false}
        // Only render one component at a time.
        // The exiting component will finish its exit
        // animation before entering component is rendered
        mode="wait"
        // Fires when all exiting nodes have completed animating out
        onExitComplete={() => null}
      >
        {isModalOpen && <Modal2 modalOpen={openModal} handleClose={closeModal} text={message} />}
      </AnimatePresence>
    </div>
  );
}

export default QuestionsPage;
