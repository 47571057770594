export const cyprusCat = [
  // --------------------------  100 -------------------------- //
  {
    code: "100",
    name: "Επικοινωνιακές και γλωσσικές δεξιότητες",
    bgColor: "bg-red-700",
    description: (
      <div>
        <p>
          Οι επικοινωνιακές και γλωσσικές δεξιότητες είναι απαραίτητες για τους
          ΥΤΧ (Υπηκόους Τρίτων Χωρών), τους μετανάστες, τους αναγνωρισμένους
          πρόσφυγες και τους αιτούντες άσυλο για να καταφέρουν να ενταχθούν στις
          χώρες υποδοχής τους. Η ικανότητα αποτελεσματικής επικοινωνίας στη
          γλώσσα της χώρας υποδοχής μπορεί να διευκολύνει την ενσωμάτωση, να
          αυξήσει την απασχολησιμότητα και να προάγει τις κοινωνικές συνδέσεις.
          Ωστόσο, οι γλωσσικοί φραγμοί μπορεί να αποτελέσουν σημαντικό εμπόδιο
          στην πρόσβαση σε υπηρεσίες και ευκαιρίες, οδηγώντας σε κοινωνικό
          αποκλεισμό και απομόνωση. Η ανάπτυξη γλωσσικών δεξιοτήτων μπορεί να
          είναι ένα βασικό βήμα για την υπέρβαση αυτών των φραγμών και την
          οικοδόμηση μιας επιτυχημένης νέας ζωής σε μια νέα χώρα. Επιπλέον, η
          πολιτιστική επίγνωση και ευαισθητοποίηση είναι επίσης σημαντικά
          στοιχεία για την αποτελεσματική επικοινωνία σε ένα νέο πολιτιστικό
          πλαίσιο.
        </p>
      </div>
    ),
    units: [
      {
        code: "110",
        name: "Επικοινωνία με τοπικές αρχές",
        subunits: [
          {
            code: "111",
            name: "Αλληλεπίδραση με τις αρχές",
          },
          {
            code: "112",
            name: "Αντιλήψεις και προσδοκίες σχετικά με την  κουλτούρα, τις συνθήκες και την επικοινωνία",
          },
          { code: "113", name: "Kανόνες και κανονισμοί" },
          { code: "114", name: "Τι πρέπει να κάνετε και τι να μην κάνετε" },
          { code: "115", name: "Πολιτιστικές πτυχές" },
          {
            code: "116",
            name: "Πρόσβαση σε βοήθεια κατά τη διάρκεια έκτακτης ανάγκης",
          },
        ],
      },
      {
        code: "120",
        name: "Καθημερινές γλωσσικές ανάγκες",
        subunits: [
          {
            code: "121",
            name: "Καθημερινή επικοινωνία (βασικές λέξεις στην ελληνική και την αγγλική γλώσσα)",
          },
          {
            code: "122",
            name: "Πληροφορίες για διαθέσιμες ευκαιρίες εκμάθησης της γλώσσας",
          },
          {
            code: "123",
            name: "Αντιλήψεις και προσδοκίες σχετικά με την κουλτούρα, τις συνθήκες και την επικοινωνία",
          },
          {
            code: "124",
            name: "Επίσκεψη στην υπεραγορά, το σχολείο, τον γιατρό και το νοσοκομείο, τις υπηρεσίες απασχόλησης, αναζήτηση εργασίας, στέγαση και μέσα μαζικής μεταφοράς",
          },
        ],
      },
    ],
  },
  //   --------------------------  200 -----------------------------  //
  {
    code: "200",
    name: "Απασχόληση",
    bgColor: "bg-blue-700",
    description: (
      <div>
        <p>
          Η απασχόληση είναι μια κρίσιμη πτυχή της διαδικασίας ενσωμάτωσης για
          τους ΥΤΧ (Υπηκόους Τρίτων Χωρών), τους μετανάστες, τους
          αναγνωρισμένους πρόσφυγες και τους αιτούντες άσυλο. Η πρόσβαση σε
          σταθερή και αξιοπρεπή εργασία μπορεί να προσφέρει οικονομική
          σταθερότητα, κοινωνική ένταξη και αίσθηση του ανήκειν στη νέα χώρα
          υποδοχής. Ωστόσο, οι ευκαιρίες απασχόλησης για αυτές τις ομάδες μπορεί
          να είναι περιορισμένες λόγω της επιλεξιμότητας όσον αφορά το καθεστώς
          τους, τους γλωσσικούς φραγμούς, τις διακρίσεις, την έλλειψη
          αναγνώρισης ξένων προσόντων και άλλων εμποδίων. Οι κυβερνήσεις και οι
          οργανισμοί μπορούν να διαδραματίσουν σημαντικό ρόλο στην αντιμετώπιση
          αυτών των προκλήσεων παρέχοντας γλωσσική κατάρτιση, διευκολύνοντας την
          αναγνώριση δεξιοτήτων, διαμεσολαβώντας για την εξεύρεση εργασίας και
          προωθώντας την πολυμορφία και την ένταξη στον χώρο εργασίας. Επιπλέον,
          οι εργοδότες μπορούν να επωφεληθούν από τις πολύμορφες δεξιότητες,
          προοπτικές και εμπειρίες που προσφέρουν αυτές οι ομάδες στο εργατικό
          δυναμικό.
        </p>
      </div>
    ),
    units: [
      {
        code: "210",
        name: "Υπηρεσίες Κοινωνικών Ασφαλίσεων",
        subunits: [
          {
            code: "211",
            name: "Περισσότερες πληροφορίες για τις Υπηρεσίες Κοινωνικών Ασφαλίσεων",
          },
          {
            code: "212",
            name: "Προστασία από διάφορους κινδύνους: Ενημέρωση και προσδοκίες για την υγεία και την ασφάλεια",
          },
        ],
      },
      {
        code: "220",
        name: "Πρόσβαση σε εργασία/απασχόληση",
        subunits: [
          {
            code: "221",
            name: "Περισσότερες πληροφορίες για την αγορά εργασίας της χώρας (π.χ. συνθήκες εργασίας)",
          },
          {
            code: "222",
            name: "Επίγνωση των πρακτικών εκμετάλλευσης (π.χ. σεξουαλική παρενόχληση) ",
          },
          { code: "223", name: "Aντιμετώπιση των προκαταλήψεων" },
          { code: "224", name: "Αναζήτηση εργασίας" },
          { code: "225", name: "Εργασιακή κουλτούρα της χώρας υποδοχής" },
          {
            code: "226",
            name: "Εύρος μισθών (ανάλογα με τις δεξιότητες, εμπειρία, εκπαίδευση) και τι να περιμένετε",
          },
        ],
      },
    ],
  },
  //   --------------------------  300 -----------------------------  //
  {
    code: "300",
    name: "Εγκατάσταση",
    bgColor: "bg-yellow-600",
    description: (
      <div>
        <p>
          Η πρόσβαση σε κατάλληλη στέγαση και διαμονή είναι ένα θεμελιώδες
          ανθρώπινο δικαίωμα που είναι απαραίτητο για την υγεία, την ασφάλεια
          και την ευημερία των ΥΤΧ (Υπηκόων Τρίτων Χωρών), των μεταναστών, των
          αναγνωρισμένων προσφύγων και των αιτούντων άσυλο. Η στέγαση
          διαδραματίζει κρίσιμο ρόλο στην παροχή μιας σταθερής και ασφαλούς
          βάσης για την ένταξη αυτών των ομάδων στις χώρες υποδοχής τους.
          Ωστόσο, πολλοί αντιμετωπίζουν σημαντικά προβλήματα όσον αφορά την
          πρόσβαση σε επαρκή στέγαση, όπως η επιλεξιμότητα λόγω του καθεστώτος
          τους, οι οικονομικοί περιορισμοί, οι διακρίσεις και οι γλωσσικοί
          φραγμοί. Οι κυβερνήσεις και οι οργανισμοί ενδέχεται να είναι σε θέση
          να αντιμετωπίσουν αυτά τα ζητήματα παρέχοντας οικονομικά προσιτές
          επιλογές στέγασης. Επιπλέον, πρωτοβουλίες που λειτουργούν στο επίπεδο
          των τοπικών κοινοτήτων, όπως χορηγίες και προγράμματα υποδοχής
          προσφύγων, αν υπάρχουν, μπορούν να βοηθήσουν στη δημιουργία φιλόξενων
          και συμπεριληπτικών γειτονιών για τα νεοεισερχόμενα άτομα.
        </p>
      </div>
    ),
    units: [
      {
        code: "310",
        name: "Στέγαση (και Διαμονή)",
        subunits: [
          { code: "311", name: "Προσδοκίες σχετικά με τη στέγαση" },
          { code: "312", name: "Λογαριασμοί, ενοίκιο, τέλη" },
          {
            code: "313",
            name: "Πρόσβαση σε στέγαση/κέντρα υποδοχής/κοινωνική στέγαση",
          },
          { code: "314", name: "Αντιμετώπιση των προκαταλήψεων" },
        ],
      },
      {
        code: "320",
        name: "Οικονομικές διαδικασίες",
        subunits: [
          {
            code: "321",
            name: "Τρόπος λειτουργίας των τραπεζών και εναλλακτικοί τρόποι τραπεζικών συναλλαγών (π.χ. Revolut)",
          },
          { code: "322", name: "Άνοιγμα τραπεζικού λογαριασμού" },
          { code: "323", name: "Πού να αναζητήσετε υποστήριξη και καθοδήγηση" },
          { code: "324", name: "Δικαίωμα για δάνειο" },
          { code: "325", name: "Ωφελήματα (οικονομικά και άλλα)" },
          { code: "326", name: "Οικονομική ένταξη στη χώρα διαμονής" },
        ],
      },
      {
        code: "330",
        name: "Πρόσβαση σε εκπαίδευση/τα βήματα",
        subunits: [
          { code: "331", name: "Επικύρωση σπουδών/διπλωμάτων/πτυχίων, κ.λπ." },
          {
            code: "332",
            name: "Αναζήτηση επιπλέον πληροφοριών (π.χ. εγγραφή, κριτήρια,  εγγραφή παιδιών στο εκπαιδευτικό σύστημα)",
          },
          { code: "333", name: "Πρόσβαση σε συμβουλευτική (π.χ. σύμβουλοι)" },
        ],
      },
      {
        code: "340",
        name: "Κοινωνικοποίηση/οικοδόμηση κοινότητας",
        subunits: [
          {
            code: "341",
            name: "Εξεύρεση κοινοτικών εκδηλώσεων - συναντήσεις με άτομα της ίδιας/παρόμοιας κουλτούρας",
          },
        ],
      },
    ],
  },
  //   --------------------------  400 -----------------------------  //
  {
    code: "400",
    name: "Υγεία",
    bgColor: "bg-green-700",
    description: (
      <div>
        <p>
          Η πρόσβαση στην υγειονομική περίθαλψη είναι μια κρίσιμη πτυχή της
          διαδικασίας επανεγκατάστασης για τους ΥΤΧ (Υπηκόους Τρίτων Χωρών),
          τους μετανάστες, τους αναγνωρισμένους πρόσφυγες και τους αιτούντες
          άσυλο. Δυστυχώς, αυτές οι ομάδες μπορεί να αντιμετωπίσουν ιδιαίτερες
          προκλήσεις σχετικά με την υγειονομική περίθαλψη, λόγω του καθεστώτος
          και της επιλεξιμότητάς τους, των γλωσσικών φραγμών, των πολιτισμικών
          διαφορών και της έλλειψης εξοικείωσης με το σύστημα υγειονομικής
          περίθαλψης στη νέα χώρα υποδοχής τους. Επιπλέον, ορισμένοι μπορεί να
          έχουν βιώσει τραύματα, βία και να έχουν προβλήματα υγείας που
          σχετίζονται με το μεταναστευτικό τους ταξίδι. Οι κυβερνήσεις και οι
          οργανισμοί μπορούν να αντιμετωπίσουν αυτά τα προβλήματα παρέχοντας
          πρόσβαση σε ολοκληρωμένες υπηρεσίες υγειονομικής περίθαλψης,
          συμπεριλαμβανομένης της υποστήριξης ψυχικής υγείας και της φροντίδας
          μετά από τραυματικές εμπειρίες. Η διασφάλιση πολιτιστικά κατάλληλων
          και προσβάσιμων υπηρεσιών υγειονομικής περίθαλψης μπορεί να βελτιώσει
          τη σωματική και ψυχική υγεία αυτών των ομάδων και να διευκολύνει την
          ενσωμάτωσή τους στις νέες τους κοινότητες.
        </p>
      </div>
    ),
    units: [
      {
        code: "410",
        name: "Υπηρεσίες υγείας",
        subunits: [
          { code: "411", name: "Αριθμός κοινωνικών ασφαλίσεων" },
          { code: "412", name: "Πληροφορίες για Covid-19 και εμβολιασμό" },
          { code: "413", name: "Πρόσβαση σε νοσοκομεία και γιατρούς" },
          { code: "414", name: "Πληροφορίες για το σύστημα υγείας" },
        ],
      },
      {
        code: "420",
        name: "Ψυχολογική υποστήριξη",
        subunits: [
          {
            code: "421",
            name: "Πρόσβαση σε ψυχολογική υποστήριξη και αναγνώριση της ανάγκης για ψυχολογική υποστήριξη και συμβουλευτική",
          },
          { code: "422", name: "Κόστος και πληροφορίες" },
          {
            code: "423",
            name: "Απαλλαγή από το στίγμα της ανάγκης για επίσκεψη/σταθερές επισκέψεις σε ειδικό",
          },
          {
            code: "424",
            name: "Πρόσβαση σε βοήθεια σε περίπτωση έκτακτης ανάγκης",
          },
        ],
      },
    ],
  },
];

export const englishCat = [
  //   --------------------------  100 -----------------------------  //
  {
    code: "100",
    name: "Communication - Language Skills",
    bgColor: "bg-red-700",
    description: (
      <div>
        <p>
          Communication and Language skills are essential for TCNs (Third
          Country Nationals), migrants, recognised refugees, and asylum seekers
          to succeed in their new host countries. The ability to communicate
          effectively in the language of the host country can facilitate
          integration, increase employability, and improve social connections.
          However, language barriers can be a significant obstacle to access to
          services and opportunities, leading to social exclusion and isolation.
          Developing language skills can be a key step in overcoming these
          barriers and building a successful new life in a new country.
          Additionally, cultural awareness and sensitivity are also important
          components of effective communication in a new cultural context.
        </p>
      </div>
    ),
    units: [
      {
        code: "110",
        name: "Communication with local authorities",
        subunits: [
          { code: "111", name: "Interact with authorities" },
          {
            code: "112",
            name: "Cultural, situational and interactional awareness and expectation",
          },
          { code: "113", name: "Rules and Regulations" },
          { code: "114", name: "Dos and Don’ts" },
          { code: "115", name: "Cultural aspects" },
          { code: "116", name: "Accessing help in case of emergencies" },
        ],
      },
      {
        code: "120",
        name: "Everyday language needs",
        subunits: [
          {
            code: "121",
            name: "Daily communication (basic words in local language and English language)",
          },
          {
            code: "122",
            name: "Information on language learning availabilities",
          },
          {
            code: "123",
            name: "Cultural, situational and interactional awareness and expectatios",
          },
          {
            code: "124",
            name: "Visiting supermarket, school, doctors and hospitals, labour service, seeking for employment, housing, transportation",
          },
        ],
      },
    ],
  },
  //   --------------------------  200 -----------------------------  //
  {
    code: "200",
    name: "Employment",
    bgColor: "bg-blue-700",
    description: (
      <div>
        <p>
          Employment is a crucial aspect of the integration process for TCNs
          (Third Country Nationals), migrants, recognised refugees, and asylum
          seekers. Access to stable and decent work can provide financial
          stability, social inclusion, and a sense of belonging in their new
          host country. However, employment opportunities for these groups can
          be limited due to eligibility in regards to their status, language
          barriers, discrimination, lack of recognition of foreign
          qualifications, and other barriers. Governments and organizations can
          play an important role in addressing these challenges by providing
          language training, facilitating skills recognition, offering job
          placement services, and promoting diversity and inclusion in the
          workplace. Furthermore, employers can benefit from the diverse skills,
          perspectives, and experiences that these groups bring to the
          workforce.
        </p>
      </div>
    ),
    units: [
      {
        code: "210",
        name: "Social Insurance",
        subunits: [
          {
            code: "211",
            name: "Further information about social insurance services",
          },
          {
            code: "212",
            name: "Protection against various risks: Health and safety awareness and exploitation",
          },
        ],
      },
      {
        code: "220",
        name: "Labour/Employment access",
        subunits: [
          {
            code: "221",
            name: "Further information about the labour market of the country (eg. How they work)",
          },
          {
            code: "222",
            name: "Being aware of exploitative practices (eg sexual harassment)",
          },
          { code: "223", name: "Overcoming biases, prejudice" },
          { code: "224", name: "Searching for employment" },
          { code: "225", name: "Working culture of receiving country" },
          {
            code: "226",
            name: "Salary-ranges (depending on skills, experience, education) & what to expect",
          },
        ],
      },
    ],
  },
  //   --------------------------  300 -----------------------------  //
  {
    code: "300",
    name: "Settling",
    bgColor: "bg-yellow-600",
    description: (
      <div>
        <p>
          Access to suitable housing and accommodation is a fundamental human
          right that is essential for the health, safety, and wellbeing of TCNs
          (Third Country Nationals), migrants, recognised refugees, and asylum
          seekers. Housing plays a crucial role in providing a stable and secure
          foundation for the integration of these groups into their new host
          countries. However, many face significant challenges in accessing
          adequate housing, such as eligibility due to status, financial
          constraints, discrimination, and language barriers. Governments and
          organizations may be able to address these issues by providing
          affordable housing options. Additionally, community-based initiatives,
          such as sponsorships and refugee welcome programs, if applicable, can
          help to create welcoming and inclusive neighbourhoods for newcomers.
        </p>
      </div>
    ),
    units: [
      {
        code: "310",
        name: "Housing & Accommodation",
        subunits: [
          { code: "311", name: "Expectations regarding properties" },
          { code: "312", name: "Bills, rent, utilities" },
          {
            code: "313",
            name: "Accessing housing/communal areas/social housing",
          },
          { code: "314", name: "Overcoming biases, prejudice" },
        ],
      },
      {
        code: "320",
        name: "Financial procedures",
        subunits: [
          { code: "321", name: "How banks operate & no banking (eg revolut)" },
          { code: "322", name: "Opening bank accounts" },
          { code: "323", name: "Where to seek for support & guidance" },
          { code: "324", name: "Loan eligibility" },
          {
            code: "325",
            name: "Benefits (financial literacy, administration)",
          },
          {
            code: "326",
            name: "Financial registration in the country of residence",
          },
        ],
      },
      {
        code: "330",
        name: "Educational access/steps",
        subunits: [
          { code: "331", name: "Validating studies/diplomas/degrees etc" },
          {
            code: "332",
            name: "Seeking further information (eg. Enrolment, criteria, children enrolment into education system)",
          },
          { code: "333", name: "Accessing advice (eg. Counsellors)" },
        ],
      },
      {
        code: "340",
        name: "Socialisation/community building",
        subunits: [
          {
            code: "341",
            name: "Identifying community events/gatherings with people of same/similar cultures",
          },
        ],
      },
    ],
  },
  //   --------------------------  400 -----------------------------  //
  {
    code: "400",
    name: "Health",
    bgColor: "bg-green-700",
    description: (
      <div>
        <p>
          Access to healthcare is a crucial aspect of the resettlement process
          for TCNs (Third Country Nationals), migrants, recognised refugees, and
          asylum seekers. These groups may face unique healthcare challenges due
          to -unfortunately- their status and eligibilities, language barriers,
          cultural differences, and lack of familiarity with the healthcare
          system in their new host country. Additionally, some may have
          experienced trauma, violence, and health issues related to their
          migration journey. Governments and organisations can address these
          challenges by providing access to comprehensive healthcare services,
          including mental health support and trauma-informed care. Ensuring
          culturally appropriate and accessible healthcare services can improve
          the physical and mental health outcomes of these groups, and
          facilitate their integration into their new communities.
        </p>
      </div>
    ),
    units: [
      {
        code: "410",
        name: "Health Services",
        subunits: [
          { code: "411", name: "Social security number" },
          { code: "412", name: "Covid-19 & vaccines info" },
          { code: "413", name: "Accessing hospitals and doctors" },
          { code: "414", name: "Information regarding the health system" },
        ],
      },
      {
        code: "420",
        name: "Psychological support",
        subunits: [
          {
            code: "421",
            name: "Accessing psychological support & recognising the need for psychological support & counselling",
          },
          { code: "422", name: "Costs and information" },
          {
            code: "423",
            name: "Removing the stigma to the need of visiting/consistent visiting",
          },
          { code: "424", name: "ccessing help in case of emergencies" },
        ],
      },
    ],
  },
];

export const swedishCat = [
  {
    code: "100",
    name: "Kommunikation och språkkunskaper",
    bgColor: "bg-red-700",
    description: (
      <div>
        <p>
          Kommunikations- och språkkunskaper är avgörande för att TCN (Third
          Country Nationals), migranter, erkända flyktingar och asylsökande ska
          lyckas i sina nya värdländer. Förmågan att kommunicera effektivt på
          värdlandets språk kan underlätta integrationen, öka anställbarheten
          och förbättra de sociala kontakterna. Språkbarriärer kan dock vara ett
          betydande hinder för tillgång till tjänster och möjligheter, vilket
          leder till social utslagning och isolering. Att utveckla
          språkkunskaper kan vara ett viktigt steg för att övervinna dessa
          hinder och bygga ett framgångsrikt nytt liv i ett nytt land. Dessutom
          är kulturell medvetenhet och lyhördhet också viktiga komponenter för
          effektiv kommunikation i ett nytt kulturellt sammanhang.
        </p>
      </div>
    ),
    units: [
      {
        code: "110",
        name: "Kommunikation med lokala myndigheter",
        subunits: [
          { code: "111", name: "Interagera med myndigheter" },
          {
            code: "112",
            name: "Kulturell, situations- och interaktionsrelaterad medvetenhet och förväntningar",
          },
          { code: "113", name: "Regler och bestämmelser" },
          { code: "114", name: "Dos och Don'ts" },
          { code: "115", name: "Kulturella aspekter" },
          { code: "116", name: "Tillgång till hjälp i nödsituationer" },
        ],
      },
      {
        code: "120",
        name: "Språkliga behov i vardagen",
        subunits: [
          {
            code: "121",
            name: "Daglig kommunikation (grundläggande ord på det lokala språket och engelska)",
          },
          {
            code: "122",
            name: "Information om möjligheter till språkinlärning",
          },
          {
            code: "123",
            name: "Kulturell, situations- och interaktionsrelaterad medvetenhet och förväntningar",
          },
          {
            code: "124",
            name: "Besöka snabbköp, skola, läkare och sjukhus, arbetsförmedling, söka arbete, bostad, transport ",
          },
        ],
      },
    ],
  },
  {
    code: "200",
    name: "Sysselsättning",
    bgColor: "bg-blue-700",
    description: (
      <div>
        <p>
          Sysselsättning är en avgörande aspekt av integrationsprocessen för TCN
          (Third Country Nationals), migranter, erkända flyktingar och
          asylsökande. Tillgång till ett stabilt och anständigt arbete kan ge
          ekonomisk stabilitet, social integration och en känsla av tillhörighet
          i deras nya värdland. Sysselsättningsmöjligheterna för dessa grupper
          kan dock vara begränsade på grund av deras status, språkbarriärer,
          diskriminering, brist på erkännande av utländska kvalifikationer och
          andra hinder. Regeringar och organisationer kan spela en viktig roll
          för att hantera dessa utmaningar genom att tillhandahålla
          språkutbildning, underlätta erkännande av färdigheter, erbjuda
          arbetsförmedlingstjänster och främja mångfald och inkludering på
          arbetsplatsen. Dessutom kan arbetsgivare dra nytta av de olika
          färdigheter, perspektiv och erfarenheter som dessa grupper för med sig
          till arbetskraften.
        </p>
      </div>
    ),
    units: [
      {
        code: "210",
        name: "Socialförsäkring",
        subunits: [
          {
            code: "211",
            name: "Ytterligare information om socialförsäkringstjänster",
          },
          {
            code: "212",
            name: "Skydd mot olika risker: Medvetenhet om och utnyttjande av hälsa och säkerhet",
          },
        ],
      },
      {
        code: "220",
        name: "Arbete/sysselsättning",
        subunits: [
          {
            code: "221",
            name: "Ytterligare information om arbetsmarknaden i landet (t.ex. hur de arbetar)",
          },
          {
            code: "222",
            name: "Att vara medveten om exploaterande metoder (t.ex. sexuella trakasserier)",
          },
          { code: "223", name: "Övervinna fördomar och förutfattade meningar" },
          { code: "224", name: "Att söka arbete" },
          { code: "225", name: "Arbetskultur i mottagarlandet" },
          {
            code: "226",
            name: "Löneintervall (beroende på kompetens, erfarenhet, utbildning) & vad du kan förvänta dig",
          },
        ],
      },
    ],
  },
  {
    code: "300",
    name: "Bostäder",
    bgColor: "bg-yellow-600",
    description: (
      <div>
        <p>
          Tillgång till lämpliga bostäder och boende är en grundläggande
          mänsklig rättighet som är avgörande för hälsa, säkerhet och
          välbefinnande för TCNs (Third Country Nationals), migranter, erkända
          flyktingar och asylsökande. Bostäder spelar en avgörande roll för att
          skapa en stabil och säker grund för integrationen av dessa grupper i
          deras nya värdländer. Många står dock inför betydande utmaningar när
          det gäller att få tillgång till lämpliga bostäder, t.ex. behörighet på
          grund av status, ekonomiska begränsningar, diskriminering och
          språkbarriärer. Regeringar och organisationer kan ta itu med dessa
          problem genom att tillhandahålla prisvärda bostadsalternativ. Dessutom
          kan samhällsbaserade initiativ, såsom sponsring och välkomstprogram
          för flyktingar, i förekommande fall, bidra till att skapa välkomnande
          och inkluderande grannskap för nyanlända.
        </p>
      </div>
    ),
    units: [
      {
        code: "310",
        name: "Bostäder och boende",
        subunits: [
          { code: "311", name: "Förväntningar avseende egenskaper" },
          { code: "312", name: "Räkningar, hyra, hushållsel" },
          {
            code: "313",
            name: "Tillgång till bostäder/kommunala områden/sociala bostäder",
          },
          {
            code: "314",
            name: "Övervinna fördomar och förutfattade meningar ",
          },
        ],
      },
      {
        code: "320",
        name: "Finansiella förfaranden",
        subunits: [
          {
            code: "321",
            name: "Hur banker fungerar och ingen bankverksamhet (t.ex. Revolut)",
          },
          { code: "322", name: "Öppnande av bankkonton" },
          {
            code: "323",
            name: "Vart man kan vända sig för stöd och vägledning",
          },
          { code: "324", name: "Berättigande till lån" },
          {
            code: "325",
            name: "Fördelar (finansiell kompetens, administration)",
          },
          { code: "326", name: "Finansiell registrering i bosättningslandet" },
        ],
      },
      {
        code: "330",
        name: "Enhet 3 Pedagogisk tillgång/steg",
        subunits: [
          { code: "331", name: "Validering av studier/examina/betyg etc." },
          {
            code: "332",
            name: "Söker ytterligare information (t.ex. inskrivning, kriterier, inskrivning av barn i utbildningssystemet)",
          },
          { code: "333", name: "Tillgång till rådgivning (t.ex. rådgivare)" },
        ],
      },
      {
        code: "340",
        name: "Socialisering/samhällsbyggande",
        subunits: [
          {
            code: "341",
            name: "Identifiera samhällsevenemang/sammankomster med människor från samma/liknande kulturer",
          },
        ],
      },
    ],
  },
  {
    code: "400",
    name: "Hälsa",
    bgColor: "bg-green-700",
    description: (
      <div>
        <p>
          Tillgång till sjukvård är en avgörande aspekt av
          vidarebosättningsprocessen för TCN (Third Country Nationals),
          migranter, erkända flyktingar och asylsökande. Dessa grupper kan möta
          unika utmaningar inom hälso- och sjukvården på grund av - tyvärr -
          deras status och behörighet, språkbarriärer, kulturella skillnader och
          bristande kännedom om hälso- och sjukvårdssystemet i deras nya
          värdland. Dessutom kan vissa ha upplevt trauma, våld och hälsoproblem
          i samband med sin migrationsresa. Regeringar och organisationer kan ta
          itu med dessa utmaningar genom att ge tillgång till omfattande hälso-
          och sjukvårdstjänster, inklusive stöd för psykisk hälsa och
          traumainformerad vård. Genom att säkerställa kulturellt anpassade och
          tillgängliga hälso- och sjukvårdstjänster kan man förbättra den
          fysiska och psykiska hälsan hos dessa grupper och underlätta deras
          integration i sina nya samhällen.
        </p>
      </div>
    ),
    units: [
      {
        code: "410",
        name: "Hälso- och sjukvård",
        subunits: [
          { code: "411", name: "Personnummer" },
          { code: "412", name: "Information om covid-19 och vacciner" },
          { code: "413", name: "Tillgång till sjukhus och läkare" },
          { code: "414", name: "Information om hälso- och sjukvårdssystemet" },
        ],
      },
      {
        code: "420",
        name: "Psykologiskt stöd",
        subunits: [
          {
            code: "421",
            name: "Få tillgång till psykologiskt stöd och inse behovet av psykologiskt stöd och rådgivning",
          },
          { code: "422", name: "Kostnader och information" },
          {
            code: "423",
            name: "Ta bort stigmatiseringen av behovet av besök/konsekventa besök",
          },
          { code: "424", name: "Tillgång till hjälp i nödsituationer" },
        ],
      },
    ],
  },
];

export const turkishCat = [
  {
    code: "100",
    name: "İletişim-Dil becerileri",
    bgColor: "bg-red-700",
    description: (
      <div>
        <p>
          İletişim ve dil becerileri, TCN'lerin (Üçüncü Ülke Vatandaşları),
          göçmenlerin, tanınmış mültecilerin ve sığınmacıların yeni ev sahibi
          ülkelerinde başarılı olmaları için çok önemlidir. Ev sahibi ülkenin
          dilinde etkili iletişim kurma becerisi entegrasyonu kolaylaştırabilir,
          istihdam edilebilirliği artırabilir ve sosyal bağlantıları
          geliştirebilir. Ancak, dil engelleri hizmetlere ve fırsatlara erişimin
          önünde önemli bir engel oluşturarak sosyal dışlanma ve izolasyona yol
          açabilir. Dil becerilerini geliştirmek, bu engellerin üstesinden
          gelmede ve yeni bir ülkede başarılı bir yeni yaşam kurmada önemli bir
          adım olabilir. Ayrıca, kültürel farkındalık ve duyarlılık da yeni bir
          kültürel bağlamda etkili iletişimin önemli bileşenleridir.
        </p>
      </div>
    ),
    units: [
      {
        code: "110",
        name: "Yerel makamlarla iletişim",
        subunits: [
          { code: "111", name: "Yetkililerle etkileşim" },
          {
            code: "112",
            name: "Kültürel, durumsal ve etkileşimsel farkındalık ve beklentiler",
          },
          { code: "113", name: "Kurallar ve Yönetmelikler" },
          { code: "114", name: "Yapılması ve Yapılmaması Gerekenler" },
          { code: "115", name: "Kültürel yönler" },
          { code: "116", name: "Acil durumlarda yardıma erişim" },
        ],
      },
      {
        code: "120",
        name: "Günlük dil ihtiyaçları",
        subunits: [
          {
            code: "121",
            name: "Günlük iletişim (yerel dilde ve İngilizce'de temel kelimeler)",
          },
          { code: "122", name: "Dil öğrenimi olanakları hakkında bilgi" },
          {
            code: "123",
            name: "Kültürel, durumsal ve etkileşimsel farkındalık ve beklentiler",
          },
          {
            code: "124",
            name: "Süpermarket, okul, doktor ve hastane ziyaretleri, işçi servisi, iş arama, barınma, ulaşım",
          },
        ],
      },
    ],
  },
  {
    code: "200",
    name: "İstihdam",
    bgColor: "bg-blue-700",
    description: (
      <div>
        <p>
          İstihdam, TCN'ler (Üçüncü Ülke Vatandaşları), göçmenler, tanınmış
          mülteciler ve sığınmacılar için entegrasyon sürecinin çok önemli bir
          yönüdür. İstikrarlı ve insana yakışır bir işe erişim, yeni ev sahibi
          ülkelerinde finansal istikrar, sosyal içerme ve aidiyet duygusu
          sağlayabilir. Ancak bu gruplar için istihdam fırsatları, statülerine
          uygunluk, dil engelleri, ayrımcılık, yabancı niteliklerin tanınmaması
          ve diğer engeller nedeniyle sınırlı olabilir. Hükümetler ve kuruluşlar
          dil eğitimi vererek, becerilerin tanınmasını kolaylaştırarak, işe
          yerleştirme hizmetleri sunarak ve işyerinde çeşitliliği ve
          kapsayıcılığı teşvik ederek bu zorlukların ele alınmasında önemli bir
          rol oynayabilir. Ayrıca, işverenler de bu grupların işgücüne kattığı
          farklı beceriler, bakış açıları ve deneyimlerden faydalanabilir.
        </p>
      </div>
    ),
    units: [
      {
        code: "210",
        name: "Sosyal Sigorta Hizmetleri",
        subunits: [
          {
            code: "211",
            name: "Sosyal sigorta hizmetleri hakkında daha fazla bilgi",
          },
          {
            code: "212",
            name: "Çeşitli risklere karşı koruma: Sağlık ve güvenlik bilinci ve istismar",
          },
        ],
      },
      {
        code: "220",
        name: "İşgücü/İstihdam erişimi",
        subunits: [
          {
            code: "221",
            name: "Ülkenin işgücü piyasası hakkında daha fazla bilgi (örn. Nasıl çalıştıkları)",
          },
          {
            code: "222",
            name: "Sömürücü uygulamaların farkında olmak (örneğin cinsel taciz)",
          },
          {
            code: "223",
            name: "Önyargıların ve önyargıların üstesinden gelmek",
          },
          { code: "224", name: "İş aramak" },
          { code: "225", name: "Ev sahibi ülkenin çalışma kültürü" },
          {
            code: "226",
            name: "Maaş aralıkları (becerilere, deneyime, eğitime bağlı olarak) & ne beklemeli",
          },
        ],
      },
    ],
  },
  {
    code: "300",
    name: "Yerleşme",
    bgColor: "bg-yellow-600",
    description: (
      <div>
        <p>
          Uygun konut ve barınmaya erişim, TCN'lerin (Üçüncü Ülke Vatandaşları),
          göçmenlerin, tanınmış mültecilerin ve sığınmacıların sağlık, güvenlik
          ve refahı için gerekli olan temel bir insan hakkıdır. Barınma, bu
          grupların yeni ev sahibi ülkelerine entegrasyonu için istikrarlı ve
          güvenli bir temel sağlamada çok önemli bir rol oynamaktadır. Ancak,
          birçoğu yeterli konuta erişimde statüden kaynaklanan uygunluk, mali
          kısıtlamalar, ayrımcılık ve dil engelleri gibi önemli zorluklarla
          karşılaşmaktadır. Hükümetler ve kuruluşlar uygun fiyatlı konut
          seçenekleri sunarak bu sorunları çözebilir. Ayrıca, varsa
          sponsorluklar ve mülteci karşılama programları gibi toplum temelli
          girişimler, yeni gelenler için sıcak ve kapsayıcı mahalleler
          oluşturulmasına yardımcı olabilir.
        </p>
      </div>
    ),
    units: [
      {
        code: "310",
        name: "Barınma ve Konaklama",
        subunits: [
          { code: "311", name: "Mülklere ilişkin beklentiler" },
          { code: "312", name: "Faturalar, kira, kamu hizmetleri" },
          {
            code: "313",
            name: "Konutlara/toplumsal alanlara/sosyal konutlara erişim",
          },
          {
            code: "314",
            name: "Önyargıların ve önyargıların üstesinden gelmek",
          },
        ],
      },
      {
        code: "320",
        name: "Mali prosedürler",
        subunits: [
          {
            code: "321",
            name: "Bankalar nasıl çalışır ve online bankacılık (örneğin revolut)",
          },
          { code: "322", name: "Banka hesabı açma" },
          { code: "323", name: "Destek ve rehberlik için nereye başvurulmalı" },
          { code: "324", name: "Kredi uygunluğu" },
          { code: "325", name: "Faydalar (finansal okuryazarlık, yönetim)" },
          { code: "326", name: "İkamet edilen ülkede mali kayıt" },
        ],
      },
      {
        code: "330",
        name: "Eğitim erişimi/adımları",
        subunits: [
          {
            code: "331",
            name: "Çalışmaların/diplomaların/derecelerin vb. onaylanması",
          },
          {
            code: "332",
            name: "Daha fazla bilgi aramak (örneğin, kayıt, kriterler, çocukların eğitim sistemine kaydı)",
          },
          { code: "333", name: "Tavsiyeye erişim (örn. Danışmanlar)" },
        ],
      },
      {
        code: "340",
        name: "Sosyalleşme/topluluk oluşturma",
        subunits: [
          {
            code: "341",
            name: "Aynı/benzer kültürlerden insanlarla topluluk etkinliklerini/toplantılarını belirleme",
          },
        ],
      },
    ],
  },
  {
    code: "400",
    name: "Sağlık",
    bgColor: "bg-green-700",
    description: (
      <div>
        <p>
          Sağlık hizmetlerine erişim, TCN'ler (Üçüncü Ülke Vatandaşları),
          göçmenler, tanınmış mülteciler ve sığınmacılar için yeniden yerleşim
          sürecinin çok önemli bir yönüdür. Bu gruplar -ne yazık ki- statüleri
          ve uygunlukları, dil engelleri, kültürel farklılıklar ve yeni ev
          sahibi ülkelerindeki sağlık sistemine aşina olmamaları nedeniyle
          sağlık hizmetlerinde benzersiz zorluklarla karşılaşabilirler. Ayrıca,
          bazıları göç yolculuklarıyla ilgili travma, şiddet ve sağlık sorunları
          yaşamış olabilir. Hükümetler ve kuruluşlar, ruh sağlığı desteği ve
          travma bilgilendirmeli bakım da dahil olmak üzere kapsamlı sağlık
          hizmetlerine erişim sağlayarak bu zorlukların üstesinden gelebilir.
          Kültürel açıdan uygun ve erişilebilir sağlık hizmetlerinin sağlanması,
          bu grupların fiziksel ve ruhsal sağlık sonuçlarını iyileştirebilir ve
          yeni toplumlarına entegrasyonlarını kolaylaştırabilir.
        </p>
      </div>
    ),
    units: [
      {
        code: "410",
        name: "Sağlık Hizmetleri ",
        subunits: [
          { code: "411", name: "Sosyal güvenlik numarası" },
          { code: "412", name: "Covid-19 ve aşı bilgileri" },
          { code: "413", name: "Hastanelere ve doktorlara erişim" },
          { code: "414", name: "Sağlık sistemine ilişkin bilgiler" },
        ],
      },
      {
        code: "420",
        name: "Psikolojik Destek",
        subunits: [
          {
            code: "421",
            name: "Psikolojik desteğe erişim ve psikolojik destek ve danışmanlık ihtiyacının farkına varma",
          },
          { code: "422", name: "Maliyetler ve bilgiler" },
          {
            code: "423",
            name: "Ziyaret ihtiyacı/sürekli ziyaret damgasının kaldırılması",
          },
          { code: "424", name: "Acil durumlarda yardıma erişim" },
        ],
      },
    ],
  },
];

export const lithuaniaCat = [
  {
    code: "100",
    name: "Komunikacijos ir kalbos įgūdžiai",
    bgColor: "bg-red-700",
    description: (
      <div>
        <p>
          Trečiųjų šalių piliečių, migrantų, pripažintų pabėgėlių ir
          prieglobsčio prašytojų kalbos įgūdžiai yra labai svarbūs, kad būtų
          galima sėkmingai dirbti juos priimančiose šalyse. Gebėjimas
          veiksmingai bendrauti priimančiosios šalies kalba gali palengvinti
          integraciją, padidinti įsidarbinimo galimybes ir pagerinti socialinius
          ryšius. Kalbos barjeras yra didelė kliūtis naudojantis paslaugomis ir
          priimančios valstybės teikiamomis galimybėmis, o tai lemia socialinę
          atskirtį ir izoliaciją. Kalbos įgūdžių ugdymas gali būti pagrindinis
          žingsnis įveikiant šias kliūtis ir kuriant gyvenimą naujoje šalyje.
          Kultūrinis sąmoningumas ir jautrumas taip pat yra svarbūs efektyvaus
          bendravimo naujoje kultūrinėje aplinkoje komponentai.
        </p>
      </div>
    ),
    units: [
      {
        code: "110",
        name: "Bendravimas su vietinėmis  institucijomis",
        subunits: [
          { code: "111", name: "Bendravimas su valstybinėmis įstaigomis" },
          {
            code: "112",
            name: "Kultūriniai ir vietiniai elgesio aspektai, situacijos bei lūkesčiai",
          },
          { code: "113", name: "Taisyklės ir tvarkos" },
          { code: "114", name: "Kaip elgtis ir ko nedaryti" },
          { code: "115", name: "Kultūriniai aspektai" },
          { code: "116", name: "Pagalba nelaimės atveju" },
        ],
      },
      {
        code: "120",
        name: "Kasdieniai kalbos poreikiai",
        subunits: [
          {
            code: "121",
            name: "Kasdienis bendravimas (pagrindiniai žodžiai vietine kalba ir anglų kalba)",
          },
          { code: "122", name: "Informacija apie kalbos mokymąsi" },
          {
            code: "123",
            name: "Kultūriniai ir vietiniai elgesio aspektai, situacijos bei lūkesčiai",
          },
          {
            code: "124",
            name: "Lankymasis prekybos centruose, mokyklose, pas gydytojus ir ligoninėse, Užimtumo tarnyboje, darbo paieška, būstas, transportas.",
          },
        ],
      },
    ],
  },
  {
    code: "200",
    name: "Įdarbinimas",
    bgColor: "bg-blue-700",
    description: (
      <div>
        <p>
          Užimtumas yra labai svarbus trečiųjų šalių piliečių, migrantų,
          pripažintų pabėgėlių ir prieglobsčio prašytojų integracijos proceso
          aspektas. Galimybė dirbti gali užtikrinti finansinį stabilumą,
          socialinę įtrauktį ir priklausymo jausmą priimančiojoje šalyje. Tačiau
          skirtingų migrantų grupių įsidarbinimo galimybės gali būti ribotos dėl
          jų statuso tinkamumo, kalbos barjerų, diskriminacijos, užsienyje
          įgytos kvalifikacijos nepripažinimo ir kitų kliūčių. Vyriausybės ir
          organizacijos gali atlikti svarbų vaidmenį sprendžiant šias problemas,
          organizuojant kalbų mokymus, palengvindant kvalifikacijos pripažinimą,
          siūlant įdarbinimo paslaugas ir skatinant įvairovę bei įtrauktį darbo
          vietoje. Vietos darbdaviams gali būti naudingi įgūdžiai ir patirtis,
          kuriuos šios grupės įneša į darbo jėgą.
        </p>
      </div>
    ),
    units: [
      {
        code: "210",
        name: "Socialinis draudimas",
        subunits: [
          { code: "211", name: "Informacija apie socialinį draudimą" },
          {
            code: "212",
            name: "Kaip apsisaugoti nuo galimų rizikų: sveikata, darbo sauga ir išnaudojimas",
          },
        ],
      },
      {
        code: "220",
        name: "Darbas",
        subunits: [
          {
            code: "221",
            name: "Papildoma informacija apie šalies darbo rinkos ypatumus",
          },
          { code: "222", name: "Ką daryti išnaudojimo atvejais" },
          { code: "223", name: "Šališkumo ir išankstinių nuostatų įveikimas" },
          { code: "224", name: "Ieškant darbo" },
          { code: "225", name: "Darbo kultūra priimančioje šalyje" },
          {
            code: "226",
            name: "Atlyginimų dydžiai (priklausomai nuo įgūdžių, patirties ir išsilavinimo)",
          },
        ],
      },
    ],
  },
  {
    code: "300",
    name: "Įsikūrimas",
    bgColor: "bg-yellow-600",
    description: (
      <div>
        <p>
          Galimybė gauti tinkamą būstą ir apgyvendinimą yra pagrindinė žmogaus
          teisė, kuri yra labai svarbi trečiųjų šalių piliečių, migrantų,
          pripažintų pabėgėlių ir prieglobsčio prašytojų sveikatai, saugumui ir
          gerovei. Būstas atlieka lemiamą vaidmenį užtikrinant stabilų ir saugų
          pagrindą šių grupių integracijai naujose šalyse. Tačiau daugelis jų
          susiduria su dideliais sunkumais siekdami gauti tinkamą būstą,
          pavyzdžiui, dėl jų migranto statuso, finansinių apribojimų,
          diskriminacijos ir kalbos barjerų. Vyriausybės ir organizacijos gali
          padėti spręsti šias problemas suteikdamos prieinamo būsto galimybes.
          Be to, bendruomenės iniciatyvos, pavyzdžiui, rėmimas ir pabėgėlių
          priėmimo programos, jei jos taikomos, gali padėti kurti svetingus ir
          įtraukius rajonus atvykėliams.
        </p>
      </div>
    ),
    units: [
      {
        code: "310",
        name: "Būstas (ir apgyvendinimas)",
        subunits: [
          {
            code: "311",
            name: "Lūkesčiai, susiję su nuosavybe/nekilnojamu turtu",
          },
          { code: "312", name: "Sąskaitos, nuoma, komunaliniai mokesčiai" },
          {
            code: "313",
            name: "Galimybė gauti būstą/bendro naudojimo patalpas/socialinį būstą",
          },
          { code: "314", name: "Šališkumo, išankstinių nuostatų adresavimas" },
        ],
      },
      {
        code: "320",
        name: "Finansinės procedūros",
        subunits: [
          {
            code: "321",
            name: "Kaip veikia bankai; kokios yra alternatyvos (pvz., revolut)",
          },
          { code: "322", name: "Banko sąskaitų atidarymas" },
          { code: "323", name: "Kur kreiptis pagalbos; konsultacijos" },
          { code: "324", name: "Reikalavimai paskolai gauti" },
          {
            code: "325",
            name: "Išmokos (finansinis raštingumas, administravimas)",
          },
          { code: "326", name: "Mokesčiai ir rezidavimas šalyje" },
        ],
      },
      {
        code: "330",
        name: "Švietimo prieinamumas ir (arba) pakopos",
        subunits: [
          { code: "331", name: "Studijų/diplomų/laipsnių pripažinimas" },
          {
            code: "332",
            name: "Kur gauti daugiau informacijos (pvz., registracija mokymo įstaigose, kriterijai, vaikų priėmimas į švietimo sistemą)",
          },
          {
            code: "333",
            name: "Galimybė gauti konsultacijas (pvz., konsultantų)",
          },
        ],
      },
      {
        code: "340",
        name: "Socializacija ir bendruomenės kūrimas",
        subunits: [
          {
            code: "341",
            name: "Vietos bendruomenės, renginiai ir susibūrimai",
          },
        ],
      },
    ],
  },
  {
    code: "400",
    name: "Sveikata",
    bgColor: "bg-green-700",
    description: (
      <div>
        <p>
          Galimybė gauti sveikatos priežiūros paslaugas yra labai svarbus
          trečiųjų šalių piliečių, migrantų, pripažintų pabėgėlių ir
          prieglobsčio prašytojų persikėlimo proceso aspektas. Šios grupės gali
          susidurti su išskirtiniais sveikatos priežiūros sunkumais dėl savo
          statuso ir teisių, kalbos barjero, kultūrinių skirtumų ir sveikatos
          priežiūros sistemos nesupratimo priimančiojoje šalyje. Be to, kai
          kurie jų gali būti patyrę traumų, smurto migracijos kelionėje.
          Vyriausybės ir organizacijos gali spręsti šias problemas suteikdamos
          galimybę naudotis visapusiškomis sveikatos priežiūros paslaugomis,
          įskaitant psichikos sveikatos pagalbą ir į traumą orientuotą
          priežiūrą. Užtikrinus kultūriškai tinkamas ir prieinamas sveikatos
          priežiūros paslaugas, galima pagerinti šių grupių fizinės ir psichinės
          sveikatos būklę ir palengvinti jų integraciją į naujas bendruomenes.{" "}
        </p>
      </div>
    ),
    units: [
      {
        code: "410",
        name: "Sveikatos priežiūros paslaugos",
        subunits: [
          { code: "411", name: "Socialinio draudimo numeris" },
          { code: "412", name: "Covid-19 ir vakcinos informacija" },
          { code: "413", name: "Galimybė kreiptis į ligonines ir gydytojus" },
          { code: "414", name: "Informacija apie sveikatos sistemą" },
        ],
      },
      {
        code: "420",
        name: "Psichologinė pagalba",
        subunits: [
          {
            code: "421",
            name: "Psichologinės pagalbos gavimas ir psichologinės pagalbos bei konsultacijų poreikio pripažinimas",
          },
          {
            code: "422",
            name: "Kainos ir kita informacija (pvz., nemokamai paslaugas teikiančių specialistų sąrašas)",
          },
          {
            code: "423",
            name: "Stigmos, susijusios su psichologinės pagalbos poreikiu (stebėsena, progresas, vertinimas).",
          },
          { code: "424", name: "Pagalbos gavimas kritiniais atvejais" },
        ],
      },
    ],
  },
];

export const italyCat = [
  {
    code: "100",
    name: "Comunicazione - Competenze Linguistiche",
    bgColor: "bg-red-700",
    description: (
      <div>
        <p>
          Le competenze comunicative e linguistiche sono essenziali per i
          cittadini di paesi terzi (CTN), migranti, rifugiati riconosciuti e
          richiedenti asilo affinché possano avere successo nei loro nuovi paesi
          ospitanti. La capacità di comunicare efficacemente nella lingua del
          paese ospitante può facilitare l'integrazione, aumentare
          l'occupabilità e migliorare le connessioni sociali. Tuttavia, le
          barriere linguistiche possono rappresentare un ostacolo significativo
          nell'accesso ai servizi e alle opportunità, portando all'esclusione
          sociale e all'isolamento. Lo sviluppo delle competenze linguistiche
          può rappresentare un passo fondamentale per superare tali barriere e
          costruire una nuova vita soddisfacente in un nuovo paese. La
          consapevolezza culturale e la sensibilità sono fondamentali per una
          comunicazione efficace in un nuovo contesto culturale.
        </p>
      </div>
    ),
    units: [
      {
        code: "110",
        name: "Comunicare con le autorità locali",
        subunits: [
          { code: "111", name: "Interagire con le autorità" },
          {
            code: "112",
            name: "Consapevolezza culturale, situazionale, relazionale e aspettative",
          },
          { code: "113", name: "Norme e regolamenti" },
          { code: "114", name: "Cosa fare e cosa non fare" },
          { code: "115", name: "Aspetti culturali" },
          { code: "116", name: "Chiedere aiuto in caso di emergenza" },
        ],
      },
      {
        code: "120",
        name: "Bisogni linguistici di base",
        subunits: [
          {
            code: "121",
            name: "Comunicazione quotidiana (Lessico base in italiano e inglese)",
          },
          { code: "122", name: "Informazioni su come imparare la lingua" },
          {
            code: "123",
            name: "Consapevolezza culturale, situazionale, relazionale e aspettative",
          },
          {
            code: "124",
            name: "Andare al supermercato, a scuola, dal dottore e all'ospedale, cercare lavoro, casa e spostarsi",
          },
        ],
      },
    ],
  },
  {
    code: "200",
    name: "Lavorare",
    bgColor: "bg-blue-700",
    description: (
      <div>
        <p>
          L'occupazione è un aspetto cruciale del processo di integrazione per i
          TCN (Cittadini di Paesi Terzi), migranti, rifugiati riconosciuti e
          richiedenti asilo. L'accesso a un lavoro stabile e decente può fornire
          stabilità finanziaria, inclusione sociale e un senso di appartenenza
          nel nuovo paese ospitante. Tuttavia, le opportunità di lavoro per
          questi gruppi possono essere limitate a causa delle condizioni di
          ammissibilità del loro status, delle barriere linguistiche, della
          discriminazione, della mancanza di riconoscimento delle qualifiche
          straniere e di altri ostacoli. I governi e le organizzazioni possono
          svolgere un ruolo importante nell'affrontare queste sfide fornendo
          formazione linguistica, facilitando il riconoscimento delle
          competenze, offrendo servizi di collocamento e promuovendo la
          diversità e l'inclusione sul posto di lavoro. Inoltre, i datori di
          lavoro possono trarre vantaggio dalle diverse competenze, prospettive
          ed esperienze che questi gruppi apportano alla forza lavoro.
        </p>
      </div>
    ),
    units: [
      {
        code: "210",
        name: "Servizi di Assistenza Sociale",
        subunits: [
          { code: "211", name: "Informazioni sui servizi di assistenza" },
          {
            code: "212",
            name: "Protezione contro i rischi: consapevolezza della salute e sicurezza e sftruttamento",
          },
        ],
      },
      {
        code: "220",
        name: "Lavoro/Accesso all'impego",
        subunits: [
          {
            code: "221",
            name: "Informazioni sul mercato del lavoro nel Paese (es. come funziona)",
          },
          {
            code: "222",
            name: "Conoscere le pratiche di sfruttamento (es. molestie e mobbing)",
          },
          { code: "223", name: "Superare gli stereotipi e pregiudizi" },
          { code: "224", name: "Cercare lavoro" },
          {
            code: "225",
            name: "Approccio al lavoro del Paese di destinazione",
          },
          {
            code: "226",
            name: "Livelli salariali (incidenza di competenze, esperienze, educazione) e cosa aspettarsi",
          },
        ],
      },
    ],
  },
  {
    code: "300",
    name: "Abitare",
    bgColor: "bg-yellow-600",
    description: (
      <div>
        <p>
          Access to suitable housing and accommodation is a fundamental human
          right that is essential for the health, safety, and wellbeing of TCNs
          (Third Country Nationals), migrants, recognised refugees, and asylum
          seekers. Housing plays a crucial role in providing a stable and secure
          foundation for the integration of these groups into their new host
          countries. However, many face significant challenges in accessing
          adequate housing, such as eligibility due to status, financial
          constraints, discrimination, and language barriers. Governments and
          organizations may be able to address these issues by providing
          affordable housing options. Additionally, community-based initiatives,
          such as sponsorships and refugee welcome programs, if applicable, can
          help to create welcoming and inclusive neighbourhoods for newcomers.
        </p>
      </div>
    ),
    units: [
      {
        code: "310",
        name: "Alloggi e accoglienza",
        subunits: [
          { code: "311", name: "Aspettative riguardanti gli immobili" },
          { code: "312", name: "Bollette, affitto, spese generali" },
          { code: "313", name: "Accesso agli alloggi/edilizia popolare" },
          { code: "314", name: "Superare Stereotipi e Pregiudizi" },
        ],
      },
      {
        code: "320",
        name: "Gestione finanziaria",
        subunits: [
          { code: "321", name: "Come funziona il sistema bancario" },
          { code: "322", name: "Aprire un conto in banca" },
          { code: "323", name: "Servizi di supporto" },
          { code: "324", name: "Ottenere un prestito/finanziamento" },
          { code: "325", name: "Sgravi fiscali" },
          { code: "326", name: "Registrazione fiscale" },
        ],
      },
      {
        code: "330",
        name: "Accesso all’istruzione",
        subunits: [
          { code: "331", name: "Riconoscere titoli e competenze" },
          {
            code: "332",
            name: "Ulteriori informazioni (es. Iscrizioni, inserimento dei bambini nel sistema scolastico)",
          },
          { code: "333", name: "Supporto e consulenza" },
        ],
      },
      {
        code: "340",
        name: "Socializzare/vivere in comunità",
        subunits: [
          {
            code: "341",
            name: "Eventi sociali/contatti con persone della propria comunità d’origine",
          },
        ],
      },
    ],
  },
  {
    code: "400",
    name: "Salute",
    bgColor: "bg-green-700",
    description: (
      <div>
        <p>
          L'accesso alle cure mediche rappresenta un aspetto cruciale del
          processo di reinsediamento per i TCN (cittadini di paesi terzi),
          migranti, rifugiati riconosciuti e richiedenti asilo. Questi gruppi
          possono dover affrontare sfide specifiche in materia di assistenza
          sanitaria a causa delle condizioni di ammissibilità relative al loro
          status, delle barriere linguistiche, delle differenze culturali e
          della mancanza di familiarità con il sistema sanitario del paese
          ospitante. Inoltre, alcuni di loro possono aver subito traumi,
          violenze e problemi di salute legati al loro percorso migratorio. I
          governi e le organizzazioni possono affrontare queste sfide fornendo
          accesso a servizi sanitari completi, inclusi supporto per la salute
          mentale e terapie post traumatiche. Garantire servizi sanitari
          appropriati e accessibili può migliorare i risultati in termini di
          salute fisica e mentale di questi gruppi e facilitarne l'integrazione
          nelle nuove comunità.
        </p>
      </div>
    ),
    units: [
      {
        code: "410",
        name: "Servizi sanitari",
        subunits: [
          { code: "411", name: "Il Codice Fiscale" },
          { code: "412", name: "Informazioni su COVID-19 e vaccini" },
          { code: "413", name: "Andare in ospedale e dal medico" },
          { code: "414", name: "Informazioni sul Sistema Sanitario" },
        ],
      },
      {
        code: "420",
        name: "Supporto Psicologico",
        subunits: [
          {
            code: "421",
            name: "Accedere ai servizi di supporto psicologico e riconoscere la necessità di aiuto",
          },
          { code: "422", name: "Costi e informazioni" },
          {
            code: "423",
            name: "Superare i pregiudizi e seguire il percorso terapeutico",
          },
          { code: "424", name: "Accesso ai soccorsi in caso di emergenza" },
        ],
      },
    ],
  },
];

export const spainCat = [
  {
    code: "100",
    name: "Comunicación-Habilidades lingüísticas",
    bgColor: "bg-red-700",
    description: (
      <div>
        <p>
          Los conocimientos lingüísticos y de comunicación son esenciales para
          que los nacionales de terceros países (NTPs), las personas migrantes,
          refugiadas reconocidas y las solicitantes de asilo tengan éxito en sus
          nuevos países de acogida. La capacidad de comunicarse eficazmente en
          la lengua del país de acogida puede facilitar la integración, aumentar
          las posibilidades de empleo y mejorar las relaciones sociales. Sin
          embargo, las barreras lingüísticas pueden ser un obstáculo importante
          para acceder a servicios y oportunidades, lo que conduce a la
          exclusión social y el aislamiento. El desarrollo de las competencias
          lingüísticas puede ser un paso clave para superar estas barreras y
          construir una nueva vida con éxito en un nuevo país. Además, la
          conciencia y la sensibilidad culturales son también componentes
          importantes de una comunicación eficaz en un nuevo contexto cultural.
        </p>
      </div>
    ),
    units: [
      {
        code: "110",
        name: "Comunicación con las autoridades locales",
        subunits: [
          { code: "111", name: "Interacción con las autoridades" },
          {
            code: "112",
            name: "Conocimiento y expectativas culturales, situacionales e interaccionales",
          },
          { code: "113", name: "Normas y reglamentos" },
          { code: "114", name: "Qué hacer y qué no hacer" },
          { code: "115", name: "Aspectos culturales" },
          { code: "116", name: "Acceso a la ayuda en caso de emergencia" },
        ],
      },
      {
        code: "120",
        name: "Necesidades lingüísticas cotidianas",
        subunits: [
          {
            code: "121",
            name: "Comunicación diaria (palabras básicas en lengua local y en inglés)",
          },
          {
            code: "122",
            name: "Información sobre las posibilidades de aprendizaje del idioma",
          },
          {
            code: "123",
            name: "Conocimiento y expectativas culturales, situacionales e interaccionales",
          },
          {
            code: "124",
            name: "Visitar supermercados, escuelas, médicos y hospitales, servicios laborales, buscar empleo, vivienda, transporte",
          },
        ],
      },
    ],
  },
  {
    code: "200",
    name: "Empleo",
    bgColor: "bg-blue-700",
    description: (
      <div>
        <p>
          El empleo es un aspecto crucial del proceso de integración de las
          personas nacionales de terceros países, migrantes, refugiadas
          reconocidas y solicitantes de asilo. El acceso a un trabajo estable y
          digno puede proporcionarles estabilidad financiera, inclusión social y
          un sentimiento de pertenencia a su nuevo país de acogida. Sin embargo,
          las oportunidades de empleo para estos grupos pueden ser limitadas
          debido a la elegibilidad en relación con su estatus, las barreras
          lingüísticas, la discriminación, la falta de reconocimiento de las
          cualificaciones extranjeras y otras barreras. Los gobiernos y las
          organizaciones pueden desempeñar un papel importante a la hora de
          abordar estos retos proporcionando formación lingüística, facilitando
          el reconocimiento de las cualificaciones, ofreciendo servicios de
          colocación y promoviendo la diversidad y la inclusión en el lugar de
          trabajo. Además, los empleadores pueden beneficiarse de las diversas
          competencias, perspectivas y experiencias que estos grupos aportan a
          la mano de obra.
        </p>
      </div>
    ),
    units: [
      {
        code: "210",
        name: "Servicios de la Seguridad Social",
        subunits: [
          {
            code: "211",
            name: "Información adicional sobre los servicios de la seguridad social",
          },
          {
            code: "212",
            name: "Protección frente a diversos riesgos: Concienciación y explotación de la salud y la seguridad",
          },
        ],
      },
      {
        code: "220",
        name: "Trabajo/Acceso al empleo",
        subunits: [
          {
            code: "221",
            name: "Más información sobre el mercado laboral del país (p. ej. Cómo funcionan)",
          },
          {
            code: "222",
            name: "Ser consciente de las prácticas de explotación (por ejemplo, el acoso sexual)",
          },
          { code: "223", name: "Superar los prejuicios" },
          { code: "224", name: "Búsqueda de empleo" },
          { code: "225", name: "Cultura laboral del país de acogida" },
          {
            code: "226",
            name: "Salarios (en función de las competencias, la experiencia y la formación) y expectativas",
          },
        ],
      },
    ],
  },
  {
    code: "300",
    name: "Vivienta",
    bgColor: "bg-yellow-600",
    description: (
      <div>
        <p>
          El acceso a una vivienda y alojamiento adecuados es un derecho humano
          fundamental que resulta esencial para la salud, la seguridad y el
          bienestar de las personas nacionales de terceros países (NTP),
          migrantes, refugiadas reconocidas y solicitantes de asilo. La vivienda
          desempeña un papel crucial a la hora de proporcionar una base estable
          y segura para la integración de estos grupos en sus nuevos países de
          acogida. Sin embargo, muchos se enfrentan a importantes dificultades
          para acceder a una vivienda adecuada, como la elegibilidad debida al
          estatus, las limitaciones financieras, la discriminación y las
          barreras lingüísticas. Los gobiernos y las organizaciones pueden
          abordar estos problemas ofreciendo opciones de vivienda asequible.
          Además, las iniciativas basadas en la comunidad, como los patrocinios
          y los programas de acogida de refugiados, si procede, pueden ayudar a
          crear barrios acogedores e integradores para los recién llegados.
        </p>
      </div>
    ),
    units: [
      {
        code: "310",
        name: "Vivienda (y alojamiento)",
        subunits: [
          { code: "311", name: "Expectativas respecto de las propiedades" },
          { code: "312", name: "Facturas, alquiler, servicios" },
          {
            code: "313",
            name: "Acceso a la vivienda/zonas comunes/vivienda social",
          },
          { code: "314", name: "Superar los prejuicios" },
        ],
      },
      {
        code: "320",
        name: "Procedimientos financieros",
        subunits: [
          {
            code: "321",
            name: "Cómo operan los bancos & neobancos (p.ej. Revolut)",
          },
          { code: "322", name: "Abrir cuentas bancarias" },
          { code: "323", name: "Dónde encontrar apoyo & orientación" },
          { code: "324", name: "Elegibilidad para un préstamo" },
          {
            code: "325",
            name: "Beneficios (conocimientos financieros, administración)",
          },
          { code: "326", name: "Registro fiscal en el país de residencia" },
        ],
      },
      {
        code: "330",
        name: "Acceso a la educación/pasos",
        subunits: [
          { code: "331", name: "Convalidar estudios/diplomas/títulos, etc." },
          {
            code: "332",
            name: "Buscar información adicional (p.ej. matriculación, criterios, matriculación de los niños en el sistema educativo)",
          },
          { code: "333", name: "Acceder al asesoramiento (p.ej. consejeros)" },
        ],
      },
      {
        code: "340",
        name: "Socialización/creación de comunidad",
        subunits: [
          {
            code: "341",
            name: "Identificar los eventos comunitarios/reuniones con personas de la misma o similar cultura",
          },
        ],
      },
    ],
  },
  {
    code: "400",
    name: "Salud",
    bgColor: "bg-green-700",
    description: (
      <div>
        <p>
          El acceso a la asistencia sanitaria es un aspecto crucial del proceso
          de reasentamiento de las personas nacionales de terceros países,
          migrantes, refugiadas reconocidas y solicitantes de asilo. Estos
          grupos pueden enfrentarse a retos sanitarios únicos debido
          -desgraciadamente- a su estatus y elegibilidad, las barreras
          lingüísticas, las diferencias culturales y la falta de familiaridad
          con el sistema sanitario de su nuevo país de acogida. Además, algunos
          pueden haber sufrido traumas, violencia y problemas de salud
          relacionados con su viaje migratorio. Los gobiernos y las
          organizaciones pueden hacer frente a estos retos proporcionando acceso
          a servicios sanitarios integrales, incluido el apoyo a la salud mental
          y la atención informada sobre traumas. Garantizar unos servicios
          sanitarios culturalmente apropiados y accesibles puede mejorar la
          salud física y mental de estos grupos y facilitar su integración en
          sus nuevas comunidades.
        </p>
      </div>
    ),
    units: [
      {
        code: "410",
        name: "Servicios sanitarios",
        subunits: [
          { code: "411", name: "Número de seguridad social" },
          { code: "412", name: "Covid-19 & información sobre las vacunas" },
          { code: "413", name: "Acceso a hospitales y médicos" },
          {
            code: "414",
            name: "Información con respecto al sistema sanitario",
          },
        ],
      },
      {
        code: "420",
        name: "Apoyo psicológico",
        subunits: [
          { code: "421", name: "Acceder al apoyo psicológico & asesoramiento" },
          { code: "422", name: "Costos e información " },
          {
            code: "423",
            name: " Eliminar el estigma de la necesidad de visitas/visitas constantes",
          },
          { code: "424", name: "Acceder a la ayuda en caso de emergencias" },
        ],
      },
    ],
  },
];
