import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import DescriptionComponent from "../components/ui/DescriptionComponent";
import Accordion from "../components/ui/Accordion";
import FetchingDataAnimation from "../components/app/FetchingDataAnimation";
import { dark100, dark200, dark300, dark400 } from "../components/context/colors";
import {
  getShortDLabelByLanguage,
  getLinksLabelByLanguage,
  getExpectationsLabelByLanguage,
  getProceduresLabelByLanguage,
  getInfoLabelByLanguage,
  getDescriptionLabelByLanguage,
  getWomenLabelByLanguage,
  getLinkCopiedTextByLanguage,
} from "../components/context/labels";

import SubunitHeader from "../components/ui/SubunitHeader";
import AccordionSection from "../components/app/AccordionSection";
import SubunitInstructions from "../components/ui/SubunitInstructions";
import Button from "../components/ui/Button";

import { FaRegCopy } from "react-icons/fa";

// ------------- Start of Helper ---------------//
const backgroundColorByCode = (code) => {
  const firstDigit = code[0];
  // console.log(firstDigit);
  const colorMap = {
    1: dark100,
    2: dark200,
    3: dark300,
    4: dark400,
  };

  const selectedColor = colorMap[firstDigit];
  if (selectedColor) return `bg-${selectedColor}`;
};

// const Break = () => {
//   return <hr className="w-1/2 flex mr-auto ml-auto mb-2"></hr>;
// };
// ------------- End of Helper ---------------//

const Subunit = () => {
  const [expanded, setExpanded] = useState(false);
  const [subunitData, setSubunitData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { country, immigrantStatus, language } = useSelector((state) => state.user);
  // const location = useLocation();
  // if (location.state) {
  //   console.log(location.state);
  //   let { nameBackup, nameParentBackup, titleBackup } = location.state;
  // } else console.log("no state");
  const { id } = useParams();

  // const [openGroup, setOpenGroup] = useState(null);
  // const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);

    // Define the API endpoint with parameters
    const apiUrl = `https://dev.silversky3d.com/api_vrin/subunit/data/${id}/${country}/${language}/${immigrantStatus}`;

    // Make the API call
    setTimeout(() => {
      axios
        .get(apiUrl)
        .then((response) => {
          // Handle the API response here
          setSubunitData(response.data);
          // console.log(response.data);

          // Add a delay of 500 ms before setting isLoading to false
          setIsLoading(false);
        })
        .catch((error) => {
          // Handle any errors here
          console.error("Error fetching data:", error);
          setSubunitData({});
          // Add a delay of 500 ms before setting isLoading to false
          setIsLoading(false);
        });
    }, 100);
  }, [id, country, language, immigrantStatus]);

  // Parse and create constants for JSON attributes
  const name = subunitData.name ? subunitData.name : "";
  const parentName = subunitData.parentName ? subunitData.parentName : "";

  const title = subunitData.title ? subunitData.title : "";

  const description = subunitData.description ? JSON.parse(subunitData.description) : null;

  const shortDescription = subunitData.shortDescription ? JSON.parse(subunitData.shortDescription) : null;

  const links1 = subunitData.links1 ? JSON.parse(subunitData.links1) : null;

  const links2 = subunitData.links2 ? JSON.parse(subunitData.links2) : null;

  const links3 = subunitData.links3 ? JSON.parse(subunitData.links3) : null;

  const expectationsA = subunitData.expectationsA ? JSON.parse(subunitData.expectationsA) : null;

  const procedures = subunitData.procedures ? JSON.parse(subunitData.procedures) : null;

  const expectationsB = subunitData.expectationsB ? JSON.parse(subunitData.expectationsB) : null;

  const expectationsC = subunitData.expectationsC ? JSON.parse(subunitData.expectationsC) : null;

  const women = subunitData.women ? JSON.parse(subunitData.women) : null;

  // const bg = backgroundColorByCode(id);

  // Structure the data into groups
  const groups = [];

  if (description) {
    groups.push({
      id: 0,
      label: getDescriptionLabelByLanguage(language),
      content: (
        <div className="max-[400px]:text-xs max-[500px]:text-sm p-3">
          <DescriptionComponent descriptions={description} code={id} />
        </div>
      ),
    });
  }

  if (shortDescription || links1 || expectationsA) {
    groups.push({
      id: 1,
      label: getInfoLabelByLanguage(language),
      content: (
        <div>
          {shortDescription && (
            <AccordionSection
              component={"ShortDescription"}
              data={shortDescription}
              labelFunction={getShortDLabelByLanguage}
              language={language}
              code={id}
            />
          )}
          {links1 && (
            <>
              <AccordionSection
                component={"LinksComponent"}
                data={links1}
                labelFunction={getLinksLabelByLanguage}
                language={language}
                code={id}
              />
            </>
          )}
          {expectationsA && (
            <>
              <AccordionSection
                component={"ExpectationsComponent"}
                data={expectationsA}
                labelFunction={getExpectationsLabelByLanguage}
                language={language}
                code={id}
              />
            </>
          )}
        </div>
      ),
    });
  }

  if (procedures || links2 || expectationsB) {
    groups.push({
      id: 2,
      label: getProceduresLabelByLanguage(language),
      content: (
        <div>
          {procedures && (
            <AccordionSection
              component={"ShortDescription"}
              data={procedures}
              labelFunction={getProceduresLabelByLanguage}
              language={language}
              code={id}
            />
          )}
          {links2 && (
            <>
              <AccordionSection
                component={"LinksComponent"}
                data={links2}
                labelFunction={getLinksLabelByLanguage}
                language={language}
                code={id}
              />
            </>
          )}
          {expectationsB && (
            <>
              <AccordionSection
                component={"ExpectationsComponent"}
                data={expectationsB}
                labelFunction={getExpectationsLabelByLanguage}
                language={language}
                code={id}
              />
            </>
          )}
        </div>
      ),
    });
  }

  if (women || links3 || expectationsC) {
    groups.push({
      id: 3,
      label: getWomenLabelByLanguage(language),
      content: (
        <div>
          {women && (
            <AccordionSection
              component={"ShortDescription"}
              data={women}
              labelFunction={getWomenLabelByLanguage}
              language={language}
              code={id}
            />
          )}
          {links3 && (
            <AccordionSection
              component={"LinksComponent"}
              data={links3}
              labelFunction={getLinksLabelByLanguage}
              language={language}
              code={id}
            />
          )}
          {expectationsC && (
            <AccordionSection
              component={"ExpectationsComponent"}
              data={expectationsC}
              labelFunction={getExpectationsLabelByLanguage}
              language={language}
              code={id}
            />
          )}
        </div>
      ),
    });
  }

  // Filter out groups with no content
  const validGroups = groups.filter((group) => React.Children.toArray(group.content).some((child) => child));

  const expandTitle = () => {
    setExpanded(!expanded);
  };

  // Get the background color
  const headerBackgroundColor = backgroundColorByCode(id);

  const [showCopiedMessage, setShowCopiedMessage] = useState(false);

  const handleCopyUrlClick = () => {
    // Copy the URL to the clipboard when the button is clicked
    navigator.clipboard.writeText(window.location.href);

    // Show the "Copied!" message
    setShowCopiedMessage(true);

    // Hide the message after a delay
    setTimeout(() => {
      setShowCopiedMessage(false);
    }, 500); // You can adjust the duration as needed
  };

  return (
    <div className="flex flex-col items-center bg-gray-900">
      <div className="pb-2 bg-gray-100 overflow-hidden min-h-[90vh] w-full max-w-4xl">
        <SubunitHeader
          id={id}
          title={title}
          parentName={parentName}
          name={name}
          expanded={expanded}
          onClick={() => expandTitle()}
          backgroundColor={headerBackgroundColor}
          isLoading={isLoading}
        />

        {/* ------------------ BODY ----------------- */}
        {isLoading ? (
          <FetchingDataAnimation />
        ) : (
          <>
            <Accordion items={validGroups} isLoading={isLoading} />
          </>
        )}
        {/* ------------------ AJusted Footer ----------------- */}
        <SubunitInstructions isLoading={isLoading} language={language} />

        {/* ------------------ Copy Button ----------------- */}
        <div className="fixed lg:right-[28%] right-[5%] bottom-9 justify-center z-20 ring-transparent floatingBtn">
          <Button secondary circle onClick={handleCopyUrlClick} animated shadow>
            <div className="py-1 ">
              <FaRegCopy size={25} />
            </div>
          </Button>
        </div>
        {/* ------------------ Copied Message ----------------- */}
        <div
          className="fixed bottom-[5.5rem] lg:right-[28%] right-[5%] p-0.5 text-black bg-gray-100 rounded-xl italic"
          style={{
            opacity: showCopiedMessage ? 1 : 0,
            transform: showCopiedMessage ? "translateY(0)" : "translateY(-10px)", // Adjust the translateY value for desired movement
            transition: "opacity 0.5s ease-in-out, transform 0.5s ease-in-out",
          }}
        >
          <p className="text-center">{getLinkCopiedTextByLanguage(language)}</p>
        </div>
      </div>
    </div>
  );
};

export default Subunit;
