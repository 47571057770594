import React from "react";
import { motion } from "framer-motion";
import Backdrop from "./Backdrop";
import "../../css/modal.css";
import Button from "./Button";
import { getCloseLabelByLanguage } from "../context/labels";
import { useSelector } from "react-redux";
import { GiPartyPopper } from "react-icons/gi";
import Ripples from "react-ripples";

const modalVariants = {
  hidden: {
    y: "-100vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.2,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: "100vh",
    opacity: 0,
  },
};

const Modal2 = ({ handleClose, text }) => {
  const { language } = useSelector((state) => state.user);

  const close = () => {
    setTimeout(() => {
      handleClose();
    }, 400);
  };

  return (
    <Backdrop onClick={close}>
      <Ripples
        className="modal2 bg-gray-200 rounded-xl py-3  border border-gray-900 shadow-sm shadow-black"
        during={400}
      >
        <motion.div
          onClick={close}
          className=" z-50 "
          variants={modalVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <div className="flex flex-row justify-center items-center p-2 rounded-xl w-full">
            <GiPartyPopper size={40} color="#ad0f04" className=" mr-4" />
            <div className="max-[400px]:text-base p-0.5 max-[500px]:text-lg text-justify font-semibold">
              {text}
            </div>
            <GiPartyPopper
              size={40}
              color="#ad0f04"
              className=" ml-4 scale-x-[-1] "
            />
          </div>
          {/* <Button onClick={handleClose} danger rounded>
          {getCloseLabelByLanguage(language)}
        </Button> */}
        </motion.div>
      </Ripples>
    </Backdrop>
  );
};

export default Modal2;
