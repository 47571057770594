import className from "classnames";
import { useState } from "react";
import Ripples from "react-ripples";

function Button({
  children,
  primary,
  secondary,
  success,
  warning,
  danger,
  outline,
  rounded,
  isInvisible,
  animated,
  circle,
  shadow,
  shadowDark,
  rippleEffect,
  ...rest
}) {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(true);

    setTimeout(() => {
      animated && setClicked(false);
      rest.onClick && rest.onClick();
    }, 250); //250ms is the duration of the animation
  };

  const classes = className(
    rest.className,
    "flex items-center px-3 py-2 border font-bold overflow-hidden focus:outline-none",
    {
      "border-blue-900 bg-blue-900 text-white": primary,
      "border-gray-900 bg-gray-900 text-white": secondary,
      "border-green-900 bg-green-800 text-white": success,
      "border-yellow-400 bg-yellow-300 text-white": warning,
      "border-red-800 bg-red-800 text-white": danger,
      "rounded-xl": rounded,
      "rounded-full": circle,
      "bg-white": outline,
      "!text-blue-900": outline && primary,
      "!text-gray-900": outline && secondary,
      "!text-green-500": outline && success,
      "!text-yellow-400": outline && warning,
      "!text-red-500": outline && danger,
      "active:opacity-20": rest.activity,
      hidden: isInvisible, // Add "hidden" class if isInvisible is true
      "animate-bounce duration-1000 ease-in-out": clicked && animated,
      // "animate-ripple": clicked && animatedRipple,
      "shadow-button": shadow,
      "shadow-darkButton": shadowDark,
    }
  );

  if (rippleEffect) {
    return (
      <div className="mx-2 ">
        <Ripples color="#ffffff88" during={500} className={classes}>
          <button {...rest} onClick={handleClick}>
            {children}
          </button>
        </Ripples>
      </div>
    );
  } else {
    return (
      <button {...rest} className={classes} onClick={handleClick}>
        {children}
      </button>
    );
  }
}

Button.propTypes = {
  checkVariationValue: ({ primary, secondary, success, warning, danger }) => {
    const count =
      Number(!!primary) +
      Number(!!secondary) +
      Number(!!warning) +
      Number(!!success) +
      Number(!!danger);

    if (count > 1) {
      return new Error(
        "Only one of primary, secondary, success, warning, danger can be true"
      );
    }
  },
};

export default Button;
