import React from "react";
import { Draggable } from "react-beautiful-dnd";

import { guidingStructure } from "../context/categoryData";

const DraggableItem = ({
  category,
  index,
  data,
  isDrawerOpen,
  isDragDisabled,
}) => {
  function findObjectByCode(structure, code) {
    // Define a recursive function to search through the structure
    function search(subStructure) {
      for (let item of subStructure) {
        if (item.code === code) {
          return item;
        }
        // If the item has units, recursively search them
        if (item.units) {
          let found = search(item.units);
          if (found) {
            return found;
          }
        }
        // If the item has subunits, recursively search them
        if (item.subunits) {
          let found = search(item.subunits);
          if (found) {
            return found;
          }
        }
      }
      return null; // Return null if the code is not found
    }

    return search(structure);
  }

  function findObjectByCodeWithoutChildren(structure, code) {
    // Define a recursive function to search through the structure
    function search(subStructure) {
      for (let item of subStructure) {
        if (item.code === code) {
          // Return a shallow copy of the object without 'units' and 'subunits'
          const { units, subunits, ...itemWithoutChildren } = item;
          return itemWithoutChildren;
        }
        // If the item has units, recursively search them
        if (item.units) {
          let found = search(item.units);
          if (found) {
            return found;
          }
        }
        // If the item has subunits, recursively search them
        if (item.subunits) {
          let found = search(item.subunits);
          if (found) {
            return found;
          }
        }
      }
      return null; // Return null if the code is not found
    }

    return search(structure);
  }

  // const getCodeLevel = (code) => {
  //   if (/^\d00$/.test(code)) return 1;
  //   if (/^\d\d0$/.test(code)) return 2;
  //   return 3;
  // };

  const indentationItem = (code) => {
    let lvl = 3;

    if (/^\d\d0$/.test(code)) lvl = 2;
    if (/^\d00$/.test(code)) lvl = 1;

    switch (lvl) {
      case 1:
        return "w-[100%]";
      case 2:
        return "w-[97.5%]";
      case 3:
        return "w-[95%]";

      default:
        return "w-5/12";
    }
  };

  const styleItem = (code) => {
    let lvl = 3;

    if (/^\d\d0$/.test(code)) lvl = 2;
    if (/^\d00$/.test(code)) lvl = 1;

    switch (lvl) {
      case 1:
        return "font-bold";
      case 2:
        return "font-semibold";
      case 3:
        return " font-extralight";

      default:
        return "";
    }
  };

  const marginItem = (code) => {
    let lvl = 3;

    if (/^\d\d0$/.test(code)) lvl = 2;
    if (/^\d00$/.test(code)) lvl = 1;

    switch (lvl) {
      case 1:
        return "mb-3";
      case 2:
        return "mb-3";
      case 3:
        return " mb-2";

      default:
        return "";
    }
  };

  const renderName = (name, code) => {
    let lvl = 3;

    if (/^\d\d0$/.test(code)) lvl = 2;
    if (/^\d00$/.test(code)) lvl = 1;

    const [section, title] = name.split(": ");
    return (
      <>
        {lvl === 1 ? (
          <div className="flex flex-col ">
            <div className="font-bold">{section}:</div>
            <div>{title}</div>
          </div>
        ) : (
          <div>{name}</div>
        )}
      </>
    );
  };

  // Example usage:
  const codeToSearch = category.code;
  const foundObject = findObjectByCode(guidingStructure, codeToSearch);
  const foundObjectNoCH = findObjectByCodeWithoutChildren(
    guidingStructure,
    codeToSearch
  );
  // console.log(foundObjectNoCH);

  const addShadow = (color) => {
    return "bg-" + color;
  };

  return (
    <Draggable
      key={category.code}
      draggableId={category.code}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`cursor-grab  ml-auto 
                  ${marginItem(category.code)} 
                  ${indentationItem(category.code)} 
                  ${
                    snapshot.isDragging ? `${category.shadow} shadow-md` : ` `
                  }  `}
        >
          <div className={`flex-row flex shadow-md`}>
            {/* <div className="mr-2">{isDrawerOpen ? category.code : ""}</div> */}
            <div
              className={`w-[100%]  bg-gray-100 p-1 ${styleItem(
                category.code
              )} `}
            >
              {isDrawerOpen ? renderName(category.name, category.code) : ""}
            </div>
            <div
              className={` ${"bg-" + category.color} ${
                isDrawerOpen ? "w-[3%]" : "w-[20%]"
              } justify-end relative left-0`}
            ></div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default DraggableItem;
