export const guidingStructure = [
  {
    code: "100",
    name: "",
    bgColor: "bg-red-700",
    description: (
      <div>
        <p></p>
      </div>
    ),
    units: [
      {
        code: "110",
        name: "",
        subunits: [
          { code: "111", name: "" },
          { code: "112", name: "" },
          { code: "113", name: "" },
          { code: "114", name: "" },
          { code: "115", name: "" },
          { code: "116", name: "" },
        ],
      },
      {
        code: "120",
        name: "",
        subunits: [
          { code: "121", name: "" },
          { code: "122", name: "" },
          { code: "123", name: "" },
          { code: "124", name: "" },
        ],
      },
    ],
  },
  {
    code: "200",
    name: "",
    bgColor: "bg-blue-700",
    description: (
      <div>
        <p></p>
      </div>
    ),
    units: [
      {
        code: "210",
        name: "",
        subunits: [
          { code: "211", name: "" },
          { code: "212", name: "" },
        ],
      },
      {
        code: "220",
        name: "",
        subunits: [
          { code: "221", name: "" },
          { code: "222", name: "" },
          { code: "223", name: "" },
          { code: "224", name: "" },
          { code: "225", name: "" },
          { code: "226", name: "" },
        ],
      },
    ],
  },
  {
    code: "300",
    name: "",
    bgColor: "bg-yellow-600",
    description: (
      <div>
        <p></p>
      </div>
    ),
    units: [
      {
        code: "310",
        name: "",
        subunits: [
          { code: "311", name: "" },
          { code: "312", name: "" },
          { code: "313", name: "" },
          { code: "314", name: "" },
        ],
      },
      {
        code: "320",
        name: "",
        subunits: [
          { code: "321", name: "" },
          { code: "322", name: "" },
          { code: "323", name: "" },
          { code: "324", name: "" },
          { code: "325", name: "" },
          { code: "326", name: "" },
        ],
      },
      {
        code: "330",
        name: "",
        subunits: [
          { code: "331", name: "" },
          { code: "332", name: "" },
          { code: "333", name: "" },
        ],
      },
      {
        code: "340",
        name: "",
        subunits: [{ code: "341", name: "" }],
      },
    ],
  },
  {
    code: "400",
    name: "",
    bgColor: "bg-green-700",
    description: (
      <div>
        <p></p>
      </div>
    ),
    units: [
      {
        code: "410",
        name: "",
        subunits: [
          { code: "411", name: "" },
          { code: "412", name: "" },
          { code: "413", name: "" },
          { code: "414", name: "" },
        ],
      },
      {
        code: "420",
        name: "",
        subunits: [
          { code: "421", name: "" },
          { code: "422", name: "" },
          { code: "423", name: "" },
          { code: "424", name: "" },
        ],
      },
    ],
  },
];
