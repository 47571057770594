import React, { useEffect, useState } from "react";
import {
  getGreetingsLabelByLanguage,
  getQuizInstructionsByLanguage,
} from "../components/context/labels";
import FetchingDataAnimation from "../components/app/FetchingDataAnimation";
import { useSelector } from "react-redux";
import axios from "axios";
import QuizButton from "../components/ui/QuizButton";
import FetchingDataAnimation2 from "../components/app/FetchingDataAnimation2";
import WaveComponent from "../components/ui/WaveComponent";
import useQuizProgress from "../components/hooks/useQuizProgress";
import { Card } from "flowbite-react";

function QuizPage() {
  const { country, language } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [quizzesArray, setQuizzesArray] = useState([]);
  const [imageSrc, setImageSrc] = useState("");

  const { isQuizCompleted } = useQuizProgress();

  useEffect(() => {
    window.scrollTo(0, 0);
    const img = new Image();
    img.onload = () => setImageSrc("/013-quiz.png");
    img.src = "/013-quiz.png";
  }, []);

  useEffect(() => {
    const apiUrl = `https://dev.silversky3d.com/api_vrin/quiz/all/${country}`;
    setIsLoading(true);

    axios
      .get(apiUrl)
      .then((response) => {
        // Handle the API response here
        setQuizzesArray(response.data);
        // console.log(response.data);
        // Add a delay of 500 ms before setting isLoading to false
        setIsLoading(false);
      })
      .catch((error) => {
        // Handle any errors here
        console.error("Error fetching data:", error);
        setQuizzesArray({});
        // Add a delay of 500 ms before setting isLoading to false
        setIsLoading(false);
      });
  }, [country]);

  // if (isLoading) {
  //   return ;
  // }
  // const background = "/17.jpg";
  return (
    <div
      className="flex flex-col items-center overflow-hidden w-full min-h-[90vh] bg-gray-100"
      style={{
        background:
          "linear-gradient(60deg, rgba(84,58,183,1) 0%, rgba(0,172,193,1) 100%)",
      }}
    >
      <h2 className="flex max-[400px]:text-lg text-xl bg-gray-900 text-gray-200 p-2 w-full justify-center">
        {getGreetingsLabelByLanguage(language)}
      </h2>

      <div className=" max-w-5xl">
        <Card
          className=" text-justify text-gray-200  bg-gray-900 bg-opacity-80 border-xl w-[90%] m-auto mt-2 "
          // style={{
          //   textShadow:
          //     " -1px -1px 0 #fff, 1px -1px 0 #fff,-1px 1px 0 #fff, 1px 1px 0 #fff",
          // }}
        >
          {getQuizInstructionsByLanguage(language)}
        </Card>
      </div>

      {!isLoading ? (
        <div
          className="flex flex-col w-full mt-6 max-w-2xl"
          // style={{
          //   backgroundImage: `url(${background})`,
          // }}
        >
          {quizzesArray.map((quiz, index) => (
            <QuizButton
              quizId={quiz.quiz_id}
              index={index}
              imageSrc={imageSrc}
              key={quiz.quiz_id}
              completed={isQuizCompleted(quiz.quiz_id)}
            />
          ))}
          <div className="absolute bottom-0 right-0 left-0 mb-2 m-auto">
            <WaveComponent />
          </div>
        </div>
      ) : (
        <div className="mt-10">
          <FetchingDataAnimation2 />
        </div>
      )}
    </div>
  );
}

export default QuizPage;
