import React, { createContext, useState, useContext } from "react";

const LayoutContext = createContext();

export const useLayout = () => useContext(LayoutContext);

export const LayoutProvider = ({ children }) => {
  const [bottomNavHeight, setBottomNavHeight] = useState(0);
  const [languageToggleHeight, setLanguageToggleHeight] = useState(0);

  const totalHeight = bottomNavHeight + languageToggleHeight;

  return (
    <LayoutContext.Provider
      value={{ setBottomNavHeight, setLanguageToggleHeight, totalHeight }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
