import React from "react";
import { getExpandableLabelByLanguage } from "../context/labels";

function SubunitInstructions({ language }) {
  // if (isLoading) return null;
  return (
    <div className=" py-1 flex flex-row items-center justify-center">
      {/* <FaCircleInfo size={15} color="white" className="mr-2" /> */}
      <p className=" italic text-center text-gray-700  max-[400px]:text-sm">
        {getExpandableLabelByLanguage(language)}
      </p>
    </div>
  );
}

export default SubunitInstructions;
