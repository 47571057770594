import React from "react";
import { Link } from "react-router-dom";

function ShortDescription({ data }) {
  return (
    <div>
      {data.map((item, index) => {
        if (index % 2 === 0) {
          // Even index, render paragraphs
          return (
            <div key={index} className="max-[400px]:text-xs max-[500px]:text-sm">
              {item.map((paragraph, i) => {
                if (paragraph !== "") {
                  // Split the paragraph into lines based on '\n'
                  const lines = paragraph.split("\n");
                  return (
                    <p className="mb-1 p-1 text-justify" key={i}>
                      {lines.map((line, lineIndex) => (
                        <React.Fragment key={lineIndex}>
                          {line}
                          {lineIndex !== lines.length - 1 && <br />}
                        </React.Fragment>
                      ))}
                    </p>
                  );
                }
                return null; // Return null for empty strings
              })}
            </div>
          );
        } else {
          // Odd index, render list
          return (
            <ul key={index} className=" list-decimal ml-5">
              {item.map((listItem, i) => (
                <li key={i} className="mb-2 mr-1 text-justify">
                  {listItem}
                </li>
              ))}
            </ul>
          );
        }
      })}
    </div>
  );
}

export default ShortDescription;
