import React from "react";

const SubunitHeader = ({
  id,
  title,
  parentName,
  name,
  expanded,
  onClick,
  backgroundColor,
  isLoading,
}) => {
  return (
    <div className="flex h-15 flex-wrap">
      <div
        className={`w-2 ${backgroundColor} flex justify-center items-center`}
      >
        {/* {expanded ? (
          <GoChevronDown size={25} color="#ffffff" />
        ) : (
          <GoChevronRight size={25} color="#ffffff" />
        )} */}
      </div>
      <div
        className={`text-center font-bold p-1 bg-slate-900  flex flex-1 flex-col`}
        onClick={onClick}
      >
        {expanded && (
          <div
            className={`flex flex-row flex-nowrap text-left items-center max-[400px]:text-sm p-0.5 text-gray-200 
          ${isLoading ? "animate-pulse" : null}`}
          >
            <div>
              <div>{id[0] + ". " + title}</div>
              <div className="ml-2">{id[1] + ". " + parentName}</div>
              <div className="ml-4">{parseInt(id[2]) + ". " + name}</div>
            </div>
          </div>
        )}
        {!expanded && (
          <div
            className={`flex flex-row flex-nowrap justify-center items-center max-[400px]:text-sm p-0.5 text-gray-200
            ${isLoading ? "animate-pulse" : null}`}
          >
            <div className="mx-2">
              {id[0] + "." + id[1] + "." + parseInt(id[2]) + ". " + name}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubunitHeader;
