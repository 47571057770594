export const englishCat = [
  //   --------------------------  100 -----------------------------  //
  {
    code: "100",
    name: "Communication",
    bgColor: "bg-red-700",
    units: [
      {
        code: "110",
        name: "Communication skills",
        subunits: [
          { code: "111", name: "Laws applying to TCNs" },
          {
            code: "112",
            name: "Benefits eligible to TCNs",
          },
          { code: "113", name: "Educational knowledge around TCNs" },
          { code: "114", name: "Manners and behaviour (eg. patience)" },
          { code: "115", name: "Development of language skills" },
          {
            code: "116",
            name: "Even if speaking the same language, some expressions are different and some words do not mean the same",
          },
          {
            code: "117",
            name: "Mandatory employment of translators",
          },
          {
            code: "118",
            name: "Guidelines in simple words and available in different languages",
          },
        ],
      },
    ],
  },
  //   --------------------------  200 -----------------------------  //
  {
    code: "200",
    name: "Empathy",
    bgColor: "bg-blue-700",
    units: [
      {
        code: "210",
        name: "Being Empathetic",
        subunits: [
          {
            code: "211",
            name: "Educational knowledge around TCNs/understand their needs (social, psychological, economic etc)",
          },
          {
            code: "212",
            name: "Emotional awareness and humanitarian understanding around migration/refugeehood",
          },
          {
            code: "213",
            name: "Respect and tolerance",
          },
          {
            code: "214",
            name: "Recognising them as human being and not numbers",
          },
        ],
      },
    ],
  },
  //   --------------------------  300 -----------------------------  //
  {
    code: "300",
    name: "Awareness",
    bgColor: "bg-yellow-600",
    units: [
      {
        code: "310",
        name: "Cultural awareness & Diversity",
        subunits: [
          { code: "311", name: "Educational knowledge around TCNs" },
          { code: "312", name: "Stereotypes" },
          {
            code: "313",
            name: "Addressing and responding to racism, xenophobia, discrimination",
          },
          { code: "314", name: "Recognising them as human beings and not as numbers" },
          { code: "315", name: "Understanding and accepting differentiality" },
          { code: "316", name: "Understanding the importance for the civic and political participation of TCNs" },
        ],
      },
    ],
  },
];

export const greekCat = [
  //   --------------------------  100 -----------------------------  //
  {
    code: "100",
    name: "Επικοινωνία",
    bgColor: "bg-red-700",
    units: [
      {
        code: "110",
        name: "Επικοινωνιακές δεξιότητες",
        subunits: [
          { code: "111", name: "Νόμοι που ισχύουν για τους ΥΤΧ" },
          {
            code: "112",
            name: "Ωφελήματα που δικαιούνται οι ΥΤΧ",
          },
          { code: "113", name: "Γνώσεις για τους ΥΤΧ" },
          { code: "114", name: "Τρόποι και συμπεριφορά" },
          { code: "115", name: "Ανάπτυξη γλωσσικών δεξιοτήτων" },
          {
            code: "116",
            name: "Ακόμη και αν μιλάμε την ίδια γλώσσα, κάποιες εκφράσεις είναι διαφορετικές, και κάποιες λέξεις δεν σημαίνουν το ίδιο ",
          },
          {
            code: "117",
            name: "Υποχρεωτική εργοδότηση μεταφραστών",
          },
          {
            code: "118",
            name: "Κατευθυντήριες γραμμές σε απλό λεξιλόγιο και σε διάφορες γλώσσες",
          },
        ],
      },
    ],
  },
  //   --------------------------  200 -----------------------------  //
  {
    code: "200",
    name: "Ενσυναίσθηση",
    bgColor: "bg-blue-700",
    units: [
      {
        code: "210",
        name: "Εφαρμόζοντας την ενσυναίσθηση",
        subunits: [
          {
            code: "211",
            name: "Γνώσεις για τους ΥΤΧ/κατανόηση των αναγκών τους",
          },
          {
            code: "212",
            name: "Συναισθηματική επίγνωση και ανθρωπιστική κατανόηση σχετικά με τη μετανάστευση/προσφυγιά",
          },
          {
            code: "213",
            name: "Σεβασμός και ανεκτικότητα",
          },
          {
            code: "214",
            name: "Αναγνωρίζοντάς τους ως ανθρώπους και όχι ως αριθμούς",
          },
        ],
      },
    ],
  },
  //   --------------------------  300 -----------------------------  //
  {
    code: "300",
    name: "Ευαισθητοποίηση",
    bgColor: "bg-yellow-600",
    units: [
      {
        code: "310",
        name: "Πολιτιστική Επίγνωση & Πολυμορφία",
        subunits: [
          { code: "311", name: "Γνώσεις για τους ΥΤΧ" },
          { code: "312", name: "Στερεότυπα" },
          {
            code: "313",
            name: "Αντιμετωπίζοντας και αντιδρώντας στον ρατσισμό, την ξενοφοβία και τις διακρίσεις",
          },
          { code: "314", name: "Αναγνωρίζοντάς τους ως ανθρώπους και όχι ως αριθμούς" },
          { code: "315", name: "Κατανόηση και αποδοχή της διαφορετικότητας" },
          { code: "316", name: "Κατανοώντας τη σημασία της πολιτειακής και πολιτικής συμμετοχής των ΥΤΧ" },
        ],
      },
    ],
  },
];

export const italyCat = [
  //   --------------------------  100 -----------------------------  //
  {
    code: "100",
    name: "Comunicazione",
    bgColor: "bg-red-700",
    units: [
      {
        code: "110",
        name: "Competenze comunicative",
        subunits: [
          { code: "111", name: "Leggi applicabili ai TCN" },
          {
            code: "112",
            name: "Benefici ammissibili per i TCN",
          },
          { code: "113", name: "Conoscenze educative relative ai TCN" },
          { code: "114", name: "Regole di comportamento" },
          { code: "115", name: "Sviluppo delle competenze linguistiche" },
          {
            code: "116",
            name: "Possibili incomprensioni e malintesi linguistici",
          },
          {
            code: "117",
            name: "Impiego obbligatorio di traduzioni linguistiche",
          },
          {
            code: "118",
            name: "Linee guida in parole semplici e disponibili in diverse lingue",
          },
        ],
      },
    ],
  },
  //   --------------------------  200 -----------------------------  //
  {
    code: "200",
    name: "Empatia",
    bgColor: "bg-blue-700",
    units: [
      {
        code: "210",
        name: "Essere empatici",
        subunits: [
          {
            code: "211",
            name: "Conoscenze educative relative ai TCN e comprensione dei loro bisogni",
          },
          {
            code: "212",
            name: "Consapevolezza emotiva e comprensione umanitaria del fenomeno migratorio/protezione internazionale",
          },
          {
            code: "213",
            name: "Rispetto e tolleranza",
          },
          {
            code: "214",
            name: "Approccio nei confronti dei beneficiari",
          },
        ],
      },
    ],
  },
  //   --------------------------  300 -----------------------------  //
  {
    code: "300",
    name: "Consapevolezza",
    bgColor: "bg-yellow-600",
    units: [
      {
        code: "310",
        name: "Consapevolezza culturale e diversità",
        subunits: [
          { code: "311", name: "Conoscenze educative relative ai TCN" },
          { code: "312", name: "Stereotipi" },
          {
            code: "313",
            name: "Contromisure per affrontare razzismo, xenofobia e discriminazione",
          },
          { code: "314", name: "Approccio nei confronti dei beneficiari" },
          { code: "315", name: "Comprensione e accettazione della diversità" },
          { code: "316", name: "Promozione e valorizzazione della partecipazione civica e politica dei TCN" },
        ],
      },
    ],
  },
];

export const lithuaniaCat = [
  //   --------------------------  100 -----------------------------  //
  {
    code: "100",
    name: "Bendravimas",
    bgColor: "bg-red-700",
    units: [
      {
        code: "110",
        name: "Bendravimo įgūdžiai",
        subunits: [
          { code: "111", name: "Trečiųjų šalių piliečiams taikomi įstatymai" },
          {
            code: "112",
            name: "Trečiųjų šalių piliečiams priklausančios išmokos",
          },
          { code: "113", name: "Žinios apie trečiasias šalis" },
          { code: "114", name: "Elgesys su trečiųjų šalių piliečiais" },
          { code: "115", name: "Kalbos įgūdžių vystymas" },
          {
            code: "116",
            name: "Net jei kalbama ta pačia kalba, kai kurie žodžiai ar išsireiškimai skirtingiems žmonėms gali reikšti ką kitą",
          },
          {
            code: "117",
            name: "Vertėjų svarba integruojant trečiųjų šalių piliečius",
          },
          {
            code: "118",
            name: "Gairės paprastais žodžiais ir įvairiomis kalbomis",
          },
        ],
      },
    ],
  },
  //   --------------------------  200 -----------------------------  //
  {
    code: "200",
    name: "Empatija",
    bgColor: "bg-blue-700",
    units: [
      {
        code: "210",
        name: "Buvimas empatišku",
        subunits: [
          {
            code: "211",
            name: "Žinios apie trečiųjų šalių švietimą ir jų poreikių supratimas",
          },
          {
            code: "212",
            name: "Emocinis sąmoningumas apie migraciją",
          },
          {
            code: "213",
            name: "Pagarba ir tolerancija",
          },
          {
            code: "214",
            name: "Trečiųjų šalių piliečių pripažinimas",
          },
        ],
      },
    ],
  },
  //   --------------------------  300 -----------------------------  //
  {
    code: "300",
    name: "Sąmoningumas",
    bgColor: "bg-yellow-600",
    units: [
      {
        code: "310",
        name: "Žinios apie trečiųjų šalių švietimą",
        subunits: [
          { code: "311", name: "Conoscenze educative relative ai TCN" },
          { code: "312", name: "Stereotipai" },
          {
            code: "313",
            name: "Rasizmo, ksenofobijos ir diskriminacijos problemų sprendimas",
          },
          { code: "314", name: "Trečiųjų šalių piliečių pripažinimas" },
          { code: "315", name: "Skirtumų supratimas ir pripažinimas" },
          { code: "316", name: "Trečiųjų šalių piliečių pilietinio ir politinio įsitraukimo svarbos supratimas" },
        ],
      },
    ],
  },
];

export const spainCat = [
  //   --------------------------  100 -----------------------------  //
  {
    code: "100",
    name: "Comunicación",
    bgColor: "bg-red-700",
    units: [
      {
        code: "110",
        name: "Habilidades comunicativas",
        subunits: [
          { code: "111", name: "Legislación aplicable a las personas NTPs" },
          {
            code: "112",
            name: "Prestaciones aplicables a las personas NTPs",
          },
          { code: "113", name: "Conocimientos educativos en torno a las personas NTPs" },
          { code: "114", name: "Modales y comportamiento" },
          { code: "115", name: "Desarrollo de las competencias lingüísticas" },
          {
            code: "116",
            name: "Aunque se hable la misma lengua, algunas expresiones son diferentes y algunas palabras no significan lo mismo",
          },
          {
            code: "117",
            name: "Empleo obligatorio de personas traductoras",
          },
          {
            code: "118",
            name: "Directrices en palabras sencillas y disponibles en distintas lenguas",
          },
        ],
      },
    ],
  },
  //   --------------------------  200 -----------------------------  //
  {
    code: "200",
    name: "Empatía",
    bgColor: "bg-blue-700",
    units: [
      {
        code: "210",
        name: "Tener empatía",
        subunits: [
          {
            code: "211",
            name: "Conocimientos pedagógicos sobre las personas NTPs/comprensión de sus necesidades",
          },
          {
            code: "212",
            name: "Conciencia emocional y comprensión humanitaria en torno a la migración/refugio",
          },
          {
            code: "213",
            name: "Respeto y tolerancia",
          },
          {
            code: "214",
            name: "Reconocerlos como seres humanos y no como números",
          },
        ],
      },
    ],
  },
  //   --------------------------  300 -----------------------------  //
  {
    code: "300",
    name: "Concienciación",
    bgColor: "bg-yellow-600",
    units: [
      {
        code: "310",
        name: "Conciencia cultural y diversidad",
        subunits: [
          { code: "311", name: "Conocimientos pedagógicos en torno a las personas NTPs" },
          { code: "312", name: "Estereotipos" },
          {
            code: "313",
            name: "Abordar y responder al racismo, la xenofobia y la discriminación",
          },
          { code: "314", name: "Reconocerlos como seres humanos y no como números" },
          { code: "315", name: "Comprender y aceptar la diferenciación  " },
          { code: "316", name: "Comprender la importancia de la participación cívica y política de las personas NTPs" },
        ],
      },
    ],
  },
];

export const swedenCat = [
  //   --------------------------  100 -----------------------------  //
  {
    code: "100",
    name: "Kommunikation",
    bgColor: "bg-red-700",
    units: [
      {
        code: "110",
        name: "Kommunikationsförmåga",
        subunits: [
          { code: "111", name: "Lagar som gäller för TCN" },
          {
            code: "112",
            name: "Förmåner som berättigar till TCN",
          },
          { code: "113", name: "Pedagogisk kunskap om TCN" },
          { code: "114", name: "Uppförande och beteende" },
          { code: "115", name: "Utveckling av språkkunskaper" },
          {
            code: "116",
            name: "Även om man talar samma språk är vissa uttryck olika, och vissa ord betyder inte samma sak",
          },
          {
            code: "117",
            name: "Obligatorisk anställning av översättare",
          },
          {
            code: "118",
            name: "Riktlinjer med enkla ord och tillgängliga på olika språk",
          },
        ],
      },
    ],
  },
  //   --------------------------  200 -----------------------------  //
  {
    code: "200",
    name: "Empati",
    bgColor: "bg-blue-700",
    units: [
      {
        code: "210",
        name: "Att vara empatisk",
        subunits: [
          {
            code: "211",
            name: "Utbildningskunskap om TCN/förståelse för deras behov",
          },
          {
            code: "212",
            name: "Emotionell medvetenhet och humanitär förståelse kring migration/flyktingskap",
          },
          {
            code: "213",
            name: "Respekt och tolerans",
          },
          {
            code: "214",
            name: "Erkänna dem som människor och inte som siffror",
          },
        ],
      },
    ],
  },
  //   --------------------------  300 -----------------------------  //
  {
    code: "300",
    name: "Medvetenhet",
    bgColor: "bg-yellow-600",
    units: [
      {
        code: "310",
        name: "Kulturell medvetenhet och mångfald",
        subunits: [
          { code: "311", name: "Pedagogisk kunskap om TCN" },
          { code: "312", name: "Stereotyper" },
          {
            code: "313",
            name: "Att ta itu med och reagera på rasism, främlingsfientlighet och diskriminering",
          },
          { code: "314", name: "Att erkänna dem som människor och inte som siffror" },
          { code: "315", name: "Förstå och acceptera differentialitet" },
          { code: "316", name: "Förståelse för betydelsen av TCN:s medborgerliga och politiska deltagande" },
        ],
      },
    ],
  },
];

export const turkishCat = [
  //   --------------------------  100 -----------------------------  //
  {
    code: "100",
    name: "İletişim",
    bgColor: "bg-red-700",
    units: [
      {
        code: "110",
        name: "İletişim becerileri",
        subunits: [
          { code: "111", name: "CN'lere uygulanan kanunlar" },
          {
            code: "112",
            name: "TCN'ler için uygun faydalar",
          },
          { code: "113", name: "TCN'lere ilişkin eğitim bilgisi" },
          { code: "114", name: "Tavır ve davranışlar" },
          { code: "115", name: "Dil becerilerinin geliştirilmesi" },
          {
            code: "116",
            name: "Aynı dili konuşuyor olsak bile, bazı ifadeler farklıdır ve bazı kelimeler aynı anlama gelmez",
          },
          {
            code: "117",
            name: "Çevirmenlerin zorunlu istihdamı",
          },
          {
            code: "118",
            name: "Basit kelimelerle ve farklı dillerde hazırlanmış kılavuzlar",
          },
        ],
      },
    ],
  },
  //   --------------------------  200 -----------------------------  //
  {
    code: "200",
    name: "Empati",
    bgColor: "bg-blue-700",
    units: [
      {
        code: "210",
        name: "Empatik Olmak",
        subunits: [
          {
            code: "211",
            name: "TCN'ler hakkında eğitim bilgisi/ihtiyaçlarını anlama",
          },
          {
            code: "212",
            name: "Göç/mülteciliğe ilişkin duygusal farkındalık ve insani anlayış",
          },
          {
            code: "213",
            name: "Saygı ve hoşgörü",
          },
          {
            code: "214",
            name: "Onları sayı olarak değil insan olarak kabul etmek",
          },
        ],
      },
    ],
  },
  //   --------------------------  300 -----------------------------  //
  {
    code: "300",
    name: "Farkındalık",
    bgColor: "bg-yellow-600",
    units: [
      {
        code: "310",
        name: "Kültürel Farkındalık ve Çeşitlilik",
        subunits: [
          { code: "311", name: "TCN'lere ilişkin eğitim bilgisi" },
          { code: "312", name: "Stereotipler" },
          {
            code: "313",
            name: "Irkçılık, yabancı düşmanlığı ve ayrımcılığın ele alınması ve bunlara yanıt verilmesi",
          },
          { code: "314", name: "Onları sayı olarak değil, insan olarak kabul etmek" },
          { code: "315", name: "Farklılığı anlamak ve kabul etmek" },
          { code: "316", name: "Kıbrıslı Türklerin sivil ve siyasi katılımı için önemin anlaşılması" },
        ],
      },
    ],
  },
];
