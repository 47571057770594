import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import Backdrop from "./Backdrop";
import "../../css/modal.css";
import Button from "./Button";
import VideoPlayer from "./VideoPlayer";
import useWindowDimensions from "../hooks/useWindowDimensions";

const modalVariants = {
  hidden: {
    y: "-100vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.2,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: "100vh",
    opacity: 0,
  },
};

const ModalMedia = ({ handleClose, url }) => {
  console.log(url);
  const { width, height } = useWindowDimensions();

  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className="modal orange-gradient"
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        style={{ width: width * 0.8, height: height * 0.8 }}
      >
        {/* <div className="max-[400px]:text-xs mb-2 max-[500px]:text-sm">
          {url}
        </div> */}
        <VideoPlayer source={url} />
        <Button onClick={handleClose} danger rounded>
          Close
        </Button>
      </motion.div>
    </Backdrop>
  );
};

export default ModalMedia;
