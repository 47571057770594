import { Navigate, Outlet } from "react-router-dom";
import { hasCountryAndStatusSelector } from "../../store";
import { useSelector } from "react-redux";
import BottomNavigation from "./BottomNavigation";
import LanguageToggle from "./LanguageToggle";

const ProtectedRoute = ({ redirectPath = "/" }) => {
  //todo Uncomment and other other line
  const hasCountryAndStatus = useSelector(hasCountryAndStatusSelector);
  //const isAuthenticated = true;
  // if (hasCountryAndStatus) {
  //   console.log("is Authenticated: " + hasCountryAndStatus); // TODO REMOVE THE LOG ---
  // } else {
  //   console.log("Redirecting...."); // TODO REMOVE THE LOG ---
  // }

  return (
    <div className="relative">
      <LanguageToggle />

      <div className="mt-12 mb-10">
        {" "}
        {/* Create space for LanguageToggle */}
        {hasCountryAndStatus ? <Outlet /> : <Navigate to={redirectPath} />}
      </div>
      {hasCountryAndStatus && <BottomNavigation />}
    </div>
  );
};

export default ProtectedRoute;
