import React, { useState, useEffect } from "react";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { MdOutlineDoneOutline } from "react-icons/md";

import Ripples from "react-ripples";
import { Card } from "flowbite-react";

function QuizButton({ quizId, onClick, index, completed, imageSrc }) {
  const navigate = useNavigate();

  const handleClick = () => {
    setTimeout(() => {
      localStorage.setItem("quizId", quizId);
      navigate("/questions", { state: { quizId } });
    }, 200);
  };

  return (
    <div className="relative mb-6">
      <div className="bg-transparent w-full py-4 px-4 flex flex-row items-center mb-1">
        <Ripples
          className="noTapHighlight w-3/4  relative z-10 shadow-iconButtonD bg-gray-900 rounded-xl"
          color="#ffffff88"
        >
          <Button
            onClick={handleClick}
            secondary
            rounded
            className="w-full flex flex-row justify-end max-[400px]:py-2 py-4 pr-6"
          >
            <div className="min-h-[1rem] flex flex-row">
              <div className="max-[400px]:text-lg text-xl">
                {"Quiz " + (index + 1)}
              </div>
            </div>
          </Button>
        </Ripples>
        <button className="absolute top-1/2 right-3/4 translate-x-1/4 transform -translate-y-1/2 z-10 max-[600px]:w-[25%] w-[16%] ">
          <LazyLoadImage
            src={imageSrc}
            alt="Quiz Icon"
            style={{ objectFit: "cover" }}
            onClick={handleClick}
          />
        </button>
        <div className="">
          {completed ? (
            <MdOutlineDoneOutline
              size={40}
              color="#50ad12"
              className="ml-7 px-2  bg-gray-900 py-2 rounded-xl text-gray-200 font-medium shadow-iconButtonD"
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default QuizButton;
//focus:translate-x-2 focus:translate-y-2 focus:shadow-none shadow-iconButtonD
