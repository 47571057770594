import React from "react";
import { Droppable } from "react-beautiful-dnd";
import DraggableItem from "../ui/DraggableItem";
import { useDispatch } from "react-redux";

const DroppableDeleteColumn = ({ title, categories, isDrawerOpen, id, showingFunction }) => {
  const dispatch = useDispatch();

  const handleSwitching = () => {
    if (!isDrawerOpen) {
      dispatch(showingFunction(true));
    }
  };
  return (
    <Droppable droppableId={id}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className={`p-3 border duration-500 ease-in-out min-h-screen
          ${snapshot.isDraggingOver && !isDrawerOpen ? "bg-gray-500" : "bg-gray-200"}  
          ${`  ${isDrawerOpen ? "w-11/12" : "w-2/12"}`}
        `}
          onClick={() => handleSwitching()}
        >
          {isDrawerOpen && <h2 className="font-bold mb-4 text-center">{title}</h2>}
          {categories.map((category, index) => (
            <DraggableItem
              key={category.code}
              category={category}
              index={index}
              isDrawerOpen={isDrawerOpen}
              isDragDisabled={!isDrawerOpen}
            />
          ))}
          {/* <div className="fixed top-1/2 ">Del {"\n"} icon</div> */}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default DroppableDeleteColumn;
