import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa6";
import { FaChevronDown } from "react-icons/fa6";
import { GoMoveToEnd } from "react-icons/go";
import { FaCircleInfo } from "react-icons/fa6";

const Category = ({ title, units, bgColor, description, openModal, setText }) => {
  //console.log(units);
  const [unitCollapsed, setUnitCollapsed] = useState({});

  const toggleUnitCollapse = (unitCode) => {
    setUnitCollapsed((prevState) => ({
      ...prevState,
      [unitCode]: !prevState[unitCode],
    }));
  };
  const [ref, inView] = useInView({
    triggerOnce: false, // Change to true if you want the animation to occur only once
    threshold: 0.1,
  });

  // const animationClasses = {
  //   top: "translate-y-40 ", // Adjust the translate values as needed
  //   right: "-translate-x-40 ",
  //   bottom: "-translate-y-40 ",
  //   left: "translate-x-40 ",
  // };

  // Determine the correct animation class based on the 'inView' state
  const currentAnimationClass = inView ? "opacity-100 translate-y-0 translate-x-0" : `opacity-0  translate-x-40`;

  const handleOpen = (description) => {
    setText(description);
    openModal();
  };

  const getShadowColor = (bgColor) => {
    switch (bgColor) {
      case "bg-red-700":
        return "rgba(185, 28, 28, 0.8)";
      case "bg-blue-700":
        return "rgba(29, 78, 216, 0.8)"; // Replace with the actual blue shadow color
      case "bg-yellow-600":
        return "rgba(202, 138, 4, 0.8)"; // Replace with the actual yellow shadow color
      case "bg-green-700":
        return "rgba(21, 128, 61, 0.8)"; // Replace with the actual green shadow color
      default:
        return "rgba(185, 28, 28, 0.8)"; // Default to red shadow color
    }
  };
  useEffect(() => {
    const initialCollapsedState = {};
    units.forEach((unit) => {
      initialCollapsedState[unit.code] = true;
    });
    setUnitCollapsed(initialCollapsedState);
  }, [units]);

  return (
    <>
      <div
        ref={ref}
        className={` transition-all duration-[1300ms] transform border-gray-200 bg-gray-900 ${currentAnimationClass}`}
      >
        {/* // --------------- MODULE ------------- */}

        <motion.button
          whileTap={{ scale: 0.9 }}
          className=" p-2  border-gray-900 bg-gray-900 text-gray-200  w-full"
          onClick={() => handleOpen(description)}
        >
          <div className="flex flex-row items-center font-bold">
            <div
              className=" flex flex-col justify-center items-center flex-wrap mr-3"
              style={{ height: "4rem", width: "1%" }}
            >
              <div className={` ${bgColor} w-2 flex-1 flex justify-center `}></div>
            </div>
            <h2 className="font-bold p-1 text-left flex flex-row flex-wrap w-10/12  max-[400px]:text-base text-lg ">
              {title}
            </h2>
            <div className="mr-1 ml-3 ">
              <FaCircleInfo size={20} color="white" />
            </div>
          </div>
        </motion.button>

        {/* --------------------- UNITS ----------------------- */}
        <div className="p-2 mb-2">
          {units.map((unit) => (
            <div
              key={unit.code}
              className=" rounded-lg shadow-md border border-gray-100 mb-4 bg-gray-200 p-1 mr-0.5"
              style={{
                boxShadow: `${getShadowColor(bgColor)} -5px 0px inset,
                            3px 5px 1px -1px #6b7280 , 0 4px 4px -2px #6b7280`,
              }}
            >
              {/* --------------------- COLLAPSABLE  ----------------------- */}
              <motion.button
                whileTap={{ scale: 0.99 }}
                className="w-full text-left flex flex-row items-center justify-between"
                onClick={() => toggleUnitCollapse(unit.code)}
              >
                <h3 className=" max-[400px]:text-base text-lg  font-semibold  p-1 mb-2 ">{unit.name}</h3>
                <div className=" mr-2 p-1 mb-2">
                  {unitCollapsed[unit.code] ? <FaChevronRight size={18} /> : <FaChevronDown size={18} />}
                </div>
              </motion.button>
              {unitCollapsed[unit.code] ? null : (
                <ul className="subunits-list mx-2 mb-2">
                  {unit.subunits.map((subunit) => (
                    <div
                      className="bg-gray-100 p-1 mb-1  rounded-lg"
                      style={{ boxShadow: `1px 4px 4px -2px #6b7280` }}
                      key={subunit.code}
                    >
                      <li className="subunit-item ">
                        <Link
                          to={`/details/${subunit.code}`}
                          state={{
                            nameBackup: subunit.name,
                            titleBackup: title,
                            nameParentBackup: unit.name,
                          }} // Passing subunit as state
                        >
                          <div className=" flex flex-row justify-around items-center ">
                            <div className="subunit-attribute text-left w-11/12">{subunit.name}</div>
                            <div className="">
                              <GoMoveToEnd size={20} color="#6b7280" />
                            </div>
                          </div>
                        </Link>
                      </li>
                    </div>
                  ))}
                </ul>
              )}
              {/* --------------------- COLLAPSABLE  ----------------------- */}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Category;
